import { dayjs } from '@abyss/web/tools/dayjs';
import { Badge } from '@abyss/web/ui/Badge';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Table as TableComponent } from '@src/components/Table-query';
import { isEmpty, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import statuses from '../../../../includes/statuses.json';
import { Header } from './components/Header';
import configuration from './includes/configuration.json';

/**
 * Table
 *
 * Displays workable items in the queue.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Table = (props) => {
  const { error, isLoading, requestArgs, requestFunction, requestKey, rows, totalPages, totalRecords } = props;

  /**
   * renderCell
   *
   * Default; Displays the value of the cell.
   *
   * @param args
   * @returns {*}
   */
  const renderCell = (args) => {
    const { cell } = args;

    return cell?.value;
  };

  /**
   * renderCellMemberName
   *
   * Displays the type firstname and last name.
   *
   * @param args
   * @returns {Element}
   */
  const renderCellMemberName = (args) => {
    const { row } = args;
    const { original } = row;

    return (
      <span>{`${original?.eimpOriginalRecord?.firstName || ''} ${original?.eimpOriginalRecord?.lastName || ''}`}</span>
    );
  };

  /**
   * renderAddressType
   *
   * Displays the type of address of the member.
   *
   * @param args
   * @returns {*|string}
   */
  const renderCellAddressType = (args) => {
    const { value } = args;

    if (value === 'CONF_COMM') {
      return 'Conf Comm';
    }

    return value;
  };

  /**
   * renderCellStatus
   *
   * Displays the status of the address.
   *
   * @param args
   * @returns {React.JSX.Element|*}
   */
  const renderCellStatus = (args) => {
    const { cell } = args;

    const badges = {
      complete: 'success',
      in_progress: 'warning',
      new: 'info',
    };

    if (!isUndefined(badges[cell?.value.toLowerCase()])) {
      return (
        <Badge outline variant={badges[cell?.value.toLowerCase()]}>
          <span style={{ textTransform: 'capitalize' }}>{statuses?.[String(cell?.value).toUpperCase()]}</span>
        </Badge>
      );
    }

    return cell?.value;
  };

  /**
   * renderCellEvent
   *
   * Displays the type of event.
   *
   * @param args
   * @returns {*}
   */
  const renderCellEvent = (args) => {
    const { cell, row } = args;
    const { original } = row;
    return <Link href={`/work-queue/item/${original?.id?.toLowerCase()}`}>{cell?.value}</Link>;
  };

  /**
   * renderCellDaysInQueue
   *
   * Displays the number of days the work item has been in the queue.
   *
   * @param args
   * @returns {Element}
   */
  const renderCellDaysInQueue = (args) => {
    const { row } = args;
    const { original } = row;
    const todayDate = dayjs();
    const createdDate = dayjs(original?.createdDate);
    const daysInQueue = todayDate.diff(createdDate, 'day');

    return <span>{daysInQueue ? `${daysInQueue} days` : ''}</span>;
  };

  /**
   * renderCellDob
   *
   * Displays the date of birth of the member.
   *
   * @param args
   * @returns {*}
   */
  const renderCellDob = (args) => {
    return renderCell(args);
  };

  /**
   * renderCellMemberId
   *
   * Displays the member id.
   *
   * @param args
   * @returns {*}
   */
  const renderCellMemberId = (args) => {
    return renderCell(args);
  };

  /**
   * renderCellAltId
   *
   * Displays the alternate id of the member.
   *
   * @param row
   * @returns {Element}
   */
  const renderCellAltId = ({ row }) => {
    const { original } = row;

    return <span>{original?.protectOriginalRecord?.memberAltId}</span>;
  };

  /**
   * renderCellCAGId
   *
   * Displays the CAG id of the member.
   *
   * @param args
   * @returns {Element}
   */
  const renderCellCAGId = (args) => {
    const { row } = args;

    if (isEmpty(row?.original?.cagId)) {
      return '';
    }

    return (
      <Layout.Stack grow>
        <div>
          <strong>Carrier ID:</strong> {row?.original?.cagId?.[0]?.carrierId}
        </div>
        <div>
          <strong>Account ID:</strong> {row?.original?.cagId?.[0]?.accountId}
        </div>
        <div>
          <strong>Group ID:</strong> {row?.original?.cagId?.[0]?.groupId}
        </div>
        <div>
          <strong>Member ID:</strong> {row?.original?.cagId?.[0]?.memberId}
        </div>
      </Layout.Stack>
    );
  };

  /**
   * renderCellPolicyNumber
   *
   * Displays the policy number of the member.
   *
   * @param args
   * @returns {*}
   */
  const renderCellPolicyNumber = (args) => {
    return renderCell(args);
  };

  /**
   * renderCellCreated
   *
   * Displays the date and time of when the work item was created.
   *
   * @param args
   * @returns {*}
   */
  const renderCellCreated = (args) => {
    const { cell } = args;

    return dayjs(cell?.value).format('MM/DD/YYYY, HH:mm:ss');
  };

  /**
   * renderCellLastModified
   *
   * Displays the date and time of when the work item was last updated.
   *
   * @param args
   * @returns {*}
   */
  const renderCellLastModified = (args) => {
    const { cell } = args;

    return dayjs(cell?.value).format('MM/DD/YYYY, HH:mm:ss');
  };

  /**
   * renderCellLastModifiedBy
   *
   * Displays the email address of the person who last modified the action path.
   *
   * @param args
   * @returns {*}
   */
  const renderCellLastModifiedBy = (args) => {
    const { cell } = args;

    return cell?.value;
  };

  /**
   * Columns for table.
   */
  const columns = useMemo(() => {
    return configuration?.initialColumns.map((item) => {
      const column = item;

      switch (column.Header) {
        case 'Address Type':
          column.Cell = renderCellAddressType;
          break;
        case 'Alt ID':
          column.Cell = renderCellAltId;
          break;
        case 'CAG ID':
          column.Cell = renderCellCAGId;
          break;
        case 'Created':
          column.Cell = renderCellCreated;
          column.width = 200;
          break;
        case 'Days in Queue':
          column.Cell = renderCellDaysInQueue;
          break;
        case 'DOB':
          column.Cell = renderCellDob;
          break;
        case 'Event':
          column.Cell = renderCellEvent;
          break;
        case 'Last Modified':
          column.Cell = renderCellLastModified;
          column.width = 200;
          break;
        case 'Last Modified By':
          column.Cell = renderCellLastModifiedBy;
          column.width = 200;
          break;
        case 'Member ID':
          column.Cell = renderCellMemberId;
          break;
        case 'Member Name':
          column.Cell = renderCellMemberName;
          break;
        case 'Policy Number':
          column.Cell = renderCellPolicyNumber;
          break;
        case 'Status':
          column.Cell = renderCellStatus;
          break;
        default:
          column.Cell = renderCell;
          break;
      }

      return column;
    });
  }, []);

  return (
    <ErrorHandler location="src/routes/private/WorkQueue/screens/List/components/Table/Table.jsx">
      <TableComponent
        {...{
          columns,
          configuration,
          error,
          headerLeft: <Header totalRecords={totalRecords} />,
          isLoading,
          requestArgs,
          requestFunction,
          requestKey,
          rows,
          totalPages,
          totalRecords,
        }}
      />
    </ErrorHandler>
  );
};

Table.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  requestArgs: PropTypes.shape({
    eid: PropTypes.string,
    policyNumber: PropTypes.string,
    statuses: PropTypes.arrayOf(PropTypes.string),
  }),
  requestFunction: PropTypes.func,
  requestKey: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  totalPages: PropTypes.number,
  totalRecords: PropTypes.number,
};

Table.defaultProps = {
  error: null,
  isLoading: false,
  requestArgs: {},
  requestFunction: () => {},
  requestKey: '',
  rows: [],
  totalPages: 0,
  totalRecords: 0,
};
