import { Tooltip } from '@abyss/web/ui/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * WithToolTip
 *
 * When the button is disabled, wrap it in a Tooltip explaining why it is disabled.
 *
 * @param props
 * @returns {React.JSX.Element|*}
 * @constructor
 */
export const WithToolTip = (props) => {
  const { children, status } = props;

  if (status === false) {
    return children;
  }

  return (
    <Tooltip
      asChild={false}
      content="This Member has an active Conf comm record which has an open ended date, you must end that record before creating a new one."
    >
      {children}
    </Tooltip>
  );
};

WithToolTip.propTypes = {
  children: PropTypes.element,
  status: PropTypes.bool,
};

WithToolTip.defaultProps = {
  children: null,
  status: false,
};
