import { config } from '@abyss/web/tools/config';

/**
 * Hook: useCurrentVersion
 *
 * Gets the current version of the application.
 *
 * @returns {*}
 */
export const useCurrentVersion = () => {
  return config('DD_VERSION') || document.head.querySelector('meta[name=version]')?.content || '0.0.0';
};
