import { useForm } from '@abyss/web/hooks/useForm';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { dayjs } from '@abyss/web/tools/dayjs';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useApi } from '@src/context/Api';
import { useRoutesContext } from '@src/context/Routes';
import { isEmpty, isEqual, isNil, isUndefined, omitBy } from 'lodash';
import React, { useEffect, useLayoutEffect, useState } from 'react';

import { Filters } from './components/Filters';
import { Results } from './components/Results';

/**
 * Search
 *
 * Displays a search form to find a member's policy information.
 *
 * @returns {Element}
 * @constructor
 */
export const Search = () => {
  const { currentRoute } = useRoutesContext();
  const router = useRouter();

  const [searchResults, setSearchResults] = useState([]);
  const [isReset, setIsReset] = useState(true);
  const [isFormValid, setIsFormValid] = useState(false);

  const { useApiQuery } = useApi();

  const [ListSearchResults, { data: searchData, isError, isFetching, isLoading }] = useApiQuery('ListSearchResults', {
    excludedHttpCodes: [400],
  });

  const defaultValues = {
    demographics: {
      addressLine: '',
      city: '',
      dob: '',
      firstName: '',
      lastName: '',
      phone: '',
      state: '',
      zipCode: '',
    },
    identifiers: {
      cagId: '',
      enterpriseId: '',
      memberAltId: '',
      memberId: '',
      policyNumber: '',
    },
  };

  const form = useForm({ defaultValues });
  const { isSubmitting } = form.formState;

  const allValues = form?.getValues();

  const validateForm = (values) => {
    const errors = {
      demographics: {},
      identifiers: {},
    };

    Object.keys(values?.identifiers).forEach((field) => {
      errors.identifiers[field] = isEmpty(values?.identifiers?.[field]);
    });

    Object.keys(values?.demographics).forEach((field) => {
      errors.demographics[field] = isEmpty(values?.demographics?.[field]);
    });

    const invalidIdentifiers = omitBy(errors?.identifiers, (value) => {
      return value === false;
    });

    const validIdentifiers = omitBy(errors?.identifiers, (value) => {
      return value === true;
    });

    const invalidDemographics = omitBy(errors?.demographics, (value) => {
      return value === false;
    });

    const validDemographics = omitBy(errors?.demographics, (value) => {
      return value === true;
    });

    const validIdentifiersCount = Object.values(values?.identifiers).length - Object.values(invalidIdentifiers).length;

    const validDemographicsCount =
      Object.values(values?.demographics).length - Object.values(invalidDemographics).length;

    const status =
      (validIdentifiersCount >= 1 && validDemographicsCount >= 1) ||
      (validIdentifiersCount >= 2 && validDemographicsCount === 0) ||
      !isUndefined(validIdentifiers?.enterpriseId) ||
      (!isUndefined(validDemographics?.firstName) &&
        !isUndefined(validDemographics?.lastName) &&
        !isUndefined(validDemographics?.dob));

    setIsFormValid(status);

    return status;
  };

  /**
   * Validate the form to ensure that required fields are filled out.
   */
  useEffect(() => {
    validateForm(allValues);
  }, [allValues]);

  /**
   * handleSubmit
   *
   * Sends the form data to the remote API to be saved in the database.
   *
   * @param submittedValues
   * @returns {Promise<void>}
   */
  const handleSubmit = async (submittedValues) => {
    const payload = { ...submittedValues?.identifiers, ...submittedValues?.demographics };

    if (!isNil(submittedValues?.demographics?.dob) && !isEmpty(submittedValues?.demographics?.dob)) {
      payload.dob = dayjs(submittedValues?.demographics?.dob).format('YYYY-MM-DD');
    }

    if (!isNil(submittedValues?.demographics?.phone) && !isEmpty(submittedValues?.demographics?.phone)) {
      payload.phone = submittedValues?.demographics?.phone.replace(/[^a-zA-Z0-9]/g, '');
    }

    await ListSearchResults(payload);
    setIsReset(false);

    const encoded = Buffer.from(JSON.stringify(submittedValues)).toString('base64');
    if (currentRoute?.params?.key !== encoded) {
      router?.navigate(`/search/${encoded}`);
    }
  };

  /**
   * Reset the form and search results.
   */
  const handleReset = () => {
    setIsReset(true);
    setSearchResults([]);
    form?.reset(defaultValues, {
      keepDirty: false,
      keepDirtyValues: false,
      keepErrors: false,
      keepIsValid: false,
      keepSubmitCount: true,
      keepTouched: false,
      keepValues: false,
    });
    if (!isUndefined(currentRoute?.params?.key)) {
      router?.navigate('/search');
    }
  };

  /**
   * If filters are passed in the URL, apply them to the form.
   */
  useLayoutEffect(() => {
    if (currentRoute?.params?.key && !isLoading && !isSubmitting) {
      const decoded = Buffer.from(currentRoute?.params?.key, 'base64').toString('utf-8');
      const searchCriteria = JSON.parse(decoded);

      const formFilters = form?.getValues();

      if (!isEqual(searchCriteria, formFilters)) {
        form?.reset(searchCriteria, {
          keepDirty: false,
          keepDirtyValues: false,
          keepErrors: false,
          keepIsValid: false,
          keepSubmitCount: true,
          keepTouched: false,
          keepValues: false,
        });

        const isValid = validateForm(searchCriteria);

        if (isValid) {
          (async () => {
            await handleSubmit(searchCriteria);
          })();
        }
      }
    }

    if (isUndefined(currentRoute?.params?.key)) {
      handleReset();
    }
  }, [currentRoute?.params?.key, isSubmitting, isLoading]);

  /**
   * Store the search results from the remote api in local component state.
   */
  useEffect(() => {
    if (!isLoading && !isFetching && !isUndefined(searchData?.data?.searchResponseList)) {
      setSearchResults(searchData?.data?.searchResponseList);
    }
  }, [isFetching, isLoading, searchData?.data?.searchResponseList]);

  /**
   * Reset the search results when an error occurs.
   */
  useEffect(() => {
    if (isError) {
      setSearchResults([]);
    }
  }, [isError]);

  return (
    <ErrorHandler location="src/routes/private/Search/Search.jsx">
      <Filters
        form={form}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        isFormValid={isFormValid}
        isLoading={isLoading || isFetching}
      />
      {!isLoading && !isFetching && !isReset && (
        <Results handleReset={setIsReset} rows={searchResults || []} total={searchResults?.length || 0} />
      )}
    </ErrorHandler>
  );
};
