import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.app-layout-header': {
    '.abyss-nav-menu-list': {
      '.abyss-nav-menu-link-root': {
        '.abyss-icon': {
          color: 'var(--abyss-colors-foreground)',
          fill: 'var(--abyss-colors-foreground)',
        },
        '.abyss-text-root': {
          color: 'var(--abyss-colors-foreground)',
        },
        '& :hover': {
          backgroundColor: 'var(--abyss-colors-background)',
          borderRadius: '4px',
        },
        '&.active': {
          '.abyss-icon': {
            color: 'var(--abyss-colors-interactive1)',
            fill: 'var(--abyss-colors-interactive1)',
          },
          '.abyss-text-root': {
            color: 'var(--abyss-colors-interactive1)',
          },
        },
      },
      '.abyss-nav-menu-menu': {
        '.abyss-icon': {
          color: 'var(--abyss-colors-foreground)',
          fill: 'var(--abyss-colors-foreground)',
        },
        '.abyss-text-root': {
          color: 'var(--abyss-colors-foreground)',
        },
        '& :hover, & button[data-state="open"]': {
          backgroundColor: 'var(--abyss-colors-background)',
          borderRadius: '4px',
        },
        '&.active': {
          '.abyss-icon': {
            color: 'var(--abyss-colors-interactive1) !important',
            fill: 'var(--abyss-colors-interactive1)',
          },
          '.abyss-text-root': {
            color: 'var(--abyss-colors-interactive1)',
          },
        },
      },
      li: {
        '.abyss-text-root': {
          fontSize: 'var(--abyss-fontSizes-sm)',
          fontWeight: 'var(--abyss-fontWeights-medium)',
        },
        '.nav-icon-wrap': {
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        },
      },
    },
    '.abyss-nav-menu-scroll-button-left, .abyss-nav-menu-scroll-button-right': {
      display: 'none',
    },
    '.abyss-page-header-logo': {
      '&:hover': {
        textDecoration: 'none',
      },
      flex: 'initial',
    },
    '.abyss-page-header-vertical-rule': {
      height: '40px',
    },
  },
  '#app-header': {
    '.abyss-nav-menu-list li:last-child': {
      borderLeft: '1px solid var(--abyss-colors-gray3)',
    },
    '.abyss-page-header-container': {
      '.abyss-page-header-vertical-rule': {
        height: '30px',
      },
      height: '52px',
    },
    backgroundColor: '#ffffff',
    top: 0,
    zIndex: '999',
  },
});

export const SubNavStyles = styled('div', {
  '.abyss-nav-menu-columns-root': {
    padding: 'var(--abyss-space-md)',
  },
  '.abyss-nav-menu-item-description': {
    display: 'none',
  },
  '.abyss-nav-menu-item-title': {
    color: 'var(--abyss-colors-foreground)',
    fontSize: 'var(--abyss-fontSizes-sm)',
    lineHeight: '1',
    marginBottom: '0',
  },
  '.abyss-nav-menu-link': {
    minHeight: 'initial',
    padding: 'var(--abyss-space-sm) var(--abyss-space-sm)',
  },
  '.abyss-nav-menu-link-root': {
    display: 'block',
  },
  '.active .abyss-nav-menu-item-title': {
    color: 'var(--abyss-colors-interactive1)',
  },
});

export const LogoutStyles = styled('div', {
  '.abyss-nav-menu-columns-root': {
    padding: 'var(--abyss-space-md)',
  },
  '.abyss-nav-menu-item-description': {
    display: 'none',
  },
  '.abyss-nav-menu-item-title': {
    fontSize: 'var(--abyss-fontSizes-sm)',
    lineHeight: '1',
    marginBottom: '0',
  },
  '.abyss-nav-menu-link': {
    minHeight: 'initial',
    padding: 'var(--abyss-space-sm) var(--abyss-space-sm)',
  },
  '.abyss-nav-menu-link-root': {
    display: 'block',
  },
});
