import { config } from '@abyss/web/tools/config';
import { RemoteLogger } from '@src/requests/mutations/RemoteLogger';
import { isEmpty } from 'lodash';

/**
 * Logger class.
 */
class Logger {
  constructor() {
    this.log = this.log.bind(this);
    this.warn = this.warn.bind(this);
    this.error = this.error.bind(this);
  }

  /**
   * Log an error message to the console.
   *
   * @param message
   * @param remote
   * @param args
   */
  error = (message = '', remote = false, ...args) => {
    if (!isEmpty(message)) {
      let theArgs = args;

      if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
        theArgs = JSON.stringify(args);
      }

      if (remote === true) {
        (async () => {
          await RemoteLogger({ data: theArgs, message, type: 'error' });
        })();
      } else {
        console.error(`${message} -> error:`, theArgs);
      }
    }
  };

  /**
   * Log a message to the console.
   *
   * @param message
   * @param remote
   * @param args
   */
  log = (message = '', remote = false, ...args) => {
    if (!isEmpty(message)) {
      let theArgs = args;

      if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
        theArgs = JSON.stringify(args);
      }

      if (remote === true) {
        (async () => {
          await RemoteLogger({ data: theArgs, message, type: 'info' });
        })();
      } else {
        // eslint-disable-next-line no-console
        console.log(`${message} -> information:`, theArgs);
      }
    }
  };

  /**
   * Log a warning message to the console.
   *
   * @param message
   * @param remote
   * @param args
   */
  warn = (message = '', remote = false, ...args) => {
    if (!isEmpty(message)) {
      let theArgs = args;

      if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
        theArgs = JSON.stringify(args);
      }

      if (remote === true) {
        (async () => {
          await RemoteLogger({ data: theArgs, message, type: 'warn' });
        })();
      } else {
        console.warn(`${message} -> warning:`, theArgs);
      }
    }
  };
}

export const logger = new Logger();
