import { Box } from '@abyss/web/ui/Box';
import { DateInput } from '@abyss/web/ui/DateInput';
import { Divider } from '@abyss/web/ui/Divider';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { TextInput } from '@abyss/web/ui/TextInput';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { ListStates } from '@src/includes/functions';
import { motion } from 'framer-motion';
import React, { useMemo } from 'react';

import fields from './includes/fields.json';
import { Styles } from './includes/styles';

/**
 * Demographics
 *
 * Displays a list of fields to filter by demographics.
 *
 * @returns {Element}
 * @constructor
 */
export const Demographics = () => {
  /**
   * ListStates
   *
   * Retrieves a list of states.
   *
   * @type {{}}
   */
  const States = useMemo(() => {
    return ListStates();
  }, []);

  return (
    <ErrorHandler location="src/routes/private/Search/components/Filters/components/Demographics/Demographics.jsx">
      <Styles>
        <motion.div
          animate="open"
          initial={{ opacity: 0 }}
          variants={{
            closed: { opacity: 0 },
            open: { opacity: 1 },
          }}
        >
          <Box className="box row border">
            <Grid>
              <Grid.Col span={{ xs: '100%' }}>
                <Heading color="black" offset={4}>
                  Demographics
                </Heading>
              </Grid.Col>
            </Grid>
            <Divider color="transparent" margin="$md" />
            <Grid>
              <Grid.Col span={{ lg: 3, xl: 3, xs: 12 }}>
                <TextInput {...fields?.firstName} />
              </Grid.Col>
              <Grid.Col span={{ lg: 3, xl: 3, xs: 12 }}>
                <TextInput {...fields?.lastName} />
              </Grid.Col>
              <Grid.Col span={{ lg: 3, xl: 3, xs: 12 }}>
                <DateInput {...fields?.dob} />
              </Grid.Col>
              <Grid.Col span={{ lg: 3, xl: 3, xs: 12 }}>
                <TextInput {...fields?.phone} />
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col span={{ xs: 4 }}>
                <TextInput {...fields?.addressLine} />
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col span={{ lg: 2, xl: 2, xs: 12 }}>
                <TextInput {...fields?.city} />
              </Grid.Col>
              <Grid.Col span={{ lg: 2, xl: 2, xs: 12 }}>
                <SelectInput
                  {...fields?.state}
                  options={States.map((state) => {
                    return { label: state?.name, value: state?.code };
                  })}
                />
              </Grid.Col>
              <Grid.Col span={{ lg: 2, xl: 2, xs: 12 }}>
                <TextInput {...fields?.zipCode} />
              </Grid.Col>
            </Grid>
          </Box>
        </motion.div>
      </Styles>
    </ErrorHandler>
  );
};
