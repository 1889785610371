import { dayjs } from '@abyss/web/tools/dayjs';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Table as TableComponent } from '@src/components/Table';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { ExpansionRow } from './components/ExpansionRow';
import configuration from './includes/configuration.json';

/**
 * Table
 *
 * Displays the search results in a table.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Table = (props) => {
  const { dataKey, rows } = props;

  /**
   * renderCellMemberName
   *
   * Displays the member name as a link to the profile page.
   *
   * @param row
   * @returns {Element}
   */
  const renderCellMemberName = ({ row }) => {
    return (
      <Link href={`/profile/${row?.original?.eid}/${row?.original?.policyNumber}`}>
        {`${row?.original?.lastName}, ${row?.original?.firstName}`}
      </Link>
    );
  };

  /**
   * displayArrayValue
   *
   * Displays the array value as a comma-separated string.
   *
   * @param cell
   * @returns {Element}
   */
  const displayArrayValue = ({ cell }) => {
    return <React.Fragment>{cell.value ? cell.value.join(',') : '_'}</React.Fragment>;
  };

  /**
   * formatDOB
   *
   * Formats the date of birth.
   *
   * @param cell
   * @returns {Element}
   */
  const formatDOB = ({ cell }) => {
    return <React.Fragment>{cell?.value ? dayjs(cell?.value).format('MM/DD/YYYY') : '-'}</React.Fragment>;
  };

  /**
   * renderCellPrimaryAddress
   *
   * Renders the primary address.
   *
   * @param row
   * @returns {Element}
   */
  const renderCellPrimaryAddress = ({ row }) => {
    return (
      <address>
        <div style={{ wordBreak: 'break-word' }}>
          {row?.original?.addressLine} <br />
          {row?.original?.city}, {row?.original?.state} {row?.original?.zipCode}
        </div>
      </address>
    );
  };

  /**
   * renderCellDateRanges
   *
   * Renders the date ranges.
   *
   * @param args
   * @returns {React.JSX.Element|string}
   */
  const renderCellDateRanges = (args) => {
    const { row } = args;
    if (row?.original?.policyStartDate && row?.original?.policyEndDate) {
      const startDate = dayjs(row?.original?.policyStartDate).isValid()
        ? dayjs(row?.original?.policyStartDate).format('MM/DD/YYYY')
        : row?.original?.policyStartDate;
      const endDate = dayjs(row?.original?.policyEndDate).isValid()
        ? dayjs(row?.original?.policyEndDate).format('MM/DD/YYYY')
        : row?.original?.policyEndDate;
      return (
        <React.Fragment>
          {!dayjs(startDate).isValid() && !dayjs(endDate).isValid() ? (
            <div style={{ wordBreak: 'break-word' }}>Record Not Found</div>
          ) : (
            <div style={{ wordBreak: 'break-word' }}>
              {startDate} to {endDate}
            </div>
          )}
        </React.Fragment>
      );
    }
    return '';
  };

  /**
   * renderCellCagId
   *
   * Renders the CAG ID.
   *
   * @param row
   * @returns {React.JSX.Element|string}
   */
  const renderCellCagId = ({ row }) => {
    if (isEmpty(row?.original?.cagId)) {
      return '';
    }

    return (
      <Layout.Stack grow>
        <div>
          <strong>Carrier ID:</strong> {row?.original?.cagId?.[0]?.carrierId}
        </div>
        <div>
          <strong>Account ID:</strong> {row?.original?.cagId?.[0]?.accountId}
        </div>
        <div>
          <strong>Group ID:</strong> {row?.original?.cagId?.[0]?.groupId}
        </div>
        <div>
          <strong>Member ID:</strong> {row?.original?.cagId?.[0]?.memberId}
        </div>
      </Layout.Stack>
    );
  };

  /**
   * Columns for the Table
   */
  const columns = useMemo(() => {
    return configuration?.initialColumns.map((item) => {
      const column = item;

      if (column.Header === 'Member Name') {
        column.Cell = renderCellMemberName;
      }

      if (column.Header === 'Alt Member ID') {
        column.Cell = displayArrayValue;
      }

      if (column.Header === 'CAG ID') {
        column.Cell = renderCellCagId;
      }

      if (column.Header === 'Primary Address') {
        column.Cell = renderCellPrimaryAddress;
      }

      if (column.Header === 'Date Ranges') {
        column.Cell = renderCellDateRanges;
      }

      if (column.Header === 'DOB') {
        column.Cell = formatDOB;
      }

      return column;
    });
  }, []);

  /**
   * Rows for the Table
   */
  const theRows = useMemo(() => {
    return rows.map((row) => {
      const item = { ...row };
      if (row?.isConfCommAddress === false) {
        item.hideExpansion = true;
      }
      return item;
    });
  }, [rows]);

  return (
    <ErrorHandler location="src/routes/private/Search/components/Results/components/Table/Table.jsx">
      <TableComponent
        {...{
          columns,
          configuration: {
            ...configuration,
            ...{
              data: theRows,
              renderSubComponent: ({ original }) => {
                return <ExpansionRow row={original} />;
              },
            },
          },
          dataKey,
          rows,
        }}
      />
    </ErrorHandler>
  );
};

Table.propTypes = {
  dataKey: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      isConfCommAddress: PropTypes.bool,
      original: PropTypes.shape({
        addressLine: PropTypes.string,
        cagId: PropTypes.arrayOf(
          PropTypes.shape({
            accountId: PropTypes.string,
            carrierId: PropTypes.string,
            groupId: PropTypes.string,
            memberId: PropTypes.string,
          })
        ),
        city: PropTypes.string,
        dob: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        policyEndDate: PropTypes.string,
        policyNumber: PropTypes.string,
        policyStartDate: PropTypes.string,
        state: PropTypes.string,
        zipCode: PropTypes.string,
      }),
    })
  ),
};

Table.defaultProps = {
  dataKey: 'searchResults',
  rows: [],
};
