import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Button: WorkQueue
 *
 * Button to view the Work Queue for this member.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const WorkQueue = (props) => {
  const { eid, policyNumber } = props;

  return (
    <ErrorHandler location="src/routes/private/MemberProfile/components/Buttons/WorkQueue/Button.jsx">
      <motion.div
        animate="open"
        initial={{ opacity: 0 }}
        variants={{
          closed: { opacity: 0 },
          open: { opacity: 1 },
        }}
      >
        <Button
          after={<IconSymbol icon="chevron_right" />}
          aria-haspopup="dialog"
          css={{ marginRight: 'var(--abyss-space-sm)' }}
          href={`/work-queue/${eid}/${policyNumber}`}
          variant="outline"
        >
          Work Queue
        </Button>
      </motion.div>
    </ErrorHandler>
  );
};

WorkQueue.propTypes = {
  eid: PropTypes.string,
  policyNumber: PropTypes.string,
};

WorkQueue.defaultProps = {
  eid: '',
  policyNumber: '',
};
