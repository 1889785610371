/* eslint-disable no-lonely-if */
import { useForm } from '@abyss/web/hooks/useForm';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { useScrollTrigger } from '@abyss/web/hooks/useScrollTrigger';
import { dayjs } from '@abyss/web/tools/dayjs';
import { Alert } from '@abyss/web/ui/Alert';
import { Box } from '@abyss/web/ui/Box';
import { Checkbox } from '@abyss/web/ui/Checkbox';
import { CheckboxGroup } from '@abyss/web/ui/CheckboxGroup';
import { Flex } from '@abyss/web/ui/Flex';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Layout } from '@abyss/web/ui/Layout';
import { Modal } from '@abyss/web/ui/Modal';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import { Tabs } from '@abyss/web/ui/Tabs';
import { Text } from '@abyss/web/ui/Text';
import { TextInputArea } from '@abyss/web/ui/TextInputArea';
import { toast } from '@abyss/web/ui/Toast';
import { Button } from '@src/components/Button';
import { DateRange } from '@src/components/DateRange';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useApi } from '@src/context/Api';
import { useCurrentUser } from '@src/hooks/useCurrentUser';
import { logger } from '@src/includes/logger';
import { isEmpty, isUndefined, merge, omit } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Details } from '../../Details';
import { isEqual } from '../includes/functions';
import { Address } from './components/Address';
import fields from './includes/fields.json';
import { Styles } from './includes/styles';

/**
 * Modal: Qmcso
 *
 * Address form for QMCSO.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Qmcso = (props) => {
  const {
    addresses,
    currentEntity,
    currentVersion,
    GetAddresses,
    GetMember,
    handleClose,
    isOpen,
    memberData,
    mode,
    policyExists,
  } = props;

  const [dateIsVoid, setDateIsVoid] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [isAddressValid, setIsAddressValid] = useState(true);
  const [isDateRangeApiValid, setisDateRangeApiValid] = useState(true);
  const [prevSubmittedValues, setPrevSubmittedValues] = useState({});
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [isInternationalDisabled, setIsInternationalDisabled] = useState(false);
  const [voidConfirmed, setVoidConfirmed] = useState(false);
  const [voidFormDisabled, setVoidFormDisabled] = useState(false);

  const { scrollableRef, scrollIntoView, targetRef } = useScrollTrigger();

  const dateRangeRef = useRef();

  const { emailAddress, roles } = useCurrentUser();
  const { clearApiCache, useApiMutation } = useApi();

  const [SaveAddress] = useApiMutation('SaveAddress', { excludedHttpCodes: [400] });

  const modeLabel = useMemo(() => {
    let theLabel = 'Sav';

    if (mode === 'create') {
      theLabel = 'Creat';
    }

    if (mode === 'edit') {
      theLabel = 'Updat';
    }

    return theLabel;
  }, []);

  const router = useRouter();
  const currentDate = dayjs(new Date()).format('MM/DD/YYYY');

  /**
   * addressDates
   *
   * List of address dates.
   *
   * @type {*[]}
   */
  const addressDates = useMemo(() => {
    let theDates = [];

    if (!isUndefined(addresses) && !isEmpty(addresses)) {
      theDates = addresses
        .filter((address) => {
          if (mode === 'edit') {
            return address?.confCommEntryId !== currentEntity?.confCommEntryId;
          }
          return address;
        })
        .map((address) => {
          return {
            ccEffectiveDate: dayjs(address?.ccEffectiveDate).format('MM/DD/YYYY'),
            ccEndDate: dayjs(address?.ccEndDate).format('MM/DD/YYYY'),
          };
        });
    }

    return theDates;
  }, [addresses, mode, currentEntity]);

  const defaultValues = {
    api: {
      administrativeNotes: '',
      businessRelationshipType: 'UHG',
      ccEffectiveDate: currentDate,
      ccEndDate: '12/31/9999',
      confComType: 'QMCSO',
      created: dayjs().format('YYYY-MM-DD'),
      digitalRestriction: true,
      email: '',
      impactedDocs: 'ALL_DOCUMENTS',
      lastModifiedDate: dayjs().format('YYYY-MM-DD'),
      lastModifiedRequestor: emailAddress,
      lastSourceModified: 'PROTECT',
      mailReroute: true,
      notes: '',
      orderOfProtectionInPlace: false,
      originalRequestor: 'PROTECT',
      recordStatus: 'AVAILABLE',
    },
    ui: {
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      administrativeNotes: '',
      agencyCaseId: '',
      agentOfRecord: '',
      agentPhoneNumber: '',
      agentPhoneType: '',
      applyFor: [],
      city: '',
      countryCode: 'US',
      dateRange: {
        end: !isUndefined(currentEntity?.ccEndDate)
          ? dayjs(currentEntity?.ccEndDate).format('MM/DD/YYYY')
          : '12/31/9999',
        start: !isUndefined(currentEntity?.ccEffectiveDate)
          ? dayjs(currentEntity?.ccEffectiveDate).format('MM/DD/YYYY')
          : currentDate,
      },
      guardianFirstName: '',
      guardianLastName: '',
      guardianPhoneNumber: '',
      guardianPhoneType: '',
      internationalAddress: {
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        city: '',
        countryCode: '',
        postalCode: '',
        stateOrRegion: '',
      },
      isInternational: false,
      notes: '',
      postalCode: '',
      stateOrRegion: '',
      usAddress: {
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        city: '',
        countryCode: 'US',
        postalCode: '',
        stateOrRegion: '',
      },
    },
  };

  const form = useForm({ defaultValues });

  const dateRange = form?.getValues('ui[dateRange]');
  const formValues = form?.getValues();

  const formatPhoneNumber = (input) => {
    const cleaned = input.replace(/\D/g, '');
    // Match and format the number as (XXX) XXX-XXXX
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    return match ? `(${match[1]}) ${match[2]}-${match[3]}` : '';
  };

  const mergeValues = (defaultData, usAddress, internationalAddress) => {
    return merge(defaultData, {
      api: {
        administrativeNotes: currentEntity?.administrativeNotes,
        digitalRestriction: currentEntity?.digitalRestriction,
        email: currentEntity?.email,
        impactedDocs: currentEntity?.impactedDocs,
        lastModifiedDate: currentEntity?.lastModifiedDate,
        mailReroute: currentEntity?.mailReroute,
        notes: currentEntity?.notes,
        orderOfProtectionInPlace: currentEntity?.orderOfProtectionInPlace,
      },
      ui: {
        applyFor: currentEntity?.businessRelationshipType?.split(','),
        city: currentEntity?.address?.city,
        countryCode: currentEntity?.address?.countryCode,
        dateRange: {
          end: dayjs(currentEntity?.ccEndDate).format('MM/DD/YYYY'),
          start: dayjs(currentEntity?.ccEffectiveDate).format('MM/DD/YYYY'),
        },
        internationalAddress: {
          addressLine1: internationalAddress?.line1,
          addressLine2: internationalAddress?.line2,
          addressLine3: '',
          agencyCaseId: currentEntity?.agencyCaseId,
          agentOfRecord: currentEntity?.agentOfRecord,
          agentPhoneNumber: currentEntity?.agentPhone?.number
            ? formatPhoneNumber(currentEntity?.agentPhone?.number)
            : '',
          agentPhoneType: currentEntity?.agentPhone?.type,
          city: internationalAddress?.city,
          countryCode: internationalAddress?.countryCode,
          guardianFirstName: currentEntity?.guardianFirstName,
          guardianLastName: currentEntity?.guardianLastName,
          guardianPhoneNumber: currentEntity?.guardianPhone?.number
            ? formatPhoneNumber(currentEntity?.guardianPhone?.number)
            : '',
          guardianPhoneType: currentEntity?.guardianPhone?.type,
          isInternational: true,
          postalCode: internationalAddress?.postalCode,
          stateOrRegion: internationalAddress?.stateOrRegion,
        },
        isInternational: !!internationalAddress,
        postalCode: currentEntity?.address?.postalCode,
        stateOrRegion: currentEntity?.address?.stateOrRegion,
        usAddress: {
          addressLine1: usAddress?.line1,
          addressLine2: usAddress?.line2,
          addressLine3: '',
          agencyCaseId: currentEntity?.agencyCaseId,
          agentOfRecord: currentEntity?.agentOfRecord,
          agentPhoneNumber: currentEntity?.agentPhone?.number
            ? formatPhoneNumber(currentEntity?.agentPhone?.number)
            : '',
          agentPhoneType: currentEntity?.agentPhone?.type,
          city: usAddress?.city,
          countryCode: usAddress?.countryCode,
          guardianFirstName: currentEntity?.guardianFirstName,
          guardianLastName: currentEntity?.guardianLastName,
          guardianPhoneNumber: currentEntity?.guardianPhone?.number
            ? formatPhoneNumber(currentEntity?.guardianPhone?.number)
            : '',
          guardianPhoneType: currentEntity?.guardianPhone?.type,
          isInternational: false,
          postalCode: usAddress?.postalCode,
          stateOrRegion: usAddress?.stateOrRegion,
        },
      },
    });
  };
  /**
   * Mapping data loaded from API to the form state.
   */
  useEffect(() => {
    if (mode === 'edit' && !isUndefined(currentEntity) && !isEmpty(currentEntity)) {
      const { addresses: addressesArr } = currentEntity;

      const usAddress = addressesArr.find((address) => {
        return address.isInternational === false;
      });

      const internationalAddress = addressesArr.find((address) => {
        return address.isInternational === true;
      });

      if (internationalAddress) {
        setIsInternationalDisabled(true);
      }

      const data = mergeValues(defaultValues, usAddress, internationalAddress);

      if (dayjs(data.ui.dateRange.start).isSame(dayjs(data.ui.dateRange.end))) {
        setVoidFormDisabled(true);
      }

      if (isEmpty(initialValues)) {
        setInitialValues(data);
      }

      setIsAddressValid(true);

      form?.reset(data, {
        keepDirty: false,
        keepDirtyValues: false,
        keepErrors: false,
        keepIsValid: false,
        keepSubmitCount: true,
        keepTouched: false,
        keepValues: false,
      });
    }

    if (mode === 'create') {
      setInitialValues(defaultValues);
      setVoidFormDisabled(false);
    }
  }, [mode, currentEntity]);

  /**
   * createAddressesPayload
   *
   * Creates the address payload for the API.
   *
   * @param submittedValues
   * @returns {*[]}
   */
  const createAddressesPayload = (submittedValues) => {
    const formAddresses = [];
    if (submittedValues?.ui?.isInternational) {
      formAddresses.push({
        city: submittedValues?.ui?.internationalAddress?.city,
        countryCode: submittedValues?.ui?.internationalAddress?.countryCode,
        isInternational: true,
        line1: submittedValues?.ui?.internationalAddress?.addressLine1,
        line2: submittedValues?.ui?.internationalAddress?.addressLine2,
        line3: '',
        postalCode: submittedValues?.ui?.internationalAddress?.postalCode,
        stateOrRegion: submittedValues?.ui?.internationalAddress?.stateOrRegion,
      });
    }
    formAddresses.push({
      city: submittedValues?.ui?.usAddress?.city,
      countryCode: submittedValues?.ui?.usAddress?.countryCode,
      isInternational: false,
      line1: submittedValues?.ui?.usAddress?.addressLine1,
      line2: submittedValues?.ui?.usAddress?.addressLine2,
      line3: '',
      postalCode: submittedValues?.ui?.usAddress?.postalCode,
      stateOrRegion: submittedValues?.ui?.usAddress?.stateOrRegion,
    });
    return formAddresses;
  };

  /**
   * validatePhoneNumber
   *
   * Validates the phone number.
   *
   * @param phoneNumber
   * @returns {boolean}
   */
  const validatePhoneNumber = (phoneNumber) => {
    // Regular expression to validate the full phone number with hyphens and area code rules
    const phoneNumberRegex = /^(\d)(?!\1{2})\d{2}\d{3}\d{4}$/;

    // Check if the phone number matches the basic format and area code validation
    if (!phoneNumberRegex.test(phoneNumber)) {
      return false;
    }

    // Remove hyphens and check if all digits are the same
    const digitsOnly = phoneNumber.replace(/-/g, '');
    const firstDigit = digitsOnly[0];

    // Check if all 10 digits are the same
    if (
      digitsOnly.split('').every((digit) => {
        return digit === firstDigit;
      })
    ) {
      return false;
    }

    return true;
  };

  /**
   * validatePhoneNumbers
   *
   * Validates the phone numbers.
   *
   * @param submittedValues
   * @param phoneFields
   * @returns {boolean}
   */
  const validatePhoneNumbers = (submittedValues, phoneFields) => {
    const { isInternational } = submittedValues?.ui || {};
    if (phoneFields.agentPhone && !validatePhoneNumber(phoneFields.agentPhone.number)) {
      form.setError(
        isInternational ? 'ui[internationalAddress][agentPhoneNumber]' : 'ui[usAddress][agentPhoneNumber]',
        {
          message: 'There is an error!',
          type: 'manual',
        }
      );
      form.setFocus(isInternational ? 'ui[internationalAddress][agentPhoneNumber]' : 'ui[usAddress][agentPhoneNumber]');
      return false;
    }
    if (phoneFields.guardianPhone && !validatePhoneNumber(phoneFields.guardianPhone.number)) {
      form.setError(
        isInternational ? 'ui[internationalAddress][guardianPhoneNumber]' : 'ui[usAddress][guardianPhoneNumber]',
        {
          message: 'There is an error!',
          type: 'manual',
        }
      );
      form.setFocus(
        isInternational ? 'ui[internationalAddress][guardianPhoneNumber]' : 'ui[usAddress][guardianPhoneNumber]'
      );
      return false;
    }
    return true;
  };

  /**
   * createPayload
   *
   * Creates the payload for the API.
   *
   * @param submittedValues
   * @param phoneFields
   * @returns {}
   */
  const createPayload = (submittedValues, phoneFields) => {
    const { dateRange: dateRangeObj, internationalAddress, isInternational, usAddress } = submittedValues?.ui || {};
    return {
      data: {
        ...submittedValues?.api,
        addresses: createAddressesPayload(submittedValues),
        agencyCaseId: isInternational ? internationalAddress?.agencyCaseId : usAddress?.agencyCaseId,
        agentOfRecord: isInternational ? internationalAddress?.agentOfRecord : usAddress?.agentOfRecord,
        agentPhone: phoneFields.agentPhone,
        ccEffectiveDate: dayjs(dateRangeObj?.start).format('YYYY-MM-DD'),
        ccEndDate: dayjs(dateRangeObj?.end).format('YYYY-MM-DD'),
        guardianFirstName: isInternational ? internationalAddress?.guardianFirstName : usAddress?.guardianFirstName,
        guardianLastName: isInternational ? internationalAddress?.guardianLastName : usAddress?.guardianLastName,
        guardianPhone: phoneFields.guardianPhone,
        impactedDocs: submittedValues?.api?.impactedDocs,
        limitedAccessGroup: memberData?.limitedAccessGroup,
      },
      dob: memberData?.dob,
      eid: memberData?.eid,
      mode,
      newDocument: !policyExists,
      newPolicy: policyExists && !memberData?.isConfCommAddress,
      policyNumber: memberData?.policyNumber,
      version: currentVersion,
    };
  };

  /**
   * handleSubmit
   *
   * Sends the form data to the remote API to be saved in the database.
   *
   * @param submittedValues
   * @returns {Promise<boolean>}
   */
  const handleSubmit = async (submittedValues) => {
    const currentTimeISO = dayjs().toISOString();
    setPrevSubmittedValues(submittedValues);

    try {
      const validateGuardianAgent = () => {
        if (submittedValues?.ui?.isInternational) {
          if (
            submittedValues?.ui?.internationalAddress?.agentOfRecord === '' &&
            submittedValues?.ui?.internationalAddress?.guardianFirstName === ''
          ) {
            setIsAddressValid(true);
            scrollIntoView();
          }
        } else if (
          submittedValues?.ui?.usAddress?.agentOfRecord === '' &&
          submittedValues?.ui?.usAddress?.guardianFirstName === ''
        ) {
          setIsAddressValid(true);
          scrollIntoView();
        }
      };

      validateGuardianAgent();

      const getPhoneFields = () => {
        const theFields = {};

        if (submittedValues?.ui?.isInternational) {
          if (
            submittedValues?.ui?.internationalAddress?.agentPhoneNumber &&
            submittedValues?.ui?.internationalAddress?.agentPhoneType
          ) {
            theFields.agentPhone = {
              countryCode: submittedValues?.ui?.internationalAddress?.countryCode,
              // format phone number like xxx-xxx-xxxx
              number: submittedValues?.ui?.internationalAddress?.agentPhoneNumber
                .replace(/\D+/g, '')
                .replace(/^(\d{3})(\d{3})(\d{4}).*/, '$1$2$3'),
              type: submittedValues?.ui?.internationalAddress?.agentPhoneType,
            };
          }

          if (
            submittedValues?.ui?.internationalAddress?.guardianPhoneNumber &&
            submittedValues?.ui?.internationalAddress?.guardianPhoneType
          ) {
            theFields.guardianPhone = {
              countryCode: submittedValues?.ui?.internationalAddress?.countryCode,
              // format phone number like xxx-xxx-xxxx
              number: submittedValues?.ui?.internationalAddress?.guardianPhoneNumber
                .replace(/\D+/g, '')
                .replace(/^(\d{3})(\d{3})(\d{4}).*/, '$1$2$3'),
              type: submittedValues?.ui?.internationalAddress?.guardianPhoneType,
            };
          }
        } else {
          if (submittedValues?.ui?.usAddress?.agentPhoneNumber && submittedValues?.ui?.usAddress?.agentPhoneType) {
            theFields.agentPhone = {
              countryCode: 'US',
              // format phone number like xxx-xxx-xxxx
              number: submittedValues?.ui?.usAddress?.agentPhoneNumber
                .replace(/\D+/g, '')
                .replace(/^(\d{3})(\d{3})(\d{4}).*/, '$1$2$3'),
              type: submittedValues?.ui?.usAddress?.agentPhoneType,
            };
          }

          if (
            submittedValues?.ui?.usAddress?.guardianPhoneNumber &&
            submittedValues?.ui?.usAddress?.guardianPhoneType
          ) {
            theFields.guardianPhone = {
              countryCode: 'US',
              // format phone number like xxx-xxx-xxxx
              number: submittedValues?.ui?.usAddress?.guardianPhoneNumber
                .replace(/\D+/g, '')
                .replace(/^(\d{3})(\d{3})(\d{4}).*/, '$1$2$3'),
              type: submittedValues?.ui?.usAddress?.guardianPhoneType,
            };
          }
        }

        return theFields;
      };

      const phoneFields = getPhoneFields();

      const payload = createPayload(submittedValues, phoneFields);

      if (!validatePhoneNumbers(submittedValues, phoneFields)) {
        return false;
      }

      if (!isEmpty(submittedValues?.ui?.applyFor)) {
        payload.data.businessRelationshipType = submittedValues?.ui?.applyFor?.join(',');
      }

      // For edit added address id
      if (mode === 'edit') {
        payload.data.confCommEntryId = currentEntity?.confCommEntryId;
        payload.data.recordStatus = voidConfirmed ? 'VOID' : 'AVAILABLE';
      }

      const toastId = 'save-address-QMCSO';

      toast.show({
        ariaLoadingLabel: `${modeLabel}ing Address`,
        autoClose: true,
        id: `${toastId}-info`,
        isPending: true,
        message: `The address is preparing to ${modeLabel.toLowerCase()}e.`,
        title: `${modeLabel}ing QMCSO Address...`,
        variant: 'info',
      });
      // Set last modified date to current date and time zone
      payload.data.lastModifiedDate = currentTimeISO;

      await SaveAddress(payload, {
        onError: (errorObject) => {
          const { error } = errorObject;
          /**
           * @TODO - We have to remove this conditions once the API provide proper error message
           * story num :Effective start and end Dates - no overlap allowed - save button #187
           */
          if (error?.response?.status?.detailedErrorMessage?.includes('OVERLAPPING ADDRESS IN POLICY')) {
            setisDateRangeApiValid(false);
            form.setFocus('ui.dateRange.start');
          }

          toast.hide(`${toastId}-info`);

          toast.show({
            id: `${toastId}-error`,
            message: `There was an error ${modeLabel}ing the address.`,
            title: `Failed to ${modeLabel}e QMCSO Address`,
            variant: 'error',
          });
        },
        onSuccess: () => {
          handleClose();

          clearApiCache(['GetAddresses', 'GetMember']);

          GetAddresses({ ...memberData, isConfCommAddress: true });
          GetMember({ eid: memberData.eid, policyNumber: memberData.policyNumber });

          toast.hide(`${toastId}-info`);

          toast.show({
            autoClose: true,
            id: `${toastId}-success`,
            message: (
              <Layout.Stack alignItems="left">
                <Text color="white">Address has been successfully {modeLabel.toLowerCase()}ed.</Text>
                <Button
                  onClick={() => {
                    router?.navigate('/search');
                    toast.hide(`${toastId}-success`);
                  }}
                  variant="outline"
                >
                  New Search
                </Button>
              </Layout.Stack>
            ),
            title: `QMCSO Address ${modeLabel}ed`,
            variant: 'success',
          });

          form?.reset(defaultValues, {
            keepDirty: false,
            keepDirtyValues: false,
            keepErrors: false,
            keepIsValid: false,
            keepSubmitCount: true,
            keepTouched: false,
            keepValues: false,
          });
        },
      });

      setIsAddressValid(true);
      setIsInternationalDisabled(false);
    } catch (error) {
      logger.error('src/routes/private/MemberProfile/components/Modals/Qmcso/Modal.jsx -> handleSubmit', false, error);
    }
    return true;
  };

  /**
   * Verify the date range does not overlap with existing addresses.
   */
  useEffect(() => {
    let datesAreVoid = false;

    const dateRangeValues = form?.getValues('ui[dateRange]');
    const startDate = dayjs(dateRangeValues?.start);
    const endDate = dayjs(dateRangeValues?.end);

    if (startDate.isSame(endDate)) {
      datesAreVoid = true;
    }

    if (datesAreVoid && mode === 'edit') {
      setDateIsVoid(true);
    } else {
      setDateIsVoid(false);
    }

    const prevStart = prevSubmittedValues?.ui?.dateRange?.start;
    const prevEnd = prevSubmittedValues?.ui?.dateRange?.end;

    const uiStartDate = dayjs(formValues?.ui?.dateRange?.start).format('YYYY-MM-DD');
    const uiEndDate = dayjs(formValues?.ui?.dateRange?.end).format('YYYY-MM-DD');

    if (
      (prevStart !== undefined && !dayjs(uiStartDate).isSame(dayjs(prevStart))) ||
      (prevEnd !== undefined && !dayjs(uiEndDate).isSame(dayjs(prevEnd)))
    ) {
      setisDateRangeApiValid(true);
    }
  }, [addressDates, addresses, form?.formState?.errors, formValues]);

  /**
   * form validation rules, toggling the save button enablement.
   */
  useEffect(() => {
    const shouldDisableForm = () => {
      if ((dateIsVoid && !voidConfirmed) || !isAddressValid || !isDateRangeApiValid) {
        return true;
      }

      if (mode === 'create') {
        return !form?.formState?.isValid;
      }

      if (!isEmpty(initialValues) && mode === 'edit') {
        return isEqual(formValues, initialValues) || !form?.formState?.isValid;
      }

      return false;
    };

    setIsFormDisabled(shouldDisableForm());
  }, [dateIsVoid, form?.formState?.isValid, formValues, initialValues, isAddressValid, isDateRangeApiValid, mode]);

  return (
    <ErrorHandler location="src/routes/private/MemberProfile/components/Modals/Qmcso/Modal.jsx">
      <Modal
        closeOnClickOutside={false}
        closeOnEscPress={false}
        isOpen={isOpen}
        onClose={() => {
          handleClose();
          form?.reset(defaultValues, {
            keepDirty: false,
            keepDirtyValues: false,
            keepErrors: false,
            keepIsValid: false,
            keepSubmitCount: true,
            keepTouched: false,
            keepValues: false,
          });
        }}
        ref={scrollableRef}
        size="1200px"
        title={`${modeLabel}e QMCSO Address`}
      >
        <Styles>
          <FormProvider autoComplete="off" onSubmit={handleSubmit} state={form}>
            <Modal.Section className="modal-body">
              <Details memberData={memberData} showBorder showDemographics={false} showIdentifiers showName={false} />
              <Grid className="zero">
                <Grid.Col span={{ lg: 4, xl: 4, xs: 12 }}>
                  <Text size="$sm">
                    <strong>Only apply this to:</strong>
                    <br />
                    <em>Defaults to UHG when no selection is made.</em>
                  </Text>
                  <CheckboxGroup {...omit(fields?.applyFor, 'options')}>
                    {fields?.applyFor?.options?.map((option) => {
                      return <Checkbox key={option.value} label={option.label} value={option.value} />;
                    })}
                  </CheckboxGroup>
                </Grid.Col>
                <Grid.Col span={{ lg: 4, xl: 4, xs: 12 }}>
                  <RadioGroup {...omit(fields?.impactedDocs, 'options')} isDisabled={voidFormDisabled}>
                    {fields?.impactedDocs?.options?.map((option) => {
                      return <RadioGroup.Radio key={option.value} label={option.label} value={option.value} />;
                    })}
                  </RadioGroup>
                </Grid.Col>
                <Grid.Col span={{ lg: 4, xl: 4, xs: 12 }}>
                  <DateRange
                    {...fields?.dateRange}
                    defaultEndDate={dateRange?.end}
                    defaultStartDate={dateRange?.start}
                    isDisabled={voidFormDisabled}
                    isStartDisabled={mode === 'edit'}
                    onChange={() => {
                      setisDateRangeApiValid(true);
                    }}
                    ref={dateRangeRef}
                  />

                  {dateIsVoid && !voidConfirmed && !voidFormDisabled && (
                    <Alert actionText="Confirm" title="" variant="error">
                      <Text fontWeight="var(--abyss-fontWeights-medium)" size="$xs">
                        Please verify, are you sure you want to void this record?
                      </Text>
                      <Flex justify="start" style={{ gap: '10px' }}>
                        <Button
                          onClick={() => {
                            setVoidConfirmed(true);
                            setIsFormDisabled(false);
                          }}
                          size="$sm"
                          variant="destructive"
                        >
                          Confirm
                        </Button>
                        <Button
                          onClick={() => {
                            form.reset({
                              dateRange: {
                                end: !isUndefined(currentEntity?.ccEndDate)
                                  ? dayjs(currentEntity?.ccEndDate).format('MM/DD/YYYY')
                                  : '12/31/9999',
                                start: !isUndefined(currentEntity?.ccEffectiveDate)
                                  ? dayjs(currentEntity?.ccEffectiveDate).format('MM/DD/YYYY')
                                  : currentDate,
                              },
                            });
                            setDateIsVoid(false);
                            if (dateRangeRef.current) {
                              dateRangeRef.current.focus();
                            }
                          }}
                          size="$sm"
                          variant="destructive"
                        >
                          Cancel
                        </Button>
                      </Flex>
                    </Alert>
                  )}

                  {!dateIsVoid && !isDateRangeApiValid ? (
                    <Alert title="" variant="error">
                      <Text fontWeight="var(--abyss-fontWeights-medium)" size="$xs">
                        Please review timeline as may overlap with existing.
                      </Text>
                    </Alert>
                  ) : (
                    <Text fontWeight="var(--abyss-fontWeights-medium)" size="$xs">
                      Date Format: mm/dd/yyyy
                    </Text>
                  )}
                </Grid.Col>
                <Grid.Col span={{ lg: 4, xl: 4, xs: 12 }}>
                  <Flex className="checkboxAlignment">
                    <Text>{fields?.orderOfProtectionInPlace?.label}</Text>
                    <Checkbox {...fields?.orderOfProtectionInPlace} isDisabled={voidFormDisabled} />
                  </Flex>
                </Grid.Col>
                <Grid.Col span={{ lg: 4, xl: 4, xs: 12 }}>
                  <Flex className="checkboxAlignment">
                    <Text>{fields?.mailReroute?.label}</Text>
                    <Checkbox {...fields?.mailReroute} isDisabled={voidFormDisabled} />
                  </Flex>
                </Grid.Col>
                <Grid.Col span={{ lg: 4, xl: 4, xs: 12 }}>
                  <Flex className="checkboxAlignment">
                    <Text>{fields?.digitalRestriction?.label}</Text>
                    <Checkbox {...fields?.digitalRestriction} isDisabled={voidFormDisabled} />
                  </Flex>
                </Grid.Col>
                <Grid.Col span={{ lg: 4, xl: 4, xs: 12 }}>
                  <Flex className="is-international-addr">
                    <Text>{fields?.isInternational?.label}</Text>
                    <Checkbox {...fields?.isInternational} isDisabled={voidFormDisabled || isInternationalDisabled} />
                  </Flex>
                </Grid.Col>
              </Grid>
              <Address
                form={form}
                isAddressValid={isAddressValid}
                mode={mode}
                setIsAddressValid={setIsAddressValid}
                targetRef={targetRef}
                voidFormDisabled={voidFormDisabled}
              />

              <Grid className="section">
                <Box className="box">
                  <Heading offset={5}>Notes:</Heading>
                </Box>
                <Grid.Col span={{ lg: 12, xl: 12, xs: 12 }}>
                  <Tabs grow={false} size="sm">
                    <Tabs.Tab label={fields?.notes?.label}>
                      <TextInputArea {...fields?.notes} isDisabled={voidFormDisabled} />
                    </Tabs.Tab>
                    <Tabs.Tab label={fields?.administrativeNotes?.label}>
                      <TextInputArea {...fields?.administrativeNotes} isDisabled={voidFormDisabled} />
                    </Tabs.Tab>
                  </Tabs>
                </Grid.Col>
              </Grid>
            </Modal.Section>
            <Modal.Section className="modal-footer">
              <Layout.Group alignLayout="right">
                <Button
                  onClick={() => {
                    handleClose();
                    form?.reset(defaultValues, {
                      keepDirty: false,
                      keepDirtyValues: false,
                      keepErrors: false,
                      keepIsValid: false,
                      keepSubmitCount: true,
                      keepTouched: false,
                      keepValues: false,
                    });
                  }}
                  type="button"
                  variant="outline"
                >
                  Close
                </Button>
                <Button
                  isDisabled={!roles.includes('State.Type.Qmcso.Write') || isFormDisabled || voidFormDisabled}
                  type="submit"
                  variant="solid"
                >
                  {modeLabel}e Address
                </Button>
              </Layout.Group>
            </Modal.Section>
          </FormProvider>
        </Styles>
      </Modal>
    </ErrorHandler>
  );
};

Qmcso.propTypes = {
  addresses: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.shape({
        city: PropTypes.string,
        countryCode: PropTypes.string,
        isInternational: PropTypes.bool,
        line1: PropTypes.string,
        line2: PropTypes.string,
        line3: PropTypes.string,
        postalCode: PropTypes.string,
        stateOrRegion: PropTypes.string,
      }),
      ccEffectiveDate: PropTypes.string,
      ccEndDate: PropTypes.string,
      confCommEntryId: PropTypes.string,
      digitalRestriction: PropTypes.bool,
      email: PropTypes.string,
      impactedDocs: PropTypes.string,
      lastModifiedDate: PropTypes.string,
      mailReroute: PropTypes.bool,
      notes: PropTypes.string,
      orderOfProtectionInPlace: PropTypes.bool,
    })
  ),
  currentEntity: PropTypes.shape({
    address: PropTypes.shape({
      city: PropTypes.string,
      countryCode: PropTypes.string,
      isInternational: PropTypes.bool,
      line1: PropTypes.string,
      line2: PropTypes.string,
      line3: PropTypes.string,
      postalCode: PropTypes.string,
      stateOrRegion: PropTypes.string,
    }),
    addresses: PropTypes.arrayOf(
      PropTypes.shape({
        city: PropTypes.string,
        countryCode: PropTypes.string,
        isInternational: PropTypes.bool,
        line1: PropTypes.string,
        line2: PropTypes.string,
        line3: PropTypes.string,
        postalCode: PropTypes.string,
        stateOrRegion: PropTypes.string,
      })
    ),
    administrativeNotes: PropTypes.string,
    agencyCaseId: PropTypes.string,
    agentOfRecord: PropTypes.string,
    agentPhone: PropTypes.shape({
      countryCode: PropTypes.string,
      number: PropTypes.string,
      type: PropTypes.string,
    }),
    businessRelationshipType: PropTypes.string,
    ccEffectiveDate: PropTypes.string,
    ccEndDate: PropTypes.string,
    confCommEntryId: PropTypes.string,
    digitalRestriction: PropTypes.bool,
    email: PropTypes.string,
    guardianFirstName: PropTypes.string,
    guardianLastName: PropTypes.string,
    guardianPhone: PropTypes.shape({
      countryCode: PropTypes.string,
      number: PropTypes.string,
      type: PropTypes.string,
    }),
    impactedDocs: PropTypes.string,
    lastModifiedDate: PropTypes.string,
    mailReroute: PropTypes.bool,
    notes: PropTypes.string,
    orderOfProtectionInPlace: PropTypes.bool,
  }),
  currentVersion: PropTypes.number,
  GetAddresses: PropTypes.func,
  GetMember: PropTypes.func,
  handleClose: PropTypes.func,
  isOpen: PropTypes.bool,
  memberData: PropTypes.shape({
    dob: PropTypes.string,
    eid: PropTypes.string,
    isConfCommAddress: PropTypes.bool,
    limitedAccessGroup: PropTypes.string,
    policyNumber: PropTypes.string,
  }),
  mode: PropTypes.string,
  policyExists: PropTypes.bool,
};

Qmcso.defaultProps = {
  addresses: [],
  currentEntity: {},
  currentVersion: 0,
  GetAddresses: () => {},
  GetMember: () => {},
  handleClose: () => {},
  isOpen: false,
  memberData: {},
  mode: 'create',
  policyExists: false,
};
