import { Router as AbyssRouter } from '@abyss/web/ui/Router';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Loader } from '@src/components/Loader';
import { Login } from '@src/routes/public/Login';
import { Unauthorized } from '@src/routes/public/Unauthorized';
import React from 'react';

/**
 * Public
 *
 * This component serves as a router for the public routes.
 *
 * @returns {Element}
 * @constructor
 */
export const Public = () => {
  return (
    <ErrorHandler location="src/components/Router/Router.jsx">
      <AbyssRouter.Routes>
        <AbyssRouter.Route element={<Loader height="100vh" width="100vh" />} path="/*" />
        <AbyssRouter.Route element={<Login />} path="/login" />
        <AbyssRouter.Route element={<Unauthorized />} path="/unauthorized" />
      </AbyssRouter.Routes>
    </ErrorHandler>
  );
};
