import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '> .row': {
    marginTop: 'var(--abyss-space-md)',
    paddingBottom: 'var(--abyss-space-lg)',
    paddingTop: 'var(--abyss-space-lg)',
    width: '100%',
  },
  'button[class*="isDisabled-true"]': {
    '.abyss-icon': {
      color: 'var(--abyss-colors-gray5) !important',
    },
  },
  marginLeft: 'calc(var(--abyss-space-md) * -1)',
  marginRight: 'calc(var(--abyss-space-md) * -1)',
  ul: {
    listStyleType: 'disc',
    marginLeft: 'var(--abyss-space-lg)',
  },
});
