import { styled } from '@abyss/web/tools/styled';
import { ErrorHandler } from '@src/components/ErrorHandler';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * GlobalStyles
 *
 * @TODO - Needs description.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const GlobalStyles = (props) => {
  const { children } = props;

  const Styles = styled('div', {
    '.abyss-nav-menu-menu button[data-state="open"] .abyss-icon, .abyss-nav-menu-menu button:hover .abyss-icon': {
      color: 'var(--abyss-colors-foreground) !important',
    },
    "*[class*='isInvalid-true']": {
      backgroundColor: 'var(--abyss-colors-error2)',
      border: '1px solid var(--abyss-colors-error1)',
      bottom: '4px',
      padding: '4px',
      position: 'absolute',
      zIndex: '999',
    },
    '*[class*="isDisabled-true"]': {
      '.abyss-icon': {
        color: 'var(--abyss-colors-gray5) !important',
      },
    },
    'main#app-main': {
      minHeight: 'calc(100vh + 150px)',
    },
  });

  return (
    <ErrorHandler location="src/styles/GlobalStyles.jsx">
      <Styles>{children}</Styles>
    </ErrorHandler>
  );
};

GlobalStyles.propTypes = {
  children: PropTypes.node,
};

GlobalStyles.defaultProps = {
  children: null,
};
