import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.abyss-heading-root': {
    color: '#000000 !important',
    marginBottom: 'var(--abyss-space-lg)',
  },
  '.abyss-tabs-root': {
    '.abyss-tabs-content-container': {
      border: 'none',
      paddingLeft: 0,
      paddingRight: 0,
    },
    '.abyss-tabs-tab-active-line': {
      borderColor: 'var(--abyss-colors-accent2)',
    },
  },
  '.abyss-tabs-tab.isDisabled': {
    backgroundColor: 'var(--abyss-colors-gray2)',
    borderColor: 'var(--abyss-colors-gray3)',
    boxShadow: 'none',
    color: 'var(--abyss-colors-gray5)',
    cursor: 'not-allowed',
  },
  '.checkboxAlignment': {
    alignItems: 'center',
    gap: '10px',
    height: '100%',
  },
  '.is-international-addr': {
    alignItems: 'center',
    gap: '10px',
    padding: 'var(--abyss-space-lg) 0px var(--abyss-space-md)',
  },
  '.modal-body': {
    paddingTop: 0,
  },
  '.modal-footer': {
    '.abyss-layout-group': {
      paddingTop: 'var(--abyss-space-sm)',
    },
  },
  '.section': {
    '.box': {
      '.abyss-heading-root': {
        margin: 0,
      },
      background: 'var(--abyss-colors-gray3)',
      borderBottom: '1px solid var(--abyss-colors-gray4)',
      borderTop: '1px solid var(--abyss-colors-gray4)',
      padding: 'var(--abyss-space-sm) var(--abyss-space-md)',
    },
    '&.first': {
      marginTop: 0,
    },
    '&.zero.abyss-grid': {
      '.abyss-grid-col': {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
      },
      paddingLeft: 0,
      paddingRight: 0,
    },
    marginTop: 'var(--abyss-space-lg)',
  },
  '.submit-btn-container': {
    gap: '20px',
    justifyContent: 'flex-end',
    marginTop: 'var(--abyss-space-lg)',
  },
});
