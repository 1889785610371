import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.abyss-flex-root': {
    '.abyss-box-root': {
      '.abyss-card-root': {
        '.abyss-divider-root': {
          backgroundColor: 'var(--abyss-colors-gray3)',
          marginBottom: 'var(--abyss-space-lg)',
          marginTop: 'var(--abyss-space-md)',
        },
        '.abyss-layout-stack': {
          textAlign: 'center',
        },
        '.abyss-link-root': {
          marginTop: 'var(--abyss-space-xl)',
        },
        borderColor: 'var(--abyss-colors-gray4)',
        borderRadius: '0px',
        marginTop: 'var(--abyss-space-md)',
        paddingBottom: 'var(--abyss-space-xl)',
        paddingLeft: 'var(--abyss-space-lg)',
        paddingRight: 'var(--abyss-space-lg)',
        paddingTop: 'var(--abyss-space-xl)',
        position: 'relative',
        width: '100%',
      },
      '.logo': {
        height: 'auto',
        paddingLeft: 'var(--abyss-space-lg)',
        paddingRight: 'var(--abyss-space-lg)',
        width: '100%',
      },
      position: 'relative',
    },
    background: 'var(--abyss-colors-white)',
    height: '100vh !important',
    position: 'relative',
    width: '100vw !important',
  },
});
