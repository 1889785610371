import { useForm } from '@abyss/web/hooks/useForm';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { toast } from '@abyss/web/ui/Toast';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useApi } from '@src/context/Api';
import { useRoutesContext } from '@src/context/Routes';
import { logger } from '@src/includes/logger';
import { ChangeEvents } from '@src/routes/private/WorkQueue/screens/View/components/ChangeEvents';
import { Form } from '@src/routes/private/WorkQueue/screens/View/components/Form';
import { capitalize, isNil, isUndefined } from 'lodash';
import React, { useEffect, useLayoutEffect } from 'react';

import { Details } from './components/Details';
import { EimpInformation } from './components/EimpInformation';
import { ProtectInformation } from './components/ProtectInformation';
import { Skeleton } from './components/Skeleton';

/**
 * View
 *
 * Provides the user with a screen to view a workable item.
 *
 * @returns {Element}
 * @constructor
 */
export const View = () => {
  const { currentRoute } = useRoutesContext();
  const workableItemId = currentRoute?.params?.workableItemId;

  const { clearApiCache, useApiMutation, useApiQuery } = useApi();
  const [GetWorkableItem, { data: workableItem, isFetching, isLoading, refetch }] = useApiQuery('GetWorkableItem');
  const [SaveWorkableItem] = useApiMutation('SaveWorkableItem');

  const defaultValues = {
    notes: '',
    status: '',
  };

  const form = useForm({ defaultValues });

  /**
   * getModifiedSubmittedValues
   *
   * Filters out the values that haven't changed from the form.
   *
   * @param submittedValues
   * @returns {{}}
   */
  const getModifiedSubmittedValues = (submittedValues) => {
    const modifiedSubmittedValues = {};

    const dirtyFields = form?.formState?.dirtyFields;

    Object.keys(submittedValues).forEach((field) => {
      if (!isUndefined(dirtyFields[field])) {
        modifiedSubmittedValues[field] = submittedValues[field];
      }
    });

    return modifiedSubmittedValues;
  };

  /**
   * handleSubmit
   *
   * Sends the form data to the remote API to be saved in the database.
   *
   * @param submittedValues
   * @returns {Promise<void>}
   */
  const handleSubmit = async (submittedValues) => {
    const modifiedSubmittedValues = getModifiedSubmittedValues(submittedValues);
    const payload = { ...{ workableItemId }, ...modifiedSubmittedValues };

    const toastId = 'save-workable-item';
    toast.show({
      ariaLoadingLabel: 'Saving Workable Item',
      autoClose: true,
      id: `${toastId}-info`,
      isPending: true,
      message: `The workable item is preparing to save.`,
      title: `Saving Workable Item...`,
      variant: 'info',
    });

    await SaveWorkableItem(payload, {
      onError: (error) => {
        toast.hide(`${toastId}-info`);
        toast.show({
          id: `${toastId}-error`,
          message: `There was an error saving the workable item.`,
          title: `Failed to Save Workable Item`,
          variant: 'error',
        });
        logger.error('src/routes/private/WorkQueue/screens/View/View.jsx -> handleSubmit', false, error);
      },
      onSuccess: () => {
        clearApiCache(['GetWorkableItem']);
        refetch();
        toast.hide(`${toastId}-info`);
        toast.show({
          autoClose: true,
          id: `${toastId}-success`,
          message: 'Workable item has been successfully saved.',
          title: `Workable Item Saved`,
          variant: 'success',
        });

        form?.reset(defaultValues, {
          keepDirty: false,
          keepDirtyValues: false,
          keepErrors: false,
          keepIsValid: false,
          keepSubmitCount: true,
          keepTouched: false,
          keepValues: false,
        });
      },
    });
  };

  /**
   * Fetch the workable item  if it hasn't been loaded yet.
   */
  useLayoutEffect(() => {
    if (!isNil(workableItemId) && isUndefined(workableItem) && !isLoading && !isFetching) {
      GetWorkableItem({ workableItemId });
    }
  }, [workableItemId, workableItem, isLoading, isFetching]);

  /**
   * Mapping data loaded from API to the form state.
   */
  useEffect(() => {
    if (!isUndefined(workableItem)) {
      form?.reset(
        {
          notes: workableItem?.notes,
          status: workableItem?.status,
        },
        {
          keepDirty: false,
          keepDirtyValues: false,
          keepErrors: false,
          keepIsValid: false,
          keepSubmitCount: true,
          keepTouched: false,
          keepValues: false,
        }
      );
    }
  }, [workableItem]);

  if (isLoading || isFetching) {
    return <Skeleton />;
  }

  return (
    <ErrorHandler location="src/routes/private/ActionPaths/screens/View/View.jsx">
      <Grid>
        <Grid.Col css={{ paddingTop: 'var(--abyss-space-lg)' }} span={{ xs: '100%' }}>
          <Heading offset={0}>Review {capitalize(String(workableItem?.eventType))} Details</Heading>
        </Grid.Col>
        <Grid.Col span={{ xs: '100%' }}>
          <Details workableItem={workableItem} />
        </Grid.Col>
      </Grid>

      <Grid>
        <Grid.Col
          css={{ paddingTop: 'var(--abyss-space-lg)' }}
          span={{
            xs: '50%',
          }}
        >
          <EimpInformation workableItem={workableItem} />
        </Grid.Col>
        <Grid.Col
          css={{ paddingTop: 'var(--abyss-space-lg)' }}
          span={{
            xs: '50%',
          }}
        >
          <Grid>
            <Grid.Col
              span={{
                xs: '100%',
              }}
            >
              <ProtectInformation workableItem={workableItem} />
            </Grid.Col>

            <Grid.Col
              css={{ paddingTop: 'var(--abyss-space-sm)' }}
              span={{
                xs: '100%',
              }}
            >
              <Form form={form} handleSubmit={handleSubmit} workableItem={workableItem} />
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>

      <Grid>
        <Grid.Col
          css={{ paddingTop: 'var(--abyss-space-lg)' }}
          span={{
            xs: '100%',
          }}
        >
          <ChangeEvents workableItem={workableItem} />
        </Grid.Col>
      </Grid>
    </ErrorHandler>
  );
};
