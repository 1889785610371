import { useRouter } from '@abyss/web/hooks/useRouter';
import { config } from '@abyss/web/tools/config';
import { DropdownMenu } from '@abyss/web/ui/DropdownMenu';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Widget } from '@src/components/Widget';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Table } from './components/Table';

/**
 * ProtectInformation
 *
 * Displays information from the Protect database.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const ProtectInformation = (props) => {
  const { workableItem } = props;

  const [rows, setRows] = useState([]);

  const router = useRouter();

  /**
   * define the rows of data for the table
   */
  useEffect(() => {
    const theRows = [];

    const fields = Object.keys(workableItem?.protectOriginalRecord || workableItem?.protectNewRecord || []);

    if (!isEmpty(fields)) {
      fields.forEach((field) => {
        const theField = {
          field,
          newValue: workableItem?.protectNewRecord?.[field],
          originalValue: workableItem?.protectOriginalRecord?.[field],
        };

        theRows.push(theField);
      });
    }

    if (theRows !== rows) {
      setRows(theRows);
    }
  }, [workableItem]);

  return (
    <Widget
      button={
        <DropdownMenu
          after={<IconSymbol icon="keyboard_arrow_down" />}
          className="no-padding no-margin"
          label="View Member Profile"
          menuItems={[
            {
              onClick: () => {
                router?.navigate(
                  `/profile/${workableItem?.protectOriginalRecord?.eid}/${workableItem?.protectOriginalRecord?.policyNumber}`,
                  { replace: false }
                );
              },
              title: 'View Original Member Profile',
            },
            {
              onClick: () => {
                router?.navigate(
                  `/profile/${workableItem?.protectNewRecord?.eid}/${workableItem?.protectNewRecord?.policyNumber}`,
                  { replace: true }
                );
              },
              title: 'View New Member Profile',
            },
          ]}
          outline
        />
      }
      description=""
      title={`${
        workableItem?.confComType === 'CONF_COMM' ? 'ConfComm' : workableItem?.confComType
      } Information From ${config('APP_NAME')}`}
    >
      <Table rows={rows} />
    </Widget>
  );
};

ProtectInformation.propTypes = {
  workableItem: PropTypes.shape({
    confComType: PropTypes.string,
    protectNewRecord: PropTypes.shape({
      eid: PropTypes.string,
      policyNumber: PropTypes.string,
    }),
    protectOriginalRecord: PropTypes.shape({
      eid: PropTypes.string,
      policyNumber: PropTypes.string,
    }),
  }),
};

ProtectInformation.defaultProps = {
  workableItem: {},
};
