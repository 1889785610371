import { useForm } from '@abyss/web/hooks/useForm';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { dayjs } from '@abyss/web/tools/dayjs';
import { Alert } from '@abyss/web/ui/Alert';
import { Box } from '@abyss/web/ui/Box';
import { Checkbox } from '@abyss/web/ui/Checkbox';
import { CheckboxGroup } from '@abyss/web/ui/CheckboxGroup';
import { Flex } from '@abyss/web/ui/Flex';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Layout } from '@abyss/web/ui/Layout';
import { Modal } from '@abyss/web/ui/Modal';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { Tabs } from '@abyss/web/ui/Tabs';
import { Text } from '@abyss/web/ui/Text';
import { TextInput } from '@abyss/web/ui/TextInput';
import { TextInputArea } from '@abyss/web/ui/TextInputArea';
import { toast } from '@abyss/web/ui/Toast';
import { Button } from '@src/components/Button';
import { DateRange } from '@src/components/DateRange';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useApi } from '@src/context/Api';
import { useCurrentUser } from '@src/hooks/useCurrentUser';
import { logger } from '@src/includes/logger';
import { isEmpty, isUndefined, merge, omit } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Details } from '../../Details';
import { isEqual, isRequired } from '../includes/functions';
import { Address } from './components/Address';
import fields from './includes/fields.json';
import { Styles } from './includes/styles';

/**
 * Modal: ConfComm
 *
 * Address form for Confidential Communication.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const ConfComm = (props) => {
  const {
    addresses,
    currentEntity,
    currentVersion,
    GetAddresses,
    GetMember,
    handleClose,
    isOpen,
    memberData,
    mode,
    policyExists,
  } = props;

  const [dateIsVoid, setDateIsVoid] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [isAddressValid, setIsAddressValid] = useState(true);
  const [isDateRangeApiValid, setisDateRangeApiValid] = useState(true);
  const [prevSubmittedValues, setPrevSubmittedValues] = useState({});
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [isInternationalDisabled, setIsInternationalDisabled] = useState(false);
  const [voidConfirmed, setVoidConfirmed] = useState(false);
  const [voidFormDisabled, setVoidFormDisabled] = useState(false);

  const { emailAddress, roles } = useCurrentUser();
  const { clearApiCache, useApiMutation } = useApi();

  const dateRangeRef = useRef();

  const [SaveAddress] = useApiMutation('SaveAddress', { excludedHttpCodes: [400] });

  const modeLabel = useMemo(() => {
    let theLabel = 'Sav';

    if (mode === 'create') {
      theLabel = 'Creat';
    }

    if (mode === 'edit') {
      theLabel = 'Updat';
    }

    return theLabel;
  }, []);

  const router = useRouter();
  const currentDate = dayjs(new Date()).format('MM/DD/YYYY');

  /**
   * addressDates
   *
   * List of address dates.
   *
   * @type {*[]}
   */
  const addressDates = useMemo(() => {
    let theDates = [];

    if (!isUndefined(addresses) && !isEmpty(addresses)) {
      theDates = addresses
        .filter((address) => {
          if (mode === 'edit') {
            return address?.confCommEntryId !== currentEntity?.confCommEntryId;
          }
          return address;
        })
        .map((address) => {
          return {
            ccEffectiveDate: dayjs(address?.ccEffectiveDate).format('MM/DD/YYYY'),
            ccEndDate: dayjs(address?.ccEndDate).format('MM/DD/YYYY'),
          };
        });
    }

    return theDates;
  }, [addresses, mode, currentEntity]);

  const defaultValues = {
    api: {
      administrativeNotes: '',
      businessRelationshipType: 'UHG',
      ccEffectiveDate: currentDate,
      ccEndDate: '12/31/9999',
      confComType: 'CONF_COMM',
      created: dayjs().format('YYYY-MM-DD'),
      email: '',
      impactedDocs: null,
      lastModifiedDate: dayjs().format('YYYY-MM-DD'),
      lastModifiedRequestor: emailAddress,
      lastSourceModified: 'PROTECT',
      notes: '',
      orderOfProtectionInPlace: false,
      originalRequestor: 'PROTECT',
      recordStatus: 'AVAILABLE',
    },
    ui: {
      administrativeNotes: '',
      applyFor: [],
      dateRange: {
        end: !isUndefined(currentEntity?.ccEndDate)
          ? dayjs(currentEntity?.ccEndDate).format('MM/DD/YYYY')
          : '12/31/9999',
        start: !isUndefined(currentEntity?.ccEffectiveDate)
          ? dayjs(currentEntity?.ccEffectiveDate).format('MM/DD/YYYY')
          : currentDate,
      },
      internationalAddress: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        countryCode: '',
        postalCode: '',
        stateOrRegion: '',
      },
      isInternational: false,
      notes: '',
      phoneNumber: '',
      phoneType: '',
      usAddress: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        countryCode: 'US',
        postalCode: '',
        stateOrRegion: '',
      },
    },
  };

  const form = useForm({ defaultValues });

  const dateRange = form?.getValues('ui[dateRange]');
  const formValues = form?.getValues();
  const phoneNumber = form?.getValues('ui[phoneNumber]');
  const phoneType = form?.getValues('ui[phoneType]');

  const formatPhoneNumber = (input) => {
    const cleaned = input.replace(/\D/g, '');
    // Match and format the number as (XXX) XXX-XXXX
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    return match ? `(${match[1]}) ${match[2]}-${match[3]}` : '';
  };

  /**
   * Mapping data loaded from API to the form state.
   */
  useEffect(() => {
    if (mode === 'edit' && !isUndefined(currentEntity) && !isEmpty(currentEntity)) {
      const { addresses: addressesArr } = currentEntity;

      const usAddress = addressesArr.find((address) => {
        return address.isInternational === false;
      });

      const internationalAddress = addressesArr.find((address) => {
        return address.isInternational === true;
      });

      if (internationalAddress) {
        setIsInternationalDisabled(true);
      }

      const data = merge(defaultValues, {
        api: {
          administrativeNotes: currentEntity?.administrativeNotes,
          email: currentEntity?.email,
          impactedDocs: currentEntity?.impactedDocs,
          lastModifiedDate: currentEntity?.lastModifiedDate,
          notes: currentEntity?.notes,
          orderOfProtectionInPlace: currentEntity?.orderOfProtectionInPlace,
        },
        ui: {
          applyFor: currentEntity?.businessRelationshipType?.split(','),
          city: currentEntity?.address?.city,
          countryCode: currentEntity?.address?.countryCode,
          dateRange: {
            end: dayjs(currentEntity?.ccEndDate).format('MM/DD/YYYY'),
            start: dayjs(currentEntity?.ccEffectiveDate).format('MM/DD/YYYY'),
          },
          internationalAddress: {
            addressLine1: internationalAddress?.line1,
            addressLine2: internationalAddress?.line2,
            addressLine3: '',
            city: internationalAddress?.city,
            countryCode: internationalAddress?.countryCode,
            isInternational: true,
            postalCode: internationalAddress?.postalCode,
            stateOrRegion: internationalAddress?.stateOrRegion,
          },
          isInternational: !!internationalAddress,
          phoneNumber: currentEntity?.memberPhone?.number ? formatPhoneNumber(currentEntity?.memberPhone?.number) : '',
          phoneType: currentEntity?.memberPhone?.type,
          postalCode: currentEntity?.address?.postalCode,
          stateOrRegion: currentEntity?.address?.stateOrRegion,
          usAddress: {
            addressLine1: usAddress?.line1,
            addressLine2: usAddress?.line2,
            addressLine3: '',
            city: usAddress?.city,
            countryCode: usAddress?.countryCode,
            isInternational: false,
            postalCode: usAddress?.postalCode,
            stateOrRegion: usAddress?.stateOrRegion,
          },
        },
      });

      if (dayjs(data.ui.dateRange.start).isSame(dayjs(data.ui.dateRange.end))) {
        setVoidFormDisabled(true);
      }

      if (isEmpty(initialValues)) {
        setInitialValues(data);
      }

      form?.reset(data, {
        keepDirty: false,
        keepDirtyValues: false,
        keepErrors: false,
        keepIsValid: false,
        keepSubmitCount: true,
        keepTouched: false,
        keepValues: false,
      });
    }

    if (mode === 'create') {
      setInitialValues(defaultValues);
      setVoidFormDisabled(false);
    }
  }, [mode, currentEntity]);

  /**
   * createAddressesPayload
   *
   * Creates the address payload for the API.
   *
   * @param submittedValues
   * @returns {*[]}
   */
  const createAddressesPayload = (submittedValues) => {
    const formAddresses = [];
    if (submittedValues?.ui?.isInternational) {
      formAddresses.push({
        city: submittedValues?.ui?.internationalAddress?.city,
        countryCode: submittedValues?.ui?.internationalAddress?.countryCode,
        isInternational: true,
        line1: submittedValues?.ui?.internationalAddress?.addressLine1,
        line2: submittedValues?.ui?.internationalAddress?.addressLine2,
        line3: '',
        postalCode: submittedValues?.ui?.internationalAddress?.postalCode,
        stateOrRegion: submittedValues?.ui?.internationalAddress?.stateOrRegion,
      });
    }
    formAddresses.push({
      city: submittedValues?.ui?.usAddress?.city,
      countryCode: submittedValues?.ui?.usAddress?.countryCode,
      isInternational: false,
      line1: submittedValues?.ui?.usAddress?.addressLine1,
      line2: submittedValues?.ui?.usAddress?.addressLine2,
      line3: '',
      postalCode: submittedValues?.ui?.usAddress?.postalCode,
      stateOrRegion: submittedValues?.ui?.usAddress?.stateOrRegion,
    });
    return formAddresses;
  };

  /**
   * validatePhoneNumber
   *
   * Validates the phone number.
   *
   * @param phNum
   * @returns {boolean}
   */
  const validatePhoneNumber = (phNum) => {
    // Regular expression to validate the full phone number with hyphens and area code rules
    const phoneNumberRegex = /^(\d)(?!\1{2})\d{2}\d{3}\d{4}$/;

    // Check if the phone number matches the basic format and area code validation
    if (!phoneNumberRegex.test(phNum)) {
      return false;
    }

    // Remove hyphens and check if all digits are the same
    const digitsOnly = phNum.replace(/-/g, '');
    const firstDigit = digitsOnly[0];

    // Check if all 10 digits are the same
    if (
      digitsOnly.split('').every((digit) => {
        return digit === firstDigit;
      })
    ) {
      return false;
    }

    return true;
  };

  /**
   * handleSubmit
   *
   * Sends the form data to the remote API to be saved in the database.
   *
   * @param submittedValues
   * @returns {Promise<void>}
   */
  const handleSubmit = async (submittedValues) => {
    const currentTimeISO = dayjs().toISOString();
    setPrevSubmittedValues(submittedValues);

    try {
      const payload = {
        ...{
          data: {
            ...submittedValues?.api,
            ...{
              addresses: createAddressesPayload(submittedValues),
              ccEffectiveDate: dayjs(submittedValues?.ui?.dateRange?.start).format('YYYY-MM-DD'),
              ccEndDate: dayjs(submittedValues?.ui?.dateRange?.end).format('YYYY-MM-DD'),
              limitedAccessGroup: memberData?.limitedAccessGroup,
            },
          },
        },
        ...{
          dob: memberData?.dob,
          eid: memberData?.eid,
          mode,
          newDocument: policyExists === false,
          newPolicy: policyExists === true && memberData?.isConfCommAddress === false,
          policyNumber: memberData?.policyNumber,
          version: currentVersion,
        },
      };

      if (!isEmpty(submittedValues?.ui?.applyFor)) {
        payload.data.businessRelationshipType = submittedValues?.ui?.applyFor?.join(',');
      }

      if (submittedValues?.ui?.phoneNumber && submittedValues?.ui?.phoneType) {
        payload.data.memberPhone = {
          countryCode: 'US',
          // format phone number like xxx-xxx-xxxx
          number: submittedValues?.ui?.phoneNumber.replace(/\D+/g, '').replace(/^(\d{3})(\d{3})(\d{4}).*/, '$1$2$3'),
          type: submittedValues?.ui?.phoneType,
        };
        if (!validatePhoneNumber(payload.data.memberPhone.number)) {
          form.setError('ui[phoneNumber]', {
            message: 'There is an error!',
            type: 'manual',
          });
          form.setFocus('ui[phoneNumber]');
          return;
        }
      }

      // For edit added address id
      if (mode === 'edit') {
        payload.data.confCommEntryId = currentEntity?.confCommEntryId;
        payload.data.recordStatus = voidConfirmed ? 'VOID' : 'AVAILABLE';
      }

      const toastId = 'save-address-ConfComm';

      toast.show({
        ariaLoadingLabel: `${modeLabel}ing Address`,
        autoClose: true,
        id: `${toastId}-info`,
        isPending: true,
        message: `The address is preparing to ${modeLabel.toLowerCase()}e.`,
        title: `${modeLabel}ing ConfComm Address...`,
        variant: 'info',
      });
      // Set last modified date to current date and time zone
      payload.data.lastModifiedDate = currentTimeISO;
      await SaveAddress(payload, {
        onError: (errorObject) => {
          const { error } = errorObject;
          /**
           * @TODO - We have to remove this conditions once the API provide proper error message
           * story num :Effective start and end Dates - no overlap allowed - save button #187
           */
          if (error?.response?.status?.detailedErrorMessage?.includes('OVERLAPPING ADDRESS IN POLICY')) {
            setisDateRangeApiValid(false);
            form.setFocus('ui.dateRange.start');
          }

          toast.hide(`${toastId}-info`);

          toast.show({
            id: `${toastId}-error`,
            message: `There was an error ${modeLabel}ing the address.`,
            title: `Failed to ${modeLabel}e ConfComm Address`,
            variant: 'error',
          });
        },
        onSuccess: () => {
          handleClose();

          clearApiCache(['GetAddresses', 'GetMember']);

          GetAddresses({ ...memberData, isConfCommAddress: true });
          GetMember({ eid: memberData.eid, policyNumber: memberData.policyNumber });

          toast.hide(`${toastId}-info`);

          toast.show({
            autoClose: true,
            id: `${toastId}-success`,
            message: (
              <Layout.Stack alignItems="left">
                <Text color="white">Address has been successfully {modeLabel.toLowerCase()}ed.</Text>
                <Button
                  onClick={() => {
                    router?.navigate('/search');
                    toast.hide(`${toastId}-success`);
                  }}
                  variant="outline"
                >
                  New Search
                </Button>
              </Layout.Stack>
            ),
            title: `ConfComm Address ${modeLabel}ed`,
            variant: 'success',
          });

          form?.reset(defaultValues, {
            keepDirty: false,
            keepDirtyValues: false,
            keepErrors: false,
            keepIsValid: false,
            keepSubmitCount: true,
            keepTouched: false,
            keepValues: false,
          });
        },
      });

      setIsAddressValid(true);
      setIsInternationalDisabled(false);
    } catch (error) {
      logger.error(
        'src/routes/private/MemberProfile/components/Modals/ConfComm/Modal.jsx -> handleSubmit',
        false,
        error
      );
    }
  };

  /**
   * Verify the date range does not overlap with existing addresses.
   */
  useEffect(() => {
    let datesAreVoid = false;

    const dateRangeValues = form?.getValues('ui[dateRange]');
    const startDate = dayjs(dateRangeValues?.start);
    const endDate = dayjs(dateRangeValues?.end);

    if (startDate.isSame(endDate)) {
      datesAreVoid = true;
    }

    if (datesAreVoid && mode === 'edit') {
      setDateIsVoid(true);
    } else {
      setDateIsVoid(false);
    }

    const prevStart = prevSubmittedValues?.ui?.dateRange?.start;
    const prevEnd = prevSubmittedValues?.ui?.dateRange?.end;

    const uiStartDate = dayjs(formValues?.ui?.dateRange?.start).format('YYYY-MM-DD');
    const uiEndDate = dayjs(formValues?.ui?.dateRange?.end).format('YYYY-MM-DD');

    if (
      (prevStart !== undefined && !dayjs(uiStartDate).isSame(dayjs(prevStart))) ||
      (prevEnd !== undefined && !dayjs(uiEndDate).isSame(dayjs(prevEnd)))
    ) {
      setisDateRangeApiValid(true);
    }
  }, [addressDates, addresses, form?.formState?.errors, formValues]);

  /**
   * form validation rules, toggling the save button enablement.
   */
  useEffect(() => {
    const shouldDisableForm = () => {
      if ((dateIsVoid && !voidConfirmed) || !isAddressValid || !isDateRangeApiValid) {
        return true;
      }

      if (mode === 'create') {
        return !form?.formState?.isValid;
      }

      if (!isEmpty(initialValues) && mode === 'edit') {
        return isEqual(formValues, initialValues) || !form?.formState?.isValid;
      }

      return false;
    };

    setIsFormDisabled(shouldDisableForm());
  }, [dateIsVoid, form?.formState?.isValid, formValues, initialValues, isAddressValid, isDateRangeApiValid, mode]);

  return (
    <ErrorHandler location="src/routes/private/MemberProfile/components/Modals/ConfComm/Modal.jsx">
      <Modal
        closeOnClickOutside={false}
        closeOnEscPress={false}
        isOpen={isOpen}
        onClose={() => {
          handleClose();
          form?.reset(defaultValues, {
            keepDirty: false,
            keepDirtyValues: false,
            keepErrors: false,
            keepIsValid: false,
            keepSubmitCount: true,
            keepTouched: false,
            keepValues: false,
          });
        }}
        size="1200px"
        title={`${modeLabel}e Confidential Communication Address`}
      >
        <Styles>
          <FormProvider autoComplete="off" onSubmit={handleSubmit} state={form}>
            <Modal.Section className="modal-body">
              <Details memberData={memberData} showBorder showDemographics={false} showIdentifiers showName={false} />
              <Grid className="zero">
                <Grid.Col span={{ lg: 4, xl: 4, xs: 12 }}>
                  <Text size="$sm">
                    <strong>Only apply this to:</strong>
                    <br />
                    <em>Defaults to UHG when no selection is made.</em>
                  </Text>
                  <CheckboxGroup {...omit(fields?.applyFor, 'options')}>
                    {fields?.applyFor?.options?.map((option) => {
                      return <Checkbox key={option.value} label={option.label} value={option.value} />;
                    })}
                  </CheckboxGroup>
                </Grid.Col>
                <Grid.Col span={{ lg: 4, xl: 4, xs: 12 }}>
                  <DateRange
                    {...fields?.dateRange}
                    defaultEndDate={dateRange?.end}
                    defaultStartDate={dateRange?.start}
                    isDisabled={voidFormDisabled}
                    isStartDisabled={mode === 'edit'}
                    ref={dateRangeRef}
                  />

                  {dateIsVoid && !voidConfirmed && !voidFormDisabled && (
                    <Alert actionText="Confirm" title="" variant="warning">
                      <Text fontWeight="var(--abyss-fontWeights-medium)" size="$xs">
                        Please verify, are you sure you want to void this record?
                      </Text>
                      <Flex justify="start" style={{ gap: '10px' }}>
                        <Button
                          onClick={() => {
                            setVoidConfirmed(true);
                            setIsFormDisabled(false);
                          }}
                          size="$sm"
                          variant="destructive"
                        >
                          Confirm
                        </Button>
                        <Button
                          onClick={() => {
                            form.reset({
                              dateRange: {
                                end: !isUndefined(currentEntity?.ccEndDate)
                                  ? dayjs(currentEntity?.ccEndDate).format('MM/DD/YYYY')
                                  : '12/31/9999',
                                start: !isUndefined(currentEntity?.ccEffectiveDate)
                                  ? dayjs(currentEntity?.ccEffectiveDate).format('MM/DD/YYYY')
                                  : currentDate,
                              },
                            });
                            setDateIsVoid(false);
                            if (dateRangeRef.current) {
                              dateRangeRef.current.focus();
                            }
                          }}
                          size="$sm"
                          variant="destructive"
                        >
                          Cancel
                        </Button>
                      </Flex>
                    </Alert>
                  )}

                  {!dateIsVoid && !isDateRangeApiValid ? (
                    <Alert title="" variant="error">
                      <Text fontWeight="var(--abyss-fontWeights-medium)" size="$xs">
                        Please review timeline as may overlap with existing.
                      </Text>
                    </Alert>
                  ) : (
                    <Text fontWeight="var(--abyss-fontWeights-medium)" size="$xs">
                      Date Format: mm/dd/yyyy
                    </Text>
                  )}
                </Grid.Col>
                <Grid.Col span={{ lg: 4, xl: 4, xs: 12 }}>
                  <Flex className="checkboxAlignment">
                    <Text>{fields?.orderOfProtectionInPlace?.label}</Text>
                    <Checkbox {...fields?.orderOfProtectionInPlace} isDisabled={voidFormDisabled} />
                  </Flex>
                </Grid.Col>
                <Grid.Col span="20%">
                  <SelectInput
                    {...fields?.phoneType}
                    isDisabled={voidFormDisabled}
                    validators={isRequired(phoneNumber)}
                  />
                </Grid.Col>
                <Grid.Col span={{ lg: 4, xl: 4, xs: 12 }}>
                  <TextInput
                    {...fields?.phoneNumber}
                    isDisabled={voidFormDisabled || isEmpty(phoneType)}
                    onChange={() => {
                      form.trigger('phoneType');
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={{ lg: 4, xl: 4, xs: 12 }}>
                  <TextInput {...fields?.email} isDisabled={voidFormDisabled} />
                </Grid.Col>
              </Grid>
              <Flex className="is-international-addr">
                <Text>{fields?.isInternational?.label}</Text>
                <Checkbox {...fields?.isInternational} isDisabled={voidFormDisabled || isInternationalDisabled} />
              </Flex>

              <Address
                form={form}
                isAddressValid={isAddressValid}
                mode={mode}
                setIsAddressValid={setIsAddressValid}
                voidFormDisabled={voidFormDisabled}
              />

              <Grid className="section">
                <Box className="box">
                  <Heading offset={5}>Notes:</Heading>
                </Box>
                <Grid.Col span={{ lg: 12, xl: 12, xs: 12 }}>
                  <Tabs grow={false} size="sm">
                    <Tabs.Tab label={fields?.notes?.label}>
                      <TextInputArea {...fields?.notes} isDisabled={voidFormDisabled} />
                    </Tabs.Tab>
                    <Tabs.Tab label={fields?.administrativeNotes?.label}>
                      <TextInputArea {...fields?.administrativeNotes} isDisabled={voidFormDisabled} />
                    </Tabs.Tab>
                  </Tabs>
                </Grid.Col>
              </Grid>
            </Modal.Section>
            <Modal.Section className="modal-footer">
              <Layout.Group alignLayout="right">
                <Button
                  onClick={() => {
                    handleClose();
                    form?.reset(defaultValues, {
                      keepDirty: false,
                      keepDirtyValues: false,
                      keepErrors: false,
                      keepIsValid: false,
                      keepSubmitCount: true,
                      keepTouched: false,
                      keepValues: false,
                    });
                  }}
                  type="button"
                  variant="outline"
                >
                  Close
                </Button>
                <Button
                  isDisabled={!roles.includes('State.Type.Qmcso.Write') || isFormDisabled || voidFormDisabled}
                  type="submit"
                  variant="solid"
                >
                  {modeLabel}e Address
                </Button>
              </Layout.Group>
            </Modal.Section>
          </FormProvider>
        </Styles>
      </Modal>
    </ErrorHandler>
  );
};

ConfComm.propTypes = {
  addresses: PropTypes.arrayOf(
    PropTypes.shape({
      ccEffectiveDate: PropTypes.string,
      ccEndDate: PropTypes,
      confCommEntryId: PropTypes.string,
    })
  ),
  currentEntity: PropTypes.shape({
    address: PropTypes.shape({
      city: PropTypes.string,
      countryCode: PropTypes.string,
      isInternational: PropTypes.bool,
      line1: PropTypes.string,
      line2: PropTypes.string,
      line3: PropTypes.string,
      postalCode: PropTypes.string,
      stateOrRegion: PropTypes.string,
    }),
    addresses: PropTypes.arrayOf(
      PropTypes.shape({
        city: PropTypes.string,
        countryCode: PropTypes.string,
        isInternational: PropTypes.bool,
        line1: PropTypes.string,
        line2: PropTypes.string,
        line3: PropTypes.string,
        postalCode: PropTypes.string,
        stateOrRegion: PropTypes.string,
      })
    ),
    administrativeNotes: PropTypes.string,
    businessRelationshipType: PropTypes.string,
    ccEffectiveDate: PropTypes.string,
    ccEndDate: PropTypes.string,
    confCommEntryId: PropTypes.string,
    digitalRestriction: PropTypes.bool,
    email: PropTypes.string,
    impactedDocs: PropTypes.string,
    lastModifiedDate: PropTypes.string,
    memberPhone: PropTypes.shape({
      countryCode: PropTypes.string,
      number: PropTypes.string,
      type: PropTypes.string,
    }),
    notes: PropTypes.string,
    orderOfProtectionInPlace: PropTypes.bool,
  }),
  currentVersion: PropTypes.number,
  GetAddresses: PropTypes.func,
  GetMember: PropTypes.func,
  handleClose: PropTypes.func,
  isOpen: PropTypes.bool,
  memberData: PropTypes.shape({
    dob: PropTypes.string,
    eid: PropTypes.string,
    isConfCommAddress: PropTypes.bool,
    limitedAccessGroup: PropTypes.string,
    policyNumber: PropTypes.string,
  }),
  mode: PropTypes.string,
  policyExists: PropTypes.bool,
};

ConfComm.defaultProps = {
  addresses: [],
  currentEntity: {},
  currentVersion: 0,
  GetAddresses: () => {},
  GetMember: () => {},
  handleClose: () => {},
  isOpen: false,
  memberData: {},
  mode: 'create',
  policyExists: false,
};
