import { Heading } from '@abyss/web/ui/Heading';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React from 'react';

import { Table } from './components/Table';
import { Styles } from './includes/styles';

/**
 * AdditionalAddresses
 *
 * Displays a table of additional addresses for a member.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const AdditionalAddresses = (props) => {
  const { addresses, isLoading, setCurrentEntity, setMode, setShowConfAddress, setShowQmcsoAddress } = props;
  return (
    <ErrorHandler location="src/routes/private/MemberProfile/components/AdditionalAddresses/AdditionalAddresses.jsx">
      <Styles>
        <motion.div
          animate={addresses ? 'open' : 'closed'}
          initial={{ opacity: 0 }}
          variants={{
            closed: { opacity: 0 },
            open: { opacity: 1 },
          }}
        >
          <Heading css={{ marginBottom: '$sm' }} offset={3}>
            Additional Addresses
          </Heading>
          <Table
            dataKey="additionalAddresses"
            isLoading={isLoading}
            rows={addresses}
            setCurrentEntity={setCurrentEntity}
            setMode={setMode}
            setShowConfAddress={setShowConfAddress}
            setShowQmcsoAddress={setShowQmcsoAddress}
          />
        </motion.div>
      </Styles>
    </ErrorHandler>
  );
};

AdditionalAddresses.propTypes = {
  addresses: PropTypes.arrayOf(
    PropTypes.shape({
      agentOfRecord: PropTypes.string,
      agentPhone: PropTypes.shape({
        number: PropTypes.string,
        type: PropTypes.string,
      }),
      guardianFirstName: PropTypes.string,
    })
  ),
  isLoading: PropTypes.bool,
  setCurrentEntity: PropTypes.func,
  setMode: PropTypes.func,
  setShowConfAddress: PropTypes.func,
  setShowQmcsoAddress: PropTypes.func,
};

AdditionalAddresses.defaultProps = {
  addresses: [],
  isLoading: false,
  setCurrentEntity: () => {},
  setMode: () => {},
  setShowConfAddress: () => {},
  setShowQmcsoAddress: () => {},
};
