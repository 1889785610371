import { useRouter } from '@abyss/web/hooks/useRouter';
import { config } from '@abyss/web/tools/config';
import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { IdleTimeout } from '@src/components/IdleTimeout';
import { Loader } from '@src/components/Loader';
import { useCurrentUser } from '@src/hooks/useCurrentUser';
import { DefaultLayout } from '@src/layouts/default';
import { DefaultRoutes } from '@src/routes/private';
import { isNull } from 'lodash';
import React, { useEffect } from 'react';

/**
 * Private
 *
 * This component serves as a router for the private routes.
 *
 * @returns {Element}
 * @constructor
 */
export const Private = () => {
  const { inProgress, instance } = useMsal();
  const router = useRouter();
  const location = router.getLocation();
  const { hasAccess, msalAccount } = useCurrentUser();

  /**
   * unauthenticates a user with the application and redirects them to unauthorized screen
   */
  useEffect(() => {
    (async () => {
      if (inProgress === InteractionStatus.None && hasAccess === false) {
        await instance.logoutRedirect({
          account: instance.getAccountByHomeId(msalAccount?.homeAccountId),
          postLogoutRedirectUri: `${config('APP_URL')}/unauthorized`,
        });
      }
    })();
  }, [location?.pathname, inProgress, instance, msalAccount, hasAccess]);

  if (isNull(hasAccess) || hasAccess === false) {
    return <Loader />;
  }

  return (
    <ErrorHandler location="src/components/Router/components/Private.jsx">
      <DefaultLayout>
        <DefaultRoutes />
        {config('APP_ENV') !== 'local' && (
          <IdleTimeout
            handleLogout={() => {
              (async () => {
                await instance.logoutRedirect({
                  account: instance.getAccountByHomeId(msalAccount?.homeAccountId),
                  postLogoutRedirectUri: `${config('APP_URL')}/login?message=inactive`,
                });
              })();
            }}
          />
        )}
      </DefaultLayout>
    </ErrorHandler>
  );
};
