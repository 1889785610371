import { logger } from '@src/includes/logger';
import PropTypes from 'prop-types';
import React from 'react';

import { Message } from './components/Message';

/**
 * ErrorHandler
 *
 * Used to handle the errors/exceptions at component level.
 */
export class ErrorHandler extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  /**
   * componentDidCatch
   *
   * This is what actually catches the error, which then is set in component state to later be used to display to the
   * user.
   *
   * @param error
   * @param errorInfo
   */
  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    });

    const { location } = this.props;
    logger.error(
      'components/ErrorHandler/ErrorHandler.jsx -> ErrorHandler() ->  componentDidCatch()',
      false,
      location,
      error,
      errorInfo
    );
  }

  render() {
    const { children, location } = this.props;
    const { error, errorInfo } = this.state;

    if (error || errorInfo) {
      return <Message error={error} errorInfo={errorInfo} location={location} />;
    }

    return children;
  }
}

ErrorHandler.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.string,
};

ErrorHandler.defaultProps = {
  location: '',
};
