import { GetUserRoles } from './auth/GetUserRoles';
import { GetAddresses } from './GetAddresses';
import { GetMember } from './GetMember';
import { GetWorkableItem } from './GetWorkableItem';
import { ListSearchResults } from './ListSearchResults';
import { ListWorkQueue } from './ListWorkQueue';

/**
 * queries
 *
 * @type {{GetUserRoles: ((function({}=): Promise<{}>)|*), ListWorkQueue: ((function({}=): Promise<*>)|*),
 *   ListSearchResults: ((function({}=): Promise<*>)|*), GetAddresses: ((function({}=): Promise<*[]>)|*),
 *   GetWorkableItem: ((function({}=): Promise<*>)|*), GetMember: ((function({}=): Promise<*>)|*)}}
 */
export const queries = {
  GetAddresses,
  GetMember,
  GetUserRoles,
  GetWorkableItem,
  ListSearchResults,
  ListWorkQueue,
};
