import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.abyss-data-table-root': {
    '.abyss-data-table-custom-header-section': {
      margin: 0,
    },
    '.abyss-data-table-pagination-top-root': {
      marginTop: 'auto',
    },
    '.abyss-data-table-subheader-lower-container': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '.abyss-table-row-expanded': {
      '& .abyss-table-cell-expanded': {
        display: 'table-cell',
        'table tr td': {
          '> div': {
            width: 'auto',
          },
        },
        width: '100%',
      },
      backgroundColor: 'var(--abyss-colors-white)',
      borderTop: '2px solid var(--abyss-colors-interactive1)',
      width: '100%',
    },
    '.abyss-table-styled-header': {
      '.abyss-table-header-data': {
        '.abyss-layout-group > div': {
          marginLeft: 0,
          marginRight: 'var(--abyss-space-md)',
        },
        width: '100%',
      },
      '.abyss-table-header-sort-arrow-container': {
        alignItems: 'end',
        cursor: 'pointer !important',
        height: '100%',
        justifyContent: 'center',
        margin: 0,
        position: 'absolute',
        right: 'var(--abyss-space-xs)',
        width: '100%',
      },
      '[role=separator]': {
        display: 'none',
        visibility: 'hidden',
      },
    },
    '.isLoading': {
      '.abyss-data-table-result-text > td > div': {
        width: 'auto !important',
      },
    },
    td: {
      '.abyss-loading-spinner-root': {
        width: '35px',
      },
      '.truncate': {
        display: 'inline-block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: 'inherit',
      },
      '> div': {
        width: '100%',
      },
      overflowX: 'initial',
      wordBreak: 'break-all',
    },
  },
});
