import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { Alert } from '@abyss/web/ui/Alert';
import { DataTable } from '@abyss/web/ui/DataTable';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { motion } from 'framer-motion';
import { isNull, isString, isUndefined, merge } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useLayoutEffect, useMemo } from 'react';

import tableConfiguration from './includes/configuration.json';
import { Styles } from './includes/styles';

/**
 * Table
 *
 * Reusable table component to be used/reused throughout various screens in the UI.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Table = (props) => {
  const { accessor, columns, configuration, headerLeft, noDataMessage, onSelection, rows, stickyHeaders } = props;

  /**
   * Defines the final table configuration to pass to abyss useDataTable hook.
   */
  const theConfiguration = useMemo(() => {
    const mergedConfiguration = merge(
      {},
      merge(
        {},
        {
          ...tableConfiguration,
          ...{
            renderSubComponent: null,
            uniqueStorageId: `table-${accessor}`,
          },
        },
        configuration
      ),
      {
        initialColumns: columns || [],
        initialData: rows || [],
        noDataMessage:
          isNull(noDataMessage) || isString(noDataMessage) ? (
            <Alert
              css={{
                marginLeft: 'var(--abyss-space-md)',
                marginRight: 'var(--abyss-space-md)',
              }}
              title={noDataMessage || 'No records found.'}
              variant="info"
            />
          ) : (
            noDataMessage
          ),
      }
    );

    if (!isNull(headerLeft)) {
      mergedConfiguration.customHeaderSection = headerLeft;
    }

    if (!isUndefined(configuration?.pageSizeOptions)) {
      mergedConfiguration.pageSizeOptions = configuration?.pageSizeOptions;
    }

    return mergedConfiguration;
  }, [tableConfiguration, configuration, columns, rows, noDataMessage]);

  /**
   * Table configuration.
   */
  const tableState = useDataTable(theConfiguration);

  useLayoutEffect(() => {
    if (onSelection) {
      onSelection(tableState?.state?.selectedFlatRows);
    }
  }, [tableState?.state?.selectedFlatRows]);

  /**
   * Data is passed into this component via the rows prop. Update the data table with the newly fetched data.
   */
  useEffect(() => {
    if (tableState?.rows !== rows) {
      tableState?.setColumns(columns, true);
      tableState?.setData(rows, true);
    }
  }, [tableState?.rows, rows, columns]);

  /**
   * pin table headers to the top of the table and follow as user scrolls.
   */
  useLayoutEffect(() => {
    if (stickyHeaders || configuration?.stickyHeaders) {
      const tableHead = document.querySelector('.abyss-table-head');

      if (!isNull(tableHead)) {
        tableHead.setAttribute('style', 'z-index: 300');
        const tableRoot = [...document.querySelectorAll('.abyss-data-table-root')];
        tableRoot.map((item) => {
          item.setAttribute('style', `max-height: calc(100vh - ${item.getBoundingClientRect()?.top}px)`);
          return item;
        });
      }
    }
  }, [stickyHeaders, configuration?.stickyHeaders]);

  return (
    <ErrorHandler location="src/components/Table-static/Table.jsx">
      <motion.div
        animate="open"
        initial={{ opacity: 0 }}
        variants={{
          closed: { opacity: 0 },
          open: { opacity: 1 },
        }}
      >
        <Styles>
          <DataTable hideTitleHeader key={`table-${accessor}`} tableState={tableState} title="" />
        </Styles>
      </motion.div>
    </ErrorHandler>
  );
};

Table.propTypes = {
  accessor: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.string,
      Cell: PropTypes.func,
      Filter: PropTypes.func,
      Header: PropTypes.string,
      sortable: PropTypes.bool,
      width: PropTypes.number,
    })
  ),
  configuration: PropTypes.shape({
    pageSizeDefault: PropTypes.number,
    pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
    stickyHeaders: PropTypes.bool,
  }),
  headerLeft: PropTypes.element,
  noDataMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onSelection: PropTypes.func,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  stickyHeaders: PropTypes.bool,
};

Table.defaultProps = {
  accessor: 'default',
  columns: [],
  configuration: {},
  headerLeft: null,
  noDataMessage: null,
  onSelection: null,
  rows: [],
  stickyHeaders: false,
};
