import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { ResultsCount } from '../ResultsCount';
import { CompactPagination } from './components/CompactPagination';
import { GoToPage } from './components/GoToPage';
import { ResultsPerPage } from './components/ResultsPerPage';
import { Styles } from './includes/styles';

/**
 * Header
 *
 * Displays components above the table which are used to control the data within the table.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Header = (props) => {
  const {
    currentPage,
    headerBottom,
    headerLeft,
    headerRight,
    headerTop,
    pageSizeOptions,
    pagination,
    resultsPerPage,
    setResultsPerPage,
    showCompactPagination,
    showGoToPage,
    showResultsCount,
    showResultsPerPage,
    totalResults,
  } = props;

  return (
    <ErrorHandler location="src/components/Table-query/components/Header/Header.jsx">
      <Styles>
        <Grid className="tableHeader">
          {!isNil(headerTop) && (
            <Grid.Col className="top" span={{ lg: '100%', md: '100%', sm: '100%', xs: '100%' }}>
              {headerTop}
            </Grid.Col>
          )}
          <Grid.Col className="left" span={{ lg: '50%', md: '50%', sm: '100%', xs: '100%' }}>
            {!isNil(headerLeft) && headerLeft}
          </Grid.Col>
          <Grid.Col className="right" span={{ lg: '50%', md: '50%', sm: '100%', xs: '100%' }}>
            {!isNil(headerRight) ? (
              headerRight
            ) : (
              <React.Fragment>
                <Flex
                  alignContent="flex-start"
                  alignItems="center"
                  className="topRow"
                  direction="row"
                  justify="flex-end"
                >
                  {showResultsPerPage === true && (
                    <ResultsPerPage
                      pageSizeOptions={pageSizeOptions}
                      resultsPerPage={resultsPerPage}
                      setResultsPerPage={setResultsPerPage}
                    />
                  )}
                  {showCompactPagination === true && <CompactPagination pagination={pagination} />}
                  {showGoToPage === true && (
                    <GoToPage
                      currentPage={currentPage}
                      navigate={pagination?.gotoPage}
                      totalPages={pagination?.pageCount}
                    />
                  )}
                </Flex>
                <Flex
                  alignContent="flex-start"
                  alignItems="center"
                  className="bottomRow"
                  direction="row"
                  justify="flex-end"
                >
                  {showResultsCount === true && (
                    <ResultsCount pagination={pagination} resultsPerPage={resultsPerPage} totalResults={totalResults} />
                  )}
                </Flex>
              </React.Fragment>
            )}
          </Grid.Col>
          {!isNil(headerBottom) && (
            <Grid.Col className="bottom" span={{ lg: '100%', md: '100%', sm: '100%', xs: '100%' }}>
              {headerBottom}
            </Grid.Col>
          )}
        </Grid>
      </Styles>
    </ErrorHandler>
  );
};

Header.propTypes = {
  currentPage: PropTypes.number,
  headerBottom: PropTypes.element,
  headerLeft: PropTypes.element,
  headerRight: PropTypes.element,
  headerTop: PropTypes.element,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  pagination: PropTypes.shape({
    gotoPage: PropTypes.func,
    pageCount: PropTypes.number,
  }),
  resultsPerPage: PropTypes.number,
  setResultsPerPage: PropTypes.func,
  showCompactPagination: PropTypes.bool,
  showGoToPage: PropTypes.bool,
  showResultsCount: PropTypes.bool,
  showResultsPerPage: PropTypes.bool,
  totalResults: PropTypes.number,
};

Header.defaultProps = {
  currentPage: 1,
  headerBottom: null,
  headerLeft: null,
  headerRight: null,
  headerTop: null,
  pageSizeOptions: [],
  pagination: {},
  resultsPerPage: 10,
  setResultsPerPage: () => {},
  showCompactPagination: true,
  showGoToPage: false,
  showResultsCount: true,
  showResultsPerPage: true,
  totalResults: 0,
};
