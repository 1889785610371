import { dayjs } from '@abyss/web/tools/dayjs';
import { Badge } from '@abyss/web/ui/Badge';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Table as TableComponent } from '@src/components/Table';
import { yesNo } from '@src/includes/functions';
import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import configuration from './includes/configuration.json';

/**
 * Table
 *
 * Displays a list of additional addresses for a member.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Table = (props) => {
  const { dataKey, isLoading, rows, setCurrentEntity, setMode, setShowConfAddress, setShowQmcsoAddress } = props;

  /**
   * renderCellType
   *
   * Displays the type of address. On click will open the address details in a modal with an editable form.
   *
   * @param args
   * @returns {Element}
   */
  const renderCellType = (args) => {
    const { row } = args;

    return (
      <Button
        onClick={() => {
          setMode('edit');
          setCurrentEntity(row?.original);
          if (row?.original?.confComType === 'QMCSO') {
            setShowQmcsoAddress(true);
          }

          if (row?.original?.confComType === 'CONF_COMM') {
            setShowConfAddress(true);
          }
        }}
        variant="tertiary"
      >
        {row?.original?.confComType}
      </Button>
    );
  };

  /**
   * renderCellStatus
   *
   * Displays the status of the address.
   *
   * @param args
   * @returns {React.JSX.Element|*}
   */
  const renderCellStatus = (args) => {
    const { cell } = args;

    const badges = {
      active: 'success',
      inactive: 'error',
      void: 'neutral',
    };
    if (!isUndefined(badges[cell?.value?.toLowerCase()])) {
      return (
        <Badge outline variant={badges[cell?.value?.toLowerCase()]}>
          <span style={{ textTransform: 'capitalize' }}>{cell?.value?.toLowerCase()}</span>
        </Badge>
      );
    }
    return cell?.value;
  };

  /**
   * renderCellAddress
   *
   * Displays the address of the member.
   *
   * @param args
   * @returns {Element}
   */
  const renderCellAddress = (args) => {
    const { row } = args;
    const address = row?.original?.addresses.find((addr) => {
      return !addr.isInternational;
    });
    return (
      <address>
        {address?.line1}, {address?.line2}, {address?.line3}
        <br />
        {address?.city}, {address?.stateOrRegion}
        <br />
        {address?.postalCode}
      </address>
    );
  };

  /**
   * renderCellDateRanges
   *
   * Displays the date ranges of the policy.
   *
   * @param args
   * @returns {Element}
   */
  const renderCellDateRanges = (args) => {
    const { row } = args;

    return (
      <React.Fragment>
        {dayjs(row?.original?.ccEffectiveDate).format('MM/DD/YYYY')} to{' '}
        {dayjs(row?.original?.ccEndDate).format('MM/DD/YYYY')}
      </React.Fragment>
    );
  };

  /**
   * renderCellOrderProtection
   *
   * Displays if an order of protection is in place.
   *
   * @param args
   * @returns {string}
   */
  const renderCellOrderProtection = (args) => {
    const { row } = args;

    return yesNo(row?.original?.orderOfProtectionInPlace);
  };

  /**
   * Columns for table.
   */
  const columns = useMemo(() => {
    return configuration?.initialColumns.map((item) => {
      const column = item;

      if (column.Header === 'Type') {
        column.Cell = renderCellType;
      }

      if (column.Header === 'Status') {
        column.Cell = renderCellStatus;
      }

      if (column.Header === 'Date Ranges') {
        column.Cell = renderCellDateRanges;
      }

      if (column.Header === 'Order of Protection') {
        column.Cell = renderCellOrderProtection;
      }

      if (column.Header === 'Address') {
        column.Cell = renderCellAddress;
      }

      return column;
    });
  }, []);

  return (
    <ErrorHandler location="src/routes/private/MemberProfile/components/AdditionalAddresses/components/Table/Table.jsx">
      <TableComponent
        {...{
          columns,
          configuration: {
            ...configuration,
            ...{
              data: rows,
            },
          },
          dataKey,
          isLoading,
          rows,
        }}
      />
    </ErrorHandler>
  );
};

Table.propTypes = {
  dataKey: PropTypes.string,
  isLoading: PropTypes.bool,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      agentOfRecord: PropTypes.string,
      agentPhone: PropTypes.shape({
        number: PropTypes.string,
        type: PropTypes.string,
      }),
      guardianFirstName: PropTypes.string,
    })
  ),
  setCurrentEntity: PropTypes.func,
  setMode: PropTypes.func,
  setShowConfAddress: PropTypes.func,
  setShowQmcsoAddress: PropTypes.func,
};

Table.defaultProps = {
  dataKey: '',
  isLoading: false,
  rows: [],
  setCurrentEntity: () => {},
  setMode: () => {},
  setShowConfAddress: () => {},
  setShowQmcsoAddress: () => {},
};
