import { useRouter } from '@abyss/web/hooks/useRouter';
import { useToast } from '@abyss/web/hooks/useToast';
import { config } from '@abyss/web/tools/config';
import { Box } from '@abyss/web/ui/Box';
import { Card } from '@abyss/web/ui/Card';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { useMsal } from '@azure/msal-react';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { loginRequest } from '@src/includes/configuration/authentication';
import { motion } from 'framer-motion';
import Head from 'next/head';
import Image from 'next/image';
import React, { useLayoutEffect } from 'react';

import { Styles } from './includes/styles';

/**
 * Login
 *
 * Provides a screen for the user to authenticate with the application.
 *
 * @returns {Element}
 * @constructor
 */
export const Login = () => {
  const { instance } = useMsal();
  const router = useRouter();
  const location = router?.getLocation();
  const queryString = location?.search;
  const queryParams = Object.fromEntries(new URLSearchParams(queryString));

  const { toast } = useToast();

  /**
   * Displays a toast message when the session has expired.
   */
  useLayoutEffect(() => {
    if (queryParams?.message === 'inactive') {
      toast.show({
        autoClose: false,
        id: 'session-expired',
        message: (
          <Text color="inherit">
            You have been logged out due to inactivity. <br />
            Please login again to continue.
          </Text>
        ),
        title: 'Session Expired',
        variant: 'info',
      });
    }
  }, [queryParams?.message]);

  return (
    <ErrorHandler location="src/routes/public/Login/Login.jsx">
      <Head>
        <title>
          Login | {config('APP_ENV') !== 'prod' ? `${config('APP_NAME')} | ${config('APP_ENV')}` : config('APP_NAME')}
        </title>
      </Head>
      <motion.div
        animate="open"
        initial={{ opacity: 0 }}
        variants={{
          closed: { opacity: 0 },
          open: { opacity: 1 },
        }}
      >
        <Styles>
          <Flex alignContent="center" alignItems="center" direction="column" justify="center">
            <Box color="" height="auto" width={500}>
              <Image
                alt="UHG logo"
                className="logo"
                height="0"
                priority="true"
                sizes="100vw"
                src="/logo.svg"
                width="0"
              />
              <Card>
                <Layout.Stack alignItems="center" alignLayout="center" grow space={12}>
                  <Heading offset={3}>{config('APP_NAME')}</Heading>
                  <Button
                    onClick={async () => {
                      toast.hide('session-expired');
                      await instance.loginRedirect(loginRequest);
                    }}
                    size="lg"
                    variant="solid"
                  >
                    <Layout.Group>
                      Login using MS ID <IconSymbol icon="login" />
                    </Layout.Group>
                  </Button>
                </Layout.Stack>
              </Card>
            </Box>
          </Flex>
        </Styles>
      </motion.div>
    </ErrorHandler>
  );
};
