import { dayjs } from '@abyss/web/tools/dayjs';
import { logger } from '@src/includes/logger';
import { Country, State } from 'country-state-city';
import { isEmpty, isUndefined } from 'lodash';

import States from './states.json';

/**
 * abbrNum
 *
 * Abbreviates a number.
 *
 * @param number
 * @param decPlaces
 * @returns {*}
 */
export function abbrNum(number = 0, decPlaces = 0) {
  if (String(number).length > 6 && decPlaces === 0) {
    return abbrNum(number, 1);
  }

  let theNumber = number;

  // 2 decimal places => 100, 3 => 1000, etc
  const decimalPlaces = 10 ** decPlaces;

  // Enumerate theNumber abbreviations
  const abbrev = ['K', 'M', 'B', 'T'];

  // Go through the array backwards, so we do the largest first
  for (let i = abbrev.length - 1; i >= 0; i--) {
    // Convert array index to "1000", "1000000", etc
    const size = 10 ** ((i + 1) * 3);

    // If the theNumber is bigger or equal do the abbreviation
    if (size <= theNumber) {
      // Here, we multiply by decimalPlaces, round, and then divide by decimalPlaces.
      // This gives us nice rounding to a particular decimal place.
      theNumber = Math.round((theNumber * decimalPlaces) / size) / decimalPlaces;

      // Handle special case where we round up to the next abbreviation
      if (theNumber === 1000 && i < abbrev.length - 1) {
        theNumber = 1;
        // eslint-disable-next-line no-plusplus
        i++;
      }

      // Add the letter for the abbreviation
      theNumber += abbrev[i];

      // We are done... stop
      break;
    }
  }

  return theNumber;
}

/**
 * parallelFetch
 *
 * Fetches provided requests in parallel.
 *
 * @param requests
 * @returns {Promise<{}>}
 */
export async function parallelFetch(requests = {}) {
  const response = {};

  try {
    if (isEmpty(requests)) {
      return response;
    }

    const promises = Object.keys(requests).map(async (key) => {
      const request = requests[key];

      let result;

      if (!isUndefined(request?.callback)) {
        if (!isUndefined(request?.args)) {
          result = await request?.callback(request?.args);
        } else {
          result = await request?.callback();
        }

        response[key] = result;
      }
    });

    await Promise.all(promises);
  } catch (error) {
    logger.error('includes/functions.js -> parallelFetch()', false, error);
  }

  return response;
}

/**
 * yesNo
 *
 * Takes a boolean (true/false) input and outputs as yes/no.
 *
 * @param value
 * @returns {string}
 */
export function yesNo(value) {
  if (value === true) {
    return 'Yes';
  }
  if (value === false) {
    return 'No';
  }
  return '';
}

/**
 * ListStates
 *
 * Returns a list of states from the country: United States.
 *
 * @returns {{}}
 * @constructor
 */
export const ListStates = () => {
  return States;
};

/**
 * ListTerritoryProvinces
 *
 * Returns a list of territories/provinces from the provided country code.
 *
 * @param countryCode
 * @returns {{code: *, name: *}[]}
 * @constructor
 */
export const ListTerritoryProvinces = (countryCode) => {
  if (countryCode === 'US') {
    return ListStates();
  }

  return State.getStatesOfCountry(countryCode).map((state) => {
    return {
      code: state.isoCode,
      name: state.name,
    };
  });
};

/**
 * ListCountries
 *
 * Returns a list of known countries around the world.
 *
 * @returns {{code: *, name: *}[]}
 * @constructor
 */
export const ListCountries = () => {
  return Country.getAllCountries().map((country) => {
    return {
      code: country.isoCode,
      name: country.name,
    };
  });
};

/**
 * IsEndDatePresent
 *
 * Check if the end date is present in the list of additional addresses.
 *
 * @param additionalAddresses
 * @param type
 * @returns {*|boolean}
 * @constructor
 */
export const IsEndDatePresent = (additionalAddresses, type) => {
  const endDate = '12/31/9999';

  const filteredAddress = additionalAddresses.filter((val) => {
    return val.confComType === type;
  });

  if (filteredAddress.length) {
    const addressesArr = filteredAddress.map((val) => {
      return dayjs(val.ccEndDate).format('MM/DD/YYYY');
    });

    return addressesArr.includes(endDate);
  }

  return false;
};

/**
 * dateRangeHasOverlap
 *
 * Check if the selected date range is between the dates in the list of existing address dates.
 *
 * @param dateRange
 * @param addressDates
 * @returns {*}
 */
export const dateRangeHasOverlap = (dateRange = {}, addressDates = []) => {
  const { end: endDate, start: startDate } = dateRange;

  const hasOverlap = addressDates?.find((addressDate) => {
    const { ccEffectiveDate, ccEndDate } = addressDate;

    const startDateIsBetween = dayjs(startDate).isBetween(dayjs(ccEffectiveDate), dayjs(ccEndDate), 'day', '[)');
    const endDateIsBetween = dayjs(endDate).isBetween(dayjs(ccEffectiveDate), dayjs(ccEndDate), 'day', '[)');

    return startDate === ccEffectiveDate || endDate === ccEndDate || startDateIsBetween || endDateIsBetween;
  });

  if (dayjs(endDate).isBefore(dayjs(startDate), 'day')) {
    return true;
  }

  return Boolean(hasOverlap);
};

/**
 * sortAddresses
 *
 * Sort the addresses based on the effective date and end date.
 *
 * @param addresses
 * @returns {*[]}
 */
export const sortAddresses = (addresses) => {
  const sortAddressesList = [];
  const currentDate = dayjs().format('MM/DD/YYYY');

  addresses?.forEach((address) => {
    const theAddress = { ...address };
    const startDate = dayjs(address?.ccEffectiveDate).format('MM/DD/YYYY');
    const endDate = dayjs(address?.ccEndDate).format('MM/DD/YYYY');
    if (
      dayjs(startDate).isSameOrBefore(dayjs(currentDate)) &&
      dayjs(endDate).isSameOrAfter(dayjs(currentDate)) &&
      !dayjs(endDate).isSame(startDate)
    ) {
      theAddress.status = 'active';
    } else if (dayjs(startDate).isSame(dayjs(endDate))) {
      theAddress.status = 'void';
    } else {
      theAddress.status = 'inactive';
    }

    sortAddressesList.push(theAddress);
  });

  // Get Pre Effective list
  const preEffectiveList = sortAddressesList
    .filter((val) => {
      const startDate = dayjs(val?.ccEffectiveDate).format('MM/DD/YYYY');
      return dayjs(startDate).isAfter(dayjs(currentDate));
    })
    ?.sort((a, b) => {
      return dayjs(a.ccEffectiveDate).isAfter(dayjs(b.ccEffectiveDate)) ? -1 : 1;
    });

  // Get Active list
  const activeList = sortAddressesList
    .filter((val) => {
      const startDate = dayjs(val?.ccEffectiveDate).format('MM/DD/YYYY');
      const endDate = dayjs(val?.ccEndDate).format('MM/DD/YYYY');
      return dayjs(startDate).isSameOrBefore(dayjs(currentDate)) && dayjs(endDate).isSameOrAfter(dayjs(currentDate));
    })
    ?.sort((a, b) => {
      return dayjs(a.ccEffectiveDate).isAfter(dayjs(b.ccEffectiveDate)) ? -1 : 1;
    });

  // Get InActive list
  const inActiveList = sortAddressesList
    .filter((val) => {
      const endDate = dayjs(val?.ccEndDate).format('MM/DD/YYYY');
      return dayjs(endDate).isBefore(dayjs(currentDate));
    })
    ?.sort((a, b) => {
      return dayjs(a.ccEffectiveDate).isAfter(dayjs(b.ccEffectiveDate)) ? -1 : 1;
    });

  // Get Void list
  const voidList = sortAddressesList
    .filter((val) => {
      const effectiveDate = dayjs(val?.ccEffectiveDate).format('MM/DD/YYYY');
      const endDate = dayjs(val?.ccEndDate).format('MM/DD/YYYY');
      return dayjs(endDate).isSame(dayjs(effectiveDate));
    })
    ?.sort((a, b) => {
      return dayjs(a.ccEffectiveDate).isAfter(dayjs(b.ccEffectiveDate)) ? -1 : 1;
    });
  const combinedList = [...preEffectiveList, ...activeList, ...inActiveList, ...voidList];
  const uniqueAddresses = combinedList.reduce((acc, current) => {
    const x = acc.find((item) => {
      return item.confCommEntryId === current.confCommEntryId;
    });
    if (!x) {
      acc.push(current);
    }
    return acc;
  }, []);
  return uniqueAddresses;
};
