/* eslint-disable react/prop-types */
import { config } from '@abyss/web/tools/config';
import { FloatingSection } from '@abyss/web/ui/FloatingSection';
import { NavMenuPrimitives } from '@abyss/web/ui/NavMenu';
import { PageHeaderPrimitives } from '@abyss/web/ui/PageHeader';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useRoutesContext } from '@src/context/Routes';
import { useCurrentUser } from '@src/hooks/useCurrentUser';
import { motion } from 'framer-motion';
import { isEmpty, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { NavIconTitle } from './components/NavIconTitle';
import { LogoutStyles, Styles, SubNavStyles } from './includes/styles';

/**
 * Header
 *
 * Renders the logo and navigation which "sticks" as the user scrolls vertically.
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const Header = (props) => {
  const { stickyRef } = props;

  const { displayName } = useCurrentUser();
  const { currentRoute, currentRoutes } = useRoutesContext();

  /**
   * Determines the currently active navigation menu item, based on the current route.
   */
  useEffect(() => {
    if (!isEmpty(currentRoute)) {
      const navigationItems = document.querySelectorAll(
        '.app-layout-header .abyss-nav-menu-menu, .app-layout-header .abyss-nav-menu-link-root '
      );

      if (!isEmpty(navigationItems)) {
        navigationItems.forEach((navigationItem) => {
          navigationItem?.classList?.remove('active');
          const parentItem = currentRoutes.find((route) => {
            return route?.navigationLabel === navigationItem.innerText;
          });
          if (
            parentItem?.inUrl === true ||
            currentRoute?.path === navigationItem?.pathname ||
            (navigationItem?.pathname !== '/' && currentRoute?.path?.includes(navigationItem?.pathname)) ||
            navigationItem?.innerText === currentRoute?.navigationLabel ||
            navigationItem?.innerText === currentRoute?.parent?.navigationLabel
          ) {
            navigationItem?.classList.add('active');
          }
        });
      }
    }
  }, [currentRoute?.path, currentRoutes]);

  /**
   * RenderUserMenu
   *
   * Displays the user menu.
   *
   * @returns {Element}
   * @constructor
   */
  const RenderUserMenu = () => {
    return (
      <NavMenuPrimitives.Item>
        <NavMenuPrimitives.Trigger variant="inverted">
          <NavIconTitle iconName="account_box" title={displayName} />
        </NavMenuPrimitives.Trigger>
        <NavMenuPrimitives.Content>
          <LogoutStyles>
            <NavMenuPrimitives.Columns>
              <NavMenuPrimitives.Column>
                <NavMenuPrimitives.MenuItem href="/logout" title="Logout" />
              </NavMenuPrimitives.Column>
            </NavMenuPrimitives.Columns>
          </LogoutStyles>
        </NavMenuPrimitives.Content>
      </NavMenuPrimitives.Item>
    );
  };

  /**
   * RenderSubMenuItems
   *
   * Displays the sub-menu items.
   *
   * @param items
   * @returns {Element}
   * @constructor
   */
  const RenderSubMenuItems = ({ items = [] }) => {
    const theItems = orderBy(items, ['menuOrder'], ['asc']);

    return (
      <React.Fragment>
        {!isEmpty(theItems) && (
          <SubNavStyles>
            <NavMenuPrimitives.Columns>
              <NavMenuPrimitives.Column>
                {theItems?.map((subItem) => {
                  if (subItem?.showInNavigation === true) {
                    return (
                      <NavMenuPrimitives.MenuItem
                        className={
                          currentRoute?.path === subItem?.path || currentRoute?.path?.includes(subItem?.path)
                            ? 'active'
                            : ''
                        }
                        href={subItem?.path}
                        key={`${subItem?.accessor}-childItem`}
                        title={subItem?.navigationLabel}
                      />
                    );
                  }
                  return <React.Fragment key={`${subItem?.accessor}-childItem`} />;
                })}
              </NavMenuPrimitives.Column>
            </NavMenuPrimitives.Columns>
          </SubNavStyles>
        )}
      </React.Fragment>
    );
  };

  /**
   * RenderNavigationItems
   *
   * Renders the navigation items.
   *
   * @param items
   * @returns {Element}
   * @constructor
   */
  const RenderNavigationMenu = ({ items = [] }) => {
    const theItems = orderBy(items, ['menuOrder'], ['asc']);

    return (
      <React.Fragment>
        {!isEmpty(theItems) &&
          theItems?.map((item) => {
            const theChildren = item?.children?.filter((child) => {
              return child?.showInNavigation === true;
            });
            if (item?.path !== '/' && item?.showInNavigation === true) {
              if (!isEmpty(theChildren) && !item?.hideSubNav) {
                return (
                  <NavMenuPrimitives.Item key={`${item?.accessor}-parentItem`}>
                    <NavMenuPrimitives.Trigger variant="inverted">
                      <NavIconTitle iconName={item?.navigationIcon} title={item?.navigationLabel} />
                    </NavMenuPrimitives.Trigger>
                    <NavMenuPrimitives.Content>
                      <RenderSubMenuItems items={theChildren} />
                    </NavMenuPrimitives.Content>
                  </NavMenuPrimitives.Item>
                );
              }
              return (
                <NavMenuPrimitives.Link href={item?.path} key={`${item?.accessor}-parentLink`} variant="inverted">
                  <NavIconTitle iconName={item?.navigationIcon} title={item?.navigationLabel} />
                </NavMenuPrimitives.Link>
              );
            }
            return <React.Fragment key={`${item?.accessor}-parentItem`} />;
          })}
      </React.Fragment>
    );
  };

  return (
    <ErrorHandler location="src/layouts/default/Header/Header.jsx">
      <FloatingSection
        alwaysFloat={false}
        containerRef={stickyRef}
        css={{
          'abyss-floating-section-root': {
            '.abyss-drawer-menu-container': {
              backgroundColor: 'var(--abyss-colors-gray1)',
            },
            '&[class*="disableFloat-true"]': {
              '.abyss-page-header-container': {
                '.abyss-page-header-vertical-rule': {
                  height: '30px',
                },
                height: '88px !important',
              },
              top: '40px',
            },
            boxShadow: 'none',
            top: '0px',
            zIndex: '999',
          },
        }}
        position="top"
        space={40}
      >
        <Styles>
          <div id="app-header">
            <motion.div
              animate="open"
              initial={{ opacity: 0 }}
              variants={{
                closed: { opacity: 0 },
                open: { opacity: 1 },
              }}
            >
              <PageHeaderPrimitives.Container className="app-layout-header" fullWidth>
                <PageHeaderPrimitives.Brandmark logoRedirect="/" logoTitle={config('APP_NAME')} />
                <div>
                  <NavMenuPrimitives.Provider positionViewportToTrigger>
                    <NavMenuPrimitives.Root variant="inverted">
                      <NavMenuPrimitives.List>
                        <RenderNavigationMenu items={currentRoutes} />
                        <RenderUserMenu />
                      </NavMenuPrimitives.List>
                      <NavMenuPrimitives.Viewport />
                    </NavMenuPrimitives.Root>
                  </NavMenuPrimitives.Provider>
                </div>
              </PageHeaderPrimitives.Container>
            </motion.div>
          </div>
        </Styles>
      </FloatingSection>
    </ErrorHandler>
  );
};

Header.propTypes = {
  stickyRef: PropTypes.shape({ current: PropTypes.shape({}) }),
};

Header.defaultProps = {
  stickyRef: { current: null },
};
