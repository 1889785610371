import { isEmpty, isObject, isString, isUndefined } from 'lodash';

/**
 * handleStatus
 *
 * Determines the overall status of a component's visibility.
 *
 * @param options
 * @returns {null|string}
 */
export const handleStatus = (options = {}) => {
  const { environmentStatus, status, storeStatus, userRoleStatus } = options;

  switch (status) {
    case 'disabled':
      // Check for enabled: store item, environment, user role
      if ([environmentStatus, storeStatus, userRoleStatus].includes('enabled')) {
        return 'enabled';
      }

      // Check for disabled: store item, environment, user role
      if (
        ['disabled', null].includes(storeStatus) ||
        ['disabled', null].includes(environmentStatus) ||
        ['disabled', null].includes(userRoleStatus)
      ) {
        return 'disabled';
      }

      return 'disabled';
    case 'enabled':
      // Check for disabled: store item, environment, user role
      if ([environmentStatus, storeStatus, userRoleStatus].includes('disabled')) {
        return 'disabled';
      }

      return 'enabled';
    default:
      // Check for enabled: store item, environment, user role
      if ([environmentStatus, storeStatus, userRoleStatus].includes('enabled')) {
        return 'enabled';
      }

      // Check for disabled: store item, environment, user role
      if ([environmentStatus, storeStatus, userRoleStatus].includes('disabled')) {
        return 'disabled';
      }

      return null;
  }
};

/**
 * updateComponent
 *
 * Updates the visibility of a component.
 *
 * @param state
 * @param payload
 * @returns {{}}
 */
export const updateComponent = (state = {}, payload = {}) => {
  const theState = { ...state };

  const { accessor, options } = payload;

  if (isUndefined(accessor) || !isString(accessor) || !isObject(options)) {
    return state;
  }

  if (isEmpty(accessor) || isEmpty(options)) {
    return state;
  }

  const toUpdate = { ...theState?.visibility?.components };

  const defaultOptions = {
    environmentStatus: null,
    status: null,
    storeStatus: null,
    userRoleStatus: null,
  };

  if (isUndefined(toUpdate?.[accessor])) {
    toUpdate[accessor] = { ...defaultOptions, ...options };
  } else {
    toUpdate[accessor] = {
      ...defaultOptions,
      ...theState?.visibility?.components?.[accessor],
      ...options,
    };
  }

  toUpdate[accessor] = {
    ...toUpdate[accessor],
    ...{
      status: handleStatus(toUpdate[accessor]),
    },
  };

  theState.visibility.components = { ...theState.visibility.components, ...toUpdate };

  return theState;
};
