import { Box } from '@abyss/web/ui/Box';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { TextInput } from '@abyss/web/ui/TextInput';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { ListTerritoryProvinces } from '@src/includes/functions';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import fields from '../../../../includes/fields.json';

/**
 * Tab: UsAddress
 *
 * Displays information about a member's policy, relevant to the United States postal address.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const UsAddress = (props) => {
  const { form, voidFormDisabled } = props;

  const city = form?.getValues('ui[usAddress][city]');
  const countryCode = form?.getValues('ui[usAddress][countryCode]');
  const stateOrRegion = form?.getValues('ui[usAddress][stateOrRegion]');

  /**
   * states
   *
   * Retrieves a list of states.
   *
   * @type {{code: *, name: *}[]}
   */
  const states = useMemo(() => {
    return ListTerritoryProvinces(countryCode);
  }, [countryCode]);

  return (
    <ErrorHandler location="src/routes/private/MemberProfile/components/Modals/CondfComm/components/Address/components/UsAddress/UsAddress.jsx">
      <Grid className="section first">
        <Box className="box">
          <Heading offset={5}>Address Line 1:</Heading>
        </Box>
        <Grid.Col span={{ lg: 4, xl: 4, xs: 12 }}>
          <TextInput
            {...fields?.usAddress?.addressLine1}
            formatter={(value) => {
              return value.replace(/[-`&/\\#,+()$~%.'":*?<>{}@!^_=;\][|]/g, '');
            }}
            isDisabled={voidFormDisabled}
          />
        </Grid.Col>
      </Grid>
      <Grid className="section">
        <Box className="box">
          <Heading offset={5}>Address Line 2:</Heading>
        </Box>
        <Grid.Col span={{ lg: 4, xl: 4, xs: 12 }}>
          <TextInput
            {...fields?.usAddress?.addressLine2}
            formatter={(value) => {
              return value.replace(/[-`&/\\#,+()$~%.'":*?<>{}@!^_=;\][|]/g, '');
            }}
            isDisabled={voidFormDisabled}
          />
        </Grid.Col>
      </Grid>
      <Grid className="section">
        <Box className="box">
          <Heading offset={5}>Address Line 3:</Heading>
        </Box>
        <Grid.Col span={{ lg: 3, xl: 3, xs: 12 }}>
          <SelectInput
            {...fields?.usAddress?.stateOrRegion}
            isDisabled={voidFormDisabled}
            options={states.map((state) => {
              return {
                label: state?.name,
                value: state?.code,
              };
            })}
          />
        </Grid.Col>
        <Grid.Col span={{ lg: 3, xl: 3, xs: 12 }}>
          <TextInput {...fields?.usAddress?.city} isDisabled={voidFormDisabled || isEmpty(stateOrRegion)} />
        </Grid.Col>
        <Grid.Col span={{ lg: 3, xl: 3, xs: 12 }}>
          <TextInput
            {...fields?.usAddress?.postalCode}
            formatter={(value) => {
              return value.replace(/[-`&/\\#,+()$~%.'":*?<>{}@!^_=;\][|]/g, '');
            }}
            isDisabled={voidFormDisabled || isEmpty(stateOrRegion) || isEmpty(city)}
          />
        </Grid.Col>
      </Grid>
    </ErrorHandler>
  );
};

UsAddress.propTypes = {
  form: PropTypes.shape({
    getValues: PropTypes.func,
  }),
  voidFormDisabled: PropTypes.bool,
};

UsAddress.defaultProps = {
  form: {
    getValues: () => {},
  },
  voidFormDisabled: false,
};
