import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { ResultsCount } from '../ResultsCount';
import { NumberedPagination } from './components/NumberedPagination';
import { Styles } from './includes/styles';

/**
 * Footer
 *
 * Displays components below the table which are used to control the data within the table.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Footer = (props) => {
  const {
    footerBottom,
    footerLeft,
    footerRight,
    footerTop,
    pagination,
    resultsPerPage,
    showNumberedPagination,
    showResultsCount,
    totalResults,
  } = props;

  return (
    <ErrorHandler location="src/components/Table-query/components/Footer/Footer.jsx">
      <Styles>
        <Grid className="tableFooter">
          {!isNil(footerTop) && (
            <Grid.Col className="top" span={{ lg: '100%', md: '100%', sm: '100%', xs: '100%' }}>
              {footerTop}
            </Grid.Col>
          )}
          <Grid.Col className="left" span={{ lg: '50%', md: '50%', sm: '100%', xs: '100%' }}>
            {!isNil(footerLeft) ? (
              footerLeft
            ) : (
              <React.Fragment>
                {showResultsCount === true && (
                  <ResultsCount pagination={pagination} resultsPerPage={resultsPerPage} totalResults={totalResults} />
                )}
              </React.Fragment>
            )}
          </Grid.Col>
          <Grid.Col className="right" span={{ lg: '50%', md: '50%', sm: '100%', xs: '100%' }}>
            {!isNil(footerRight) ? (
              footerRight
            ) : (
              <React.Fragment>
                {showNumberedPagination === true && (
                  <Flex alignContent="flex-start" alignItems="flex-start" direction="row" justify="flex-end">
                    <NumberedPagination pagination={pagination} />
                  </Flex>
                )}
              </React.Fragment>
            )}
          </Grid.Col>
          {!isNil(footerBottom) && (
            <Grid.Col className="bottom" span={{ lg: '100%', md: '100%', sm: '100%', xs: '100%' }}>
              {footerBottom}
            </Grid.Col>
          )}
        </Grid>
      </Styles>
    </ErrorHandler>
  );
};

Footer.propTypes = {
  footerBottom: PropTypes.element,
  footerLeft: PropTypes.element,
  footerRight: PropTypes.element,
  footerTop: PropTypes.element,
  pagination: NumberedPagination.propTypes.pagination,
  resultsPerPage: ResultsCount.propTypes.resultsPerPage,
  showNumberedPagination: PropTypes.bool,
  showResultsCount: PropTypes.bool,
  totalResults: ResultsCount.propTypes.totalResults,
};

Footer.defaultProps = {
  footerBottom: null,
  footerLeft: null,
  footerRight: null,
  footerTop: null,
  pagination: NumberedPagination.defaultProps.pagination,
  resultsPerPage: ResultsCount.defaultProps.resultsPerPage,
  showNumberedPagination: true,
  showResultsCount: true,
  totalResults: ResultsCount.defaultProps.totalResults,
};
