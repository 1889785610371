import { dayjs } from '@abyss/web/tools/dayjs';
import { Grid } from '@abyss/web/ui/Grid';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import statuses from '@src/routes/private/WorkQueue/includes/statuses.json';
import { isNull } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { DataItem } from './components/DataItem';

/**
 * Details
 *
 * Displays the details of the workable item.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Details = (props) => {
  const { workableItem } = props;

  const items = useMemo(() => {
    const todayDate = dayjs();
    const createdDate = dayjs(workableItem?.createdDate);
    const daysInQueue = todayDate.diff(createdDate, 'day');

    return [
      {
        icon: <IconSymbol color="white" icon="event" size={48} variant="outlined" />,
        label: 'Date Created',
        value: dayjs(workableItem?.createdDate).format('MM/DD/YYYY'),
        variant: 'blue',
      },
      {
        icon: <IconSymbol color="white" icon="schedule" size={48} variant="outlined" />,
        label: 'Days in Queue',
        value: daysInQueue,
        variant: 'yellow',
      },
      {
        icon: <IconSymbol color="white" icon="event_available" size={48} variant="outlined" />,
        label: 'Date Completed',
        value: !isNull(workableItem?.completeDate) ? dayjs(workableItem?.completeDate).format('MM/DD/YYYY') : 'N/A',
        variant: 'green',
      },
      {
        icon: <IconSymbol color="white" icon="help" size={48} variant="outlined" />,
        label: 'Status',
        value: statuses?.[String(workableItem?.status).toUpperCase()],
        variant: 'purple',
      },
    ];
  }, [workableItem]);

  return (
    <Grid>
      {items.map((item) => {
        return (
          <Grid.Col
            span={{
              xs: '25%',
            }}
          >
            <DataItem key={item?.label.replace(' ', '')} {...item} />
          </Grid.Col>
        );
      })}
    </Grid>
  );
};

Details.propTypes = {
  workableItem: PropTypes.shape({
    completeDate: PropTypes.string,
    createdDate: PropTypes.string,
    status: PropTypes.string,
  }),
};

Details.defaultProps = {
  workableItem: {},
};
