import { TextInput } from '@abyss/web/ui/TextInput';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Visibility } from '@src/components/Visibility';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Styles } from './includes/styles';

/**
 * GoToPage
 *
 * Allows the user to navigate directly to a specific page number of results within a data table.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const GoToPage = (props) => {
  const { currentPage, navigate } = props;

  const [requestedPage, setRequestedPage] = useState(currentPage + 1);

  /**
   * handlePageChange
   *
   * Uses the abyss pagination functionality to toggle the current page.
   *
   * @returns {Promise<void>}
   */
  const handlePageChange = async () => {
    if (currentPage + 1 !== requestedPage) {
      await navigate(requestedPage - 1);
    }
  };

  return (
    <ErrorHandler location="src/components/Table/components/GoToPage/GoToPage.jsx">
      <Visibility>
        <Styles>
          <TextInput
            hideLabel
            inputRightElement={
              <Button onClick={handlePageChange} type="submit" variant="solid">
                Go
              </Button>
            }
            label=""
            leftAddOn="Page"
            onChange={(event) => {
              setRequestedPage(event?.target?.value);
            }}
            onKeyDown={async (event) => {
              if (event?.keyCode === 13) {
                setRequestedPage(event?.target?.value);
                await handlePageChange();
              }
            }}
            value={requestedPage}
            width="11em"
          />
        </Styles>
      </Visibility>
    </ErrorHandler>
  );
};

GoToPage.propTypes = {
  currentPage: PropTypes.number,
  navigate: PropTypes.func,
};

GoToPage.defaultProps = {
  currentPage: 0,
  navigate: () => {},
};
