import { config } from '@abyss/web/tools/config';
import { Box } from '@abyss/web/ui/Box';
import { Card } from '@abyss/web/ui/Card';
import { Divider } from '@abyss/web/ui/Divider';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { Text } from '@abyss/web/ui/Text';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { motion } from 'framer-motion';
import Head from 'next/head';
import Image from 'next/image';
import React from 'react';

import { Styles } from './includes/styles';

/**
 * Unauthorized
 *
 * Provides a screen for the user to view when they are not authorized to view the application.
 *
 * @returns {Element}
 * @constructor
 */
export const Unauthorized = () => {
  return (
    <ErrorHandler location="src/routes/public/Unauthorized/Unauthorized.jsx">
      <Head>
        <title>
          Unauthorized |{' '}
          {config('APP_ENV') !== 'prod' ? `${config('APP_NAME')} | ${config('APP_ENV')}` : config('APP_NAME')}
        </title>
      </Head>
      <motion.div
        animate="open"
        initial={{ opacity: 0 }}
        variants={{
          closed: { opacity: 0 },
          open: { opacity: 1 },
        }}
      >
        <Styles>
          <Flex alignContent="center" alignItems="center" direction="column" justify="center">
            <Box color="" height="auto" width={500}>
              <Image
                alt="UHG logo"
                className="logo"
                height="0"
                priority="true"
                sizes="100vw"
                src="/logo.svg"
                width="0"
              />
              <Card>
                <Layout.Stack alignItems="center" alignLayout="center" grow space={12}>
                  <Heading offset={3}>{config('APP_NAME')}</Heading>
                  <Divider />
                  <Heading offset={0}>UNAUTHORIZED</Heading>
                  <Text>Not allowed to view the requested resource.</Text>
                </Layout.Stack>
                <Link href="/login">
                  <IconSymbol icon="arrow_back" /> Back to Login
                </Link>
              </Card>
            </Box>
          </Flex>
        </Styles>
      </motion.div>
    </ErrorHandler>
  );
};
