import { Widget } from '@src/components/Widget';
import PropTypes from 'prop-types';
import React from 'react';

import { Table } from './components/Table';

/**
 * ChangeEvents
 *
 * Displays the history of the workable item.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const ChangeEvents = (props) => {
  const { workableItem } = props;

  return (
    <Widget description="" title="Change Events">
      <Table rows={workableItem?.changeEvents} />
    </Widget>
  );
};

ChangeEvents.propTypes = {
  workableItem: PropTypes.shape({
    changeEvents: PropTypes.arrayOf(
      PropTypes.shape({
        field: PropTypes.string,
        lastModified: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  }),
};

ChangeEvents.defaultProps = {
  workableItem: {
    changeEvents: [],
  },
};
