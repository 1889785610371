import { Box } from '@abyss/web/ui/Box';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { Text } from '@abyss/web/ui/Text';
import { TextInput } from '@abyss/web/ui/TextInput';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { ListTerritoryProvinces } from '@src/includes/functions';
import { isRequired } from '@src/routes/private/MemberProfile/components/Modals/includes/functions';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import fields from '../../../../includes/fields.json';

/**
 * Tab: UsAddress
 *
 * Displays information about a member's policy, relevant to the United States postal address.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const UsAddress = (props) => {
  const { form, isAddressValid, targetRef, voidFormDisabled } = props;

  const agencyCaseId = form?.getValues('ui[usAddress][agencyCaseId]');
  const agentOfRecord = form?.getValues('ui[usAddress][agentOfRecord]');
  const agentPhoneNumber = form?.getValues('ui[usAddress][agentPhoneNumber]');
  const agentPhoneType = form?.getValues('ui[usAddress][agentPhoneType]');
  const city = form?.getValues('ui[usAddress][city]');
  const countryCode = form?.getValues('ui[usAddress][countryCode]');
  const guardianFirstName = form?.getValues('ui[usAddress][guardianFirstName]');
  const guardianLastName = form?.getValues('ui[usAddress][guardianLastName]');
  const guardianPhoneNumber = form?.getValues('ui[usAddress][guardianPhoneNumber]');
  const guardianPhoneType = form?.getValues('ui[usAddress][guardianPhoneType]');
  const isInternational = form?.getValues('ui[isInternational]');
  const stateOrRegion = form?.getValues('ui[usAddress][stateOrRegion]');

  /**
   * states
   *
   * Retrieves a list of states.
   *
   * @type {{code: *, name: *}[]}
   */
  const states = useMemo(() => {
    return ListTerritoryProvinces(countryCode);
  }, [countryCode]);

  return (
    <ErrorHandler location="src/routes/private/MemberProfile/components/Modals/Qmcso/components/Address/components/UsAddress/UsAddress.jsx">
      {!isInternational && (
        <Grid className="section first">
          <Box className="box">
            <Heading offset={5}>
              Address Line 1: <span className="required-field" />
            </Heading>
          </Box>
          <span ref={targetRef} />
          <Grid.Col span={{ xs: 12 }}>
            <Text color={isAddressValid ? '' : '$error1'} size="$sm">
              <em>Please enter either Agent of Record or Guardian information.</em>
            </Text>
          </Grid.Col>
          <Grid.Col span={{ lg: 7, xl: 7, xs: 12 }}>
            <Grid>
              <Grid.Col span={{ lg: 6, xl: 6, xs: 12 }}>
                <TextInput
                  {...fields?.usAddress?.agentOfRecord}
                  isDisabled={voidFormDisabled}
                  validators={isRequired(agencyCaseId)}
                />
              </Grid.Col>
              <Grid.Col span={{ lg: 6, xl: 6, xs: 12 }}>
                <TextInput
                  {...fields?.usAddress?.agencyCaseId}
                  formatter={(value) => {
                    return value.replace(/[-`&/\\#,+()$~%.'":*?<>{}@!^_=;\][|]/g, '');
                  }}
                  isDisabled={voidFormDisabled}
                  validators={isRequired(agentOfRecord)}
                />
              </Grid.Col>
            </Grid>
          </Grid.Col>
          <Grid.Col span={{ lg: 5, xl: 5, xs: 12 }}>
            <Grid>
              <Grid.Col span="45%">
                <SelectInput
                  {...fields?.usAddress?.agentPhoneType}
                  isDisabled={voidFormDisabled}
                  validators={isRequired(agentPhoneNumber)}
                />
              </Grid.Col>
              <Grid.Col span="55%">
                <TextInput
                  {...fields?.usAddress?.agentPhoneNumber}
                  isDisabled={voidFormDisabled || isEmpty(agentPhoneType)}
                />
              </Grid.Col>
            </Grid>
          </Grid.Col>
          <Grid.Col span={{ lg: 7, xl: 7, xs: 12 }}>
            <Grid>
              <Grid.Col span={{ lg: 6, xl: 6, xs: 12 }}>
                <TextInput
                  {...fields?.usAddress?.guardianFirstName}
                  isDisabled={voidFormDisabled}
                  validators={isRequired(guardianLastName)}
                />
              </Grid.Col>
              <Grid.Col span={{ lg: 6, xl: 6, xs: 12 }}>
                <TextInput
                  {...fields?.usAddress?.guardianLastName}
                  isDisabled={voidFormDisabled}
                  validators={isRequired(guardianFirstName)}
                />
              </Grid.Col>
            </Grid>
          </Grid.Col>
          <Grid.Col span={{ lg: 5, xl: 5, xs: 12 }}>
            <Grid>
              <Grid.Col span="45%">
                <SelectInput
                  {...fields?.usAddress?.guardianPhoneType}
                  isDisabled={voidFormDisabled}
                  validators={isRequired(guardianPhoneNumber)}
                />
              </Grid.Col>
              <Grid.Col span="55%">
                <TextInput
                  {...fields?.usAddress?.guardianPhoneNumber}
                  isDisabled={voidFormDisabled || isEmpty(guardianPhoneType)}
                />
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
      )}

      <Grid className={`section ${isInternational ? 'first' : ''}`}>
        <Box className="box">
          <Heading offset={5}>Address Line {isInternational ? 1 : 2}:</Heading>
        </Box>
        <Grid.Col span={{ lg: 4, xl: 4, xs: 12 }}>
          <TextInput
            {...fields?.usAddress?.addressLine1}
            formatter={(value) => {
              return value.replace(/[-`&/\\#,+()$~%.'":*?<>{}@!^_=;\][|]/g, '');
            }}
            isDisabled={voidFormDisabled}
          />
        </Grid.Col>
      </Grid>
      <Grid className="section">
        <Box className="box">
          <Heading offset={5}>Address Line {isInternational ? 2 : 3}:</Heading>
        </Box>
        <Grid.Col span={{ lg: 4, xl: 4, xs: 12 }}>
          <TextInput
            {...fields?.usAddress?.addressLine2}
            formatter={(value) => {
              return value.replace(/[-`&/\\#,+()$~%.'":*?<>{}@!^_=;\][|]/g, '');
            }}
            isDisabled={voidFormDisabled}
          />
        </Grid.Col>
      </Grid>
      <Grid className="section">
        <Box className="box">
          <Heading offset={5}>Address Line {isInternational ? 3 : 4}:</Heading>
        </Box>
        <Grid.Col span={{ lg: 3, xl: 3, xs: 12 }}>
          <SelectInput
            {...fields?.usAddress?.stateOrRegion}
            isDisabled={voidFormDisabled}
            options={states.map((state) => {
              return {
                label: state?.name,
                value: state?.code,
              };
            })}
          />
        </Grid.Col>
        <Grid.Col span={{ lg: 3, xl: 3, xs: 12 }}>
          <TextInput {...fields?.usAddress?.city} isDisabled={voidFormDisabled || isEmpty(stateOrRegion)} />
        </Grid.Col>
        <Grid.Col span={{ lg: 3, xl: 3, xs: 12 }}>
          <TextInput
            {...fields?.usAddress?.postalCode}
            formatter={(value) => {
              return value.replace(/[-`&/\\#,+()$~%.'":*?<>{}@!^_=;\][|]/g, '');
            }}
            isDisabled={voidFormDisabled || isEmpty(stateOrRegion) || isEmpty(city)}
          />
        </Grid.Col>
      </Grid>
    </ErrorHandler>
  );
};

UsAddress.propTypes = {
  form: PropTypes.shape({
    getValues: PropTypes.func,
    setValue: PropTypes.func,
  }),
  isAddressValid: PropTypes.bool,
  targetRef: PropTypes.shape({
    current: PropTypes.shape({
      scrollIntoView: PropTypes.func,
    }),
  }),
  TerritoriesProvinces: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  voidFormDisabled: PropTypes.bool,
};

UsAddress.defaultProps = {
  form: {},
  isAddressValid: false,
  targetRef: {},
  TerritoriesProvinces: [],
  voidFormDisabled: false,
};
