import { config } from '@abyss/web/tools/config';
import { Axios } from '@src/context/Api/includes/Axios/Axios';
import { logger } from '@src/includes/logger';

/**
 * SaveWorkableItem
 *
 * Makes a request to the remote API to save workable item in the database.
 *
 * @param payload
 * @returns {Promise<{}>}
 * @constructor
 */
export const SaveWorkableItem = async (payload = {}) => {
  try {
    const theMutationKey = payload?.[0];
    const thePayload = payload?.[1];

    const requestArgs = {
      baseURL: config('API_URL'),
      data: {
        notes: thePayload?.notes,
        status: thePayload?.status,
      },
      headers: {
        'x-api-endpoint': `/workitem/${thePayload?.workableItemId}`,
      },
      method: 'PUT',
      params: { mutationKey: theMutationKey },
    };

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse?.data;
  } catch (error) {
    logger.error('src/requests/mutations/SaveWorkableItem.js -> SaveWorkableItem()', false, error);

    throw error;
  }
};
