/**
 * isEqual
 *
 * Compares two objects for equality.
 *
 * @param object1
 * @param object2
 * @returns {boolean}
 */
export function isEqual(object1, object2) {
  return JSON.stringify(object1) === JSON.stringify(object2);
}

/**
 * isRequired
 *
 * Validates the value, making it required field if true.
 *
 * @param value
 * @returns {{required: boolean}}
 */
export function isRequired(value) {
  if (value) {
    return { required: true };
  }
  return { required: false };
}
