import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.tableHeader': {
    '.bottom': {
      padding: 0,
      position: 'relative',
    },
    '.left': {
      '.abyss-toggle-switch-root': {
        height: '100%',
      },
      padding: 0,
      position: 'relative',
    },
    '.right': {
      '.bottomRow': {
        marginTop: 'var(--abyss-space-sm)',
      },
      padding: 0,
      position: 'relative',
    },
    '.top': {
      marginBottom: 'var(--abyss-space-sm)',
      padding: 0,
      position: 'relative',
    },
    margin: 0,
    marginBottom: 'var(--abyss-space-sm)',
    marginTop: 'var(--abyss-space-sm)',
    position: 'relative',
  },
});
