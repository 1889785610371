import { Box } from '@abyss/web/ui/Box';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { Text } from '@abyss/web/ui/Text';
import { TextInput } from '@abyss/web/ui/TextInput';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { ListCountries, ListTerritoryProvinces } from '@src/includes/functions';
import { isRequired } from '@src/routes/private/MemberProfile/components/Modals/includes/functions';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import fields from '../../../../includes/fields.json';

/**
 * Tab: InternationalAddress
 *
 * Displays international address information about a member's policy.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const InternationalAddress = (props) => {
  const { form, isAddressValid, targetRef, voidFormDisabled } = props;
  const agencyCaseId = form?.getValues('ui[internationalAddress][agencyCaseId]');
  const agentOfRecord = form?.getValues('ui[internationalAddress][agentOfRecord]');
  const agentPhoneNumber = form?.getValues('ui[internationalAddress][agentPhoneNumber]');
  const agentPhoneType = form?.getValues('ui[internationalAddress][agentPhoneType]');
  const city = form?.getValues('ui[internationalAddress][city]');
  const countryCode = form?.getValues('ui[internationalAddress][countryCode]');
  const guardianFirstName = form?.getValues('ui[internationalAddress][guardianFirstName]');
  const guardianLastName = form?.getValues('ui[internationalAddress][guardianLastName]');
  const guardianPhoneNumber = form?.getValues('ui[internationalAddress][guardianPhoneNumber]');
  const guardianPhoneType = form?.getValues('ui[internationalAddress][guardianPhoneType]');
  const isInternational = form?.getValues('ui[isInternational]');
  const stateOrRegion = form?.getValues('ui[internationalAddress][stateOrRegion]');
  /**
   * ListCountries
   *
   * Retrieves a list of countries.
   *
   * @type {{code: *, name: *}[]}
   */
  const Countries = useMemo(() => {
    return ListCountries().filter((country) => {
      return !(isInternational && country?.code === 'US');
    });
  }, [isInternational]);

  /**
   * TerritoriesProvinces
   *
   * Retrieves a list of states.
   *
   * @type {{code: *, name: *}[]}
   */
  const TerritoriesProvinces = useMemo(() => {
    return ListTerritoryProvinces(countryCode);
  }, [countryCode]);

  return (
    <ErrorHandler location="src/routes/private/MemberProfile/components/Modals/Qmcso/components/Address/components/InternationalAddress/InternationalAddress.jsx">
      <Grid className="section first">
        <Box className="box">
          <Heading offset={5}>
            Address Line 1: <span className="required-field" />
          </Heading>
        </Box>
        <span ref={targetRef} />
        <Grid.Col span={{ xs: 12 }}>
          <Text color={isAddressValid ? '' : '$error1'} size="$sm">
            <em>Please enter either Agent of Record or Guardian information.</em>
          </Text>
        </Grid.Col>
        <Grid.Col span={{ lg: 7, xl: 7, xs: 12 }}>
          <Grid>
            <Grid.Col span={{ lg: 6, xl: 6, xs: 12 }}>
              <TextInput
                {...fields?.internationalAddress?.agentOfRecord}
                isDisabled={voidFormDisabled}
                validators={isRequired(agencyCaseId)}
              />
            </Grid.Col>
            <Grid.Col span={{ lg: 6, xl: 6, xs: 12 }}>
              <TextInput
                {...fields?.internationalAddress?.agencyCaseId}
                formatter={(value) => {
                  return value.replace(/[-`&/\\#,+()$~%.'":*?<>{}@!^_=;\][|]/g, '');
                }}
                isDisabled={voidFormDisabled}
                validators={isRequired(agentOfRecord)}
              />
            </Grid.Col>
          </Grid>
        </Grid.Col>
        <Grid.Col span={{ lg: 5, xl: 5, xs: 12 }}>
          <Grid>
            <Grid.Col span="45%">
              <SelectInput
                {...fields?.internationalAddress?.agentPhoneType}
                isDisabled={voidFormDisabled}
                validators={isRequired(agentPhoneNumber)}
              />
            </Grid.Col>
            <Grid.Col span="55%">
              <TextInput
                {...fields?.internationalAddress?.agentPhoneNumber}
                isDisabled={voidFormDisabled || isEmpty(agentPhoneType)}
              />
            </Grid.Col>
          </Grid>
        </Grid.Col>
        <Grid.Col span={{ lg: 7, xl: 7, xs: 12 }}>
          <Grid>
            <Grid.Col span={{ lg: 6, xl: 6, xs: 12 }}>
              <TextInput
                {...fields?.internationalAddress?.guardianFirstName}
                isDisabled={voidFormDisabled}
                validators={isRequired(guardianLastName)}
              />
            </Grid.Col>
            <Grid.Col span={{ lg: 6, xl: 6, xs: 12 }}>
              <TextInput
                {...fields?.internationalAddress?.guardianLastName}
                isDisabled={voidFormDisabled}
                validators={isRequired(guardianFirstName)}
              />
            </Grid.Col>
          </Grid>
        </Grid.Col>
        <Grid.Col span={{ lg: 5, xl: 5, xs: 12 }}>
          <Grid>
            <Grid.Col span="45%">
              <SelectInput
                {...fields?.internationalAddress?.guardianPhoneType}
                isDisabled={voidFormDisabled}
                validators={isRequired(guardianPhoneNumber)}
              />
            </Grid.Col>
            <Grid.Col span="55%">
              <TextInput
                {...fields?.internationalAddress?.guardianPhoneNumber}
                isDisabled={voidFormDisabled || isEmpty(guardianPhoneType)}
              />
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>
      <Grid className="section">
        <Box className="box">
          <Heading offset={5}>Address Line 2:</Heading>
        </Box>
        <Grid.Col span={{ lg: 4, xl: 4, xs: 12 }}>
          <TextInput
            {...fields?.internationalAddress?.addressLine1}
            formatter={(value) => {
              return value.replace(/[-`&/\\#,+()$~%.'":*?<>{}@!^_=;\][|]/g, '');
            }}
            isDisabled={voidFormDisabled}
          />
        </Grid.Col>
      </Grid>
      <Grid className="section">
        <Box className="box">
          <Heading offset={5}>Address Line 3:</Heading>
        </Box>
        <Grid.Col span={{ lg: 4, xl: 4, xs: 12 }}>
          <TextInput
            {...fields?.internationalAddress?.addressLine2}
            formatter={(value) => {
              return value.replace(/[-`&/\\#,+()$~%.'":*?<>{}@!^_=;\][|]/g, '');
            }}
            isDisabled={voidFormDisabled}
          />
        </Grid.Col>
      </Grid>
      <Grid className="section">
        <Box className="box">
          <Heading offset={5}>Address Line 4:</Heading>
        </Box>
        <Grid.Col span={{ lg: 3, xl: 3, xs: 12 }}>
          <SelectInput
            {...fields?.internationalAddress?.countryCode}
            onChange={() => {}}
            onClear={() => {}}
            options={Countries.map((country) => {
              return {
                label: country?.name,
                value: country?.code,
              };
            })}
          />
        </Grid.Col>
        <Grid.Col span={{ lg: 3, xl: 3, xs: 12 }}>
          <SelectInput
            {...fields?.internationalAddress?.stateOrRegion}
            isDisabled={voidFormDisabled || isEmpty(countryCode)}
            options={TerritoriesProvinces.map((territoryProvince) => {
              return {
                label: territoryProvince?.name,
                value: territoryProvince?.code,
              };
            })}
          />
        </Grid.Col>
        <Grid.Col span={{ lg: 3, xl: 3, xs: 12 }}>
          <TextInput
            {...fields?.internationalAddress?.city}
            isDisabled={voidFormDisabled || isEmpty(countryCode) || isEmpty(stateOrRegion)}
          />
        </Grid.Col>
        <Grid.Col span={{ lg: 3, xl: 3, xs: 12 }}>
          <TextInput
            {...fields?.internationalAddress?.postalCode}
            formatter={(value) => {
              return value.replace(/[-`&/\\#,+()$~%.'":*?<>{}@!^_=;\][|]/g, '');
            }}
            isDisabled={voidFormDisabled || isEmpty(countryCode) || isEmpty(stateOrRegion) || isEmpty(city)}
          />
        </Grid.Col>
      </Grid>
    </ErrorHandler>
  );
};

InternationalAddress.propTypes = {
  form: PropTypes.shape({
    getValues: PropTypes.func,
    setValue: PropTypes.func,
  }),
  isAddressValid: PropTypes.bool,
  targetRef: PropTypes.shape({
    current: PropTypes.shape({
      scrollIntoView: PropTypes.func,
    }),
  }),
  TerritoriesProvinces: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  voidFormDisabled: PropTypes.bool,
};

InternationalAddress.defaultProps = {
  form: {},
  isAddressValid: false,
  targetRef: {},
  TerritoriesProvinces: [],
  voidFormDisabled: false,
};
