import { config } from '@abyss/web/tools/config';
import { Axios } from '@src/context/Api/includes/Axios/Axios';
import { logger } from '@src/includes/logger';
import { isEmpty, isUndefined } from 'lodash';

/**
 * GetMember
 *
 * Retrieves member information from the remote API via an authenticated request.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const GetMember = async (payload = {}) => {
  try {
    const theQueryKey = payload?.[0];
    const thePayload = payload?.[1];

    const requestArgs = {
      baseURL: config('API_URL'),
      data: { enterpriseId: thePayload?.eid, policyNumber: thePayload?.policyNumber },
      headers: {
        'x-api-endpoint': `/search`,
      },
      method: 'POST',
      params: { queryKey: theQueryKey },
    };

    const remoteResponse = await Axios.request(requestArgs);

    let response = {};

    if (!isEmpty(remoteResponse?.data?.data?.searchResponseList)) {
      const matchedPolicy = remoteResponse?.data?.data?.searchResponseList.find((memberData) => {
        return memberData?.policyNumber === thePayload?.policyNumber;
      });

      const unmatchedPolices = remoteResponse?.data?.data?.searchResponseList.filter((memberData) => {
        return memberData?.policyNumber !== thePayload?.policyNumber;
      });

      if (!isUndefined(matchedPolicy)) {
        response = { ...matchedPolicy, additionalPolicies: unmatchedPolices };
      }
    }
    return response;
  } catch (error) {
    logger.error('src/requests/queries/GetMember.js -> GetMember()', false, error);

    throw error;
  }
};
