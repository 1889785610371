import { Grid } from '@abyss/web/ui/Grid';
import { Layout } from '@abyss/web/ui/Layout';
import { Skeleton as AbyssSkeleton } from '@abyss/web/ui/Skeleton';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { motion } from 'framer-motion';
import React from 'react';

/**
 * Skeleton
 *
 * Displays a roughed-in idea/placeholder of what the UI should look like while the data is fetching from the API.
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const Skeleton = () => {
  return (
    <ErrorHandler location="src/routes/private/MemberProfile/components/Skeleton/Skeleton.jsx">
      <motion.div
        animate="open"
        data-testid="skeleton"
        initial={{ opacity: 0 }}
        variants={{
          closed: { opacity: 0 },
          open: { opacity: 1 },
        }}
      >
        <Grid>
          <Grid.Col
            css={{ paddingTop: themeConfiguration?.theme?.space?.lg }}
            span={{
              xs: '100%',
            }}
          >
            <AbyssSkeleton height="319px" width="100%" />
          </Grid.Col>

          <Grid.Col
            css={{ paddingTop: themeConfiguration?.theme?.space?.md }}
            span={{
              xs: '100%',
            }}
          >
            <Layout.Group>
              <AbyssSkeleton height="40px" width="200px" />
              <AbyssSkeleton height="40px" width="200px" />
            </Layout.Group>
          </Grid.Col>

          <Grid.Col
            css={{ paddingTop: themeConfiguration?.theme?.space?.lg }}
            span={{
              xs: '100%',
            }}
          >
            <AbyssSkeleton height="32px" width="408px" />
          </Grid.Col>
          <Grid.Col
            css={{ paddingTop: themeConfiguration?.theme?.space?.xs }}
            span={{
              xs: '100%',
            }}
          >
            <AbyssSkeleton height="370px" width="100%" />
          </Grid.Col>
        </Grid>
      </motion.div>
    </ErrorHandler>
  );
};
