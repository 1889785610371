/* eslint-disable import/no-extraneous-dependencies */
import { useRouter } from '@abyss/web/hooks/useRouter';
import { config } from '@abyss/web/tools/config';
import { useRoutesContext } from '@src/context/Routes';
import { useCurrentUser } from '@src/hooks/useCurrentUser';
import { MemberProfile } from '@src/routes/private/MemberProfile';
import { Search } from '@src/routes/private/Search';
import { ListWorkQueue, ViewWorkableItem } from '@src/routes/private/WorkQueue';
import { isEmpty, isNull, merge } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { Outlet, useRoutes } from 'react-router-dom';

/**
 * Routes
 *
 * Configures the routes for the application within an authenticated state.
 *
 * @returns {*}
 * @constructor
 */
export const Routes = () => {
  const { roles: userRoles } = useCurrentUser();
  const { currentRoute, currentRoutes, setCurrentRoute, setCurrentRoutes } = useRoutesContext();

  const router = useRouter();
  const location = router?.getLocation();
  const path = location?.pathname;
  const routeParams = router?.getRouteParams();
  const queryString = location?.search;
  const queryParams = Object.fromEntries(new URLSearchParams(queryString));

  /**
   * routerConfiguration
   *
   * Control visibility like this:
   *
   *         visibility: {
   *           disabledEnvironments: ['Stage', 'Production'],
   *           enabledEnvironments: ['Local', 'Development'],
   *         },
   *
   */
  const routerConfiguration = useMemo(() => {
    return [
      {
        element: <Outlet />,
        navigationLabel: config('APP_NAME'),
        path: '/',
        screenTitle: config('APP_NAME'),
        showInBreadcrumbs: true,
        showInNavigation: false,
        slug: 'root',
      },
      {
        children: [
          {
            children: [],
            element: <ListWorkQueue />,
            navigationLabel: 'Work Queue',
            path: '/work-queue',
            screenTitle: 'Work Queue',
            showInBreadcrumbs: false,
            showInNavigation: false,
            slug: 'work-queue',
          },
          {
            children: [
              {
                children: [],
                element: <ViewWorkableItem />,
                exact: true,
                navigationLabel: 'Workable Item',
                path: '/work-queue/item/:workableItemId',
                screenTitle: 'Workable Item',
                showInBreadcrumbs: true,
                showInNavigation: false,
                slug: 'workable-item',
              },
            ],
            element: <ViewWorkableItem />,
            navigationLabel: 'Workable Item',
            path: '/work-queue/item',
            screenTitle: 'Workable Item',
            showInBreadcrumbs: false,
            showInNavigation: false,
            slug: 'workable-item',
          },
          {
            children: [
              {
                children: [],
                element: <ListWorkQueue />,
                navigationLabel: 'Work Queue',
                path: '/work-queue/:eid/:policyNumber',
                screenTitle: 'Work Queue',
                showInBreadcrumbs: false,
                showInNavigation: false,
                slug: 'work-queue',
              },
            ],
            element: <ListWorkQueue />,
            navigationLabel: 'Work Queue',
            path: '/work-queue/:eid',
            screenTitle: 'Work Queue',
            showInBreadcrumbs: false,
            showInNavigation: false,
            slug: 'work-queue',
          },
        ],
        element: <Outlet />,
        navigationIcon: 'patient_list',
        navigationLabel: 'Work Queue',
        path: '/work-queue',
        screenTitle: 'Work Queue',
        showInBreadcrumbs: true,
        showInNavigation: true,
        slug: 'work-queue',
      },
      {
        children: [],
        element: <Search />,
        navigationIcon: 'search',
        navigationLabel: 'Search',
        path: '/search',
        screenTitle: 'Search',
        showInBreadcrumbs: true,
        showInNavigation: true,
        slug: 'search',
      },
      {
        children: [],
        element: <Search />,
        navigationLabel: 'Search',
        path: '/search/:key',
        screenTitle: 'Search',
        showInBreadcrumbs: true,
        showInNavigation: false,
        slug: 'search',
      },
      {
        children: [
          {
            children: [],
            element: <MemberProfile />,
            navigationLabel: 'Member Profile',
            path: '/profile/:eid',
            screenTitle: 'Member Profile',
            showInBreadcrumbs: false,
            showInNavigation: false,
            slug: 'view-member-profile',
          },
          {
            children: [],
            element: <MemberProfile />,
            navigationLabel: 'Member Profile',
            path: '/profile/:eid/:policyNumber',
            screenTitle: 'Member Profile',
            showInBreadcrumbs: false,
            showInNavigation: false,
            slug: 'view-member-profile',
          },
        ],
        element: <MemberProfile />,
        navigationLabel: 'Member Profile',
        path: '/profile',
        screenTitle: 'Member Profile',
        showInBreadcrumbs: true,
        showInNavigation: false,
        slug: 'view-member-profile',
      },
    ];
  }, [userRoles]);

  /**
   * Handle Redirects
   */
  useEffect(() => {
    (() => {
      if (path === '/') {
        return router.navigate('/search');
      }
      return false;
    })();
  }, [path]);

  /**
   * getTertiaryRoutes
   *
   * @param routes
   * @param secondaryRoute
   * @param secondaryIndex
   * @param primaryIndex
   * @returns {{theRoute: {}, theRoutes: *[]}}
   */
  const getTertiaryRoutes = (routes = [], secondaryRoute = {}, secondaryIndex = 0, primaryIndex = 0) => {
    const theRoutes = routes;
    let theRoute = {};

    secondaryRoute?.children.forEach((route, index) => {
      const match = router.matchPath(
        {
          exact: false,
          path: route.path,
          strict: false,
        },
        path
      );

      if (!isNull(match)) {
        theRoutes[primaryIndex].current = false;
        theRoutes[primaryIndex].inUrl = false;
        theRoutes[primaryIndex].children[secondaryIndex].current = true;
        theRoutes[primaryIndex].children[secondaryIndex].inUrl = true;
        theRoutes[primaryIndex].children[secondaryIndex].children[index].current = true;
        theRoutes[primaryIndex].children[secondaryIndex].children[index].inUrl = true;

        if (isEmpty(theRoute)) {
          theRoute = {
            ...route,
            ...match,
            ...{
              parent: secondaryRoute,
              queryParams,
              queryString,
              routeParams,
            },
          };
        }
      }
    });

    return { theRoute, theRoutes };
  };

  /**
   * getSecondaryRoutes
   *
   * @param routes
   * @param primaryRoute
   * @param primaryIndex
   * @returns {{theRoute: {}, theRoutes: *[]}}
   */
  const getSecondaryRoutes = (routes = [], primaryRoute = {}, primaryIndex = 0) => {
    let theRoutes = routes;
    let theRoute = {};

    primaryRoute?.children.forEach((route, index) => {
      const match = router.matchPath(
        {
          exact: true,
          path: route.path,
          strict: false,
        },
        path
      );

      if (!isNull(match)) {
        theRoutes[primaryIndex].current = false;
        theRoutes[primaryIndex].inUrl = false;
        theRoutes[primaryIndex].children[index].current = true;
        theRoutes[primaryIndex].children[index].inUrl = true;

        if (isEmpty(theRoute)) {
          theRoute = {
            ...route,
            ...match,
            ...{
              parent: primaryRoute,
              queryParams,
              queryString,
              routeParams,
            },
          };
        }
      } else if (!isEmpty(route?.children)) {
        const tertiaryRoutes = getTertiaryRoutes(theRoutes, route, index, primaryIndex);
        theRoutes = merge([], theRoutes, tertiaryRoutes.theRoutes);
        theRoute = merge({}, theRoute, tertiaryRoutes.theRoute);
      }
    });

    return { theRoute, theRoutes };
  };

  /**
   * getPrimaryRoutes
   *
   * @param routes
   * @returns {{theRoute: {}, theRoutes: *[]}}
   */
  const getPrimaryRoutes = (routes = []) => {
    let theRoutes = routes;
    let theRoute = {};

    routes.forEach((route, index) => {
      const match = router.matchPath(
        {
          exact: true,
          path: route.path,
          strict: false,
        },
        path
      );

      if (!isNull(match)) {
        theRoutes[index].current = false;
        theRoutes[index].inUrl = false;

        if (isEmpty(theRoute)) {
          theRoute = {
            ...route,
            ...match,
            ...{
              parent: null,
              queryParams,
              queryString,
              routeParams,
            },
          };
        }
      } else if (!isEmpty(route?.children)) {
        const secondaryRoutes = getSecondaryRoutes(theRoutes, route, index);
        theRoutes = merge([], theRoutes, secondaryRoutes.theRoutes);
        theRoute = merge({}, theRoute, secondaryRoutes.theRoute);
      }
    });

    return { theRoute, theRoutes };
  };

  /**
   * getRoutes
   *
   * @returns {{theRoute: {}, theRoutes: *[]}}
   */
  const getRoutes = () => {
    return getPrimaryRoutes(routerConfiguration);
  };

  /**
   * Determines the current route(s) based on the URL.
   */
  useEffect(() => {
    const { theRoute, theRoutes } = getRoutes();

    if (theRoute !== currentRoute) {
      setCurrentRoute(theRoute);
    }

    if (theRoutes !== currentRoutes) {
      setCurrentRoutes(theRoutes);
    }
  }, [path, routerConfiguration]);

  return useRoutes(routerConfiguration);
};
