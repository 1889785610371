import { PageBody } from '@abyss/web/ui/PageBody';
import { Breadcrumbs } from '@src/components/Breadcrumbs';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Main
 *
 * This is where the main content of the page will be rendered.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const Main = (props) => {
  const { children } = props;

  return (
    <ErrorHandler location="src/layouts/default/Main.jsx">
      <motion.div
        animate="open"
        initial={{ opacity: 0 }}
        variants={{
          closed: { opacity: 0 },
          open: { opacity: 1 },
        }}
      >
        <main id="app-main">
          <PageBody
            css={{
              paddingBottom: 'var(--abyss-space-md)',
              paddingTop: 'var(--abyss-space-md)',
            }}
            fullWidth
          >
            <section id="app-breadcrumbs">
              <Breadcrumbs />
            </section>
            <motion.div
              animate="open"
              initial={{ opacity: 0 }}
              variants={{
                closed: { opacity: 0 },
                open: { opacity: 1 },
              }}
            >
              {children}
            </motion.div>
          </PageBody>
        </main>
      </motion.div>
    </ErrorHandler>
  );
};

Main.propTypes = {
  children: PropTypes.node.isRequired,
};
