import { config } from '@abyss/web/tools/config';
import { Badge } from '@abyss/web/ui/Badge';
import { Box } from '@abyss/web/ui/Box';
import { Label } from '@abyss/web/ui/Label';
import { Layout } from '@abyss/web/ui/Layout';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Visibility } from '@src/components/Visibility';
import { useCurrentEnvironment } from '@src/hooks/useCurrentEnvironment';
import { useCurrentVersion } from '@src/hooks/useCurrentVersion';
import React from 'react';

/**
 * CurrentEnvironment
 *
 * Displays the current environment name along with the current GitHub version number.
 *
 * @returns {Element}
 * @constructor
 */
export const CurrentEnvironment = () => {
  const currentEnvironment = useCurrentEnvironment();
  const currentVersion = useCurrentVersion();

  return (
    <ErrorHandler location="src/components/CurrentEnvironment/CurrentEnvironment.jsx">
      <Visibility
        accessor="CurrentEnvironment"
        disabledEnvironments={['Production']}
        enabledEnvironments={['Local', 'Development', 'Stage']}
      >
        <Box color="$interactive1" css={{ position: 'relative' }} data-testid="current-environment-box" padding="$sm">
          <Layout.Group alignLayout="center">
            <Label className="text-align-center" color="$white">
              {currentEnvironment} Environment
            </Label>
            <Badge
              css={{
                backgroundColor: 'transparent',
                borderColor: '#ffffff',
                borderRadius: '0px',
                color: '#ffffff',
              }}
              outline
              variant="info"
            >
              v{currentVersion}
            </Badge>
          </Layout.Group>
          {String(currentEnvironment).toLowerCase().includes('local') && (
            <div
              style={{
                position: 'absolute',
                right: 10,
                top: 8,
              }}
            >
              <Layout.Group alignLayout="center">
                <Label className="text-align-center" color="$white">
                  API Environment:
                </Label>
                <Badge
                  css={{
                    backgroundColor: 'transparent',
                    borderColor: '#ffffff',
                    borderRadius: '0px',
                    color: '#ffffff',
                  }}
                  outline
                  variant="info"
                >
                  {config('APP_ENV') === 'local' ? 'Local' : 'Development'}
                </Badge>
              </Layout.Group>
            </div>
          )}
        </Box>
      </Visibility>
    </ErrorHandler>
  );
};
