import { isUndefined } from 'lodash';

class ApiError extends Error {
  constructor(error = {}) {
    super(error);

    if (Error?.captureStackTrace) {
      Error?.captureStackTrace(this, ApiError);
    }

    this.name = error?.name;
    this.message = error?.message;
    this.error = error;
    this.date = new Date();
  }
}

/**
 * responseError
 *
 * Creates a new ApiError object.
 *
 * @param error
 * @returns {ApiError}
 */
export function responseError(error = {}) {
  const headers = { ...error?.config?.headers };

  delete headers?.authorization;
  delete headers?.Authorization;

  const urlInfo = new URL(error?.config?.baseURL);
  const errorObject = {
    baseUrl: error?.config?.baseURL || '',
    endpoint: error?.config?.url || '',
    headers: headers || {},
    host: urlInfo?.host || '',
    method: error?.config?.method || '',
    parameters: !isUndefined(error?.config?.params) ? error?.config?.params : {},
    pathname: urlInfo?.pathname || '',
    payload: !isUndefined(error?.config?.data) ? error?.config?.data : {},
    protocol: String(urlInfo?.protocol).replace(':', '') || '',
    url: '',
  };

  errorObject.url = `${errorObject.baseUrl}${errorObject.endpoint}?${new URLSearchParams(errorObject.parameters).toString()}`;

  return new ApiError({
    message: `Request failed: ${error?.status} ${error?.statusText}`,
    name: 'API Error',
    request: errorObject,
    response: error?.data,
    statusCode: error?.status,
    statusText: error?.statusText,
  });
}
