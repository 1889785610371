import { Flex } from '@abyss/web/ui/Flex';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Layout } from '@abyss/web/ui/Layout';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { TextInputArea } from '@abyss/web/ui/TextInputArea';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { Button } from '@src/components/Button';
import statuses from '@src/routes/private/WorkQueue/includes/statuses.json';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Form
 *
 * Displays fields for the user to update the workable item.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Form = (props) => {
  const { form, handleSubmit, workableItem } = props;

  const formValues = form?.watch();

  return (
    <FormProvider autoComplete="off" onSubmit={handleSubmit} state={form}>
      <Layout.Stack alignLayout="right" grow space={themeConfiguration?.theme?.space?.md}>
        <TextInputArea
          displayMaxLength
          isDisabled={workableItem?.status === 'COMPLETE'}
          label="Notes"
          maxLength={1000}
          model="notes"
          placeholder="enter notes here ..."
          rows={4}
        />
        <SelectInput
          isDisabled={workableItem?.status === 'COMPLETE'}
          label="Status"
          model="status"
          options={Object.keys(statuses)
            .filter((key) => {
              return ['COMPLETE', 'IN_PROGRESS', 'NEW'].includes(key);
            })
            .map((key) => {
              let isDisabled = false;

              if (key === 'NEW' && ['COMPLETE', 'IN_PROGRESS'].includes(workableItem?.status)) {
                isDisabled = true;
              }

              if (key === 'IN_PROGRESS' && workableItem?.status === 'COMPLETE') {
                isDisabled = true;
              }

              return {
                isDisabled,
                label: statuses[key],
                value: key,
              };
            })}
          placeholder="Please Select --"
        />
        <Flex justify="flex-end">
          <Button
            isDisabled={
              workableItem?.status === 'COMPLETE' ||
              !form?.formState.isValid ||
              isEqual(form?.formState.defaultValues, formValues)
            }
            type="submit"
            variant="solid"
          >
            Save
          </Button>
        </Flex>
      </Layout.Stack>
    </FormProvider>
  );
};

Form.propTypes = {
  form: PropTypes.shape({
    formState: PropTypes.shape({
      defaultValues: PropTypes.shape({
        notes: PropTypes.string,
        status: PropTypes.string,
      }),
      dirtyFields: PropTypes.shape({
        notes: PropTypes.bool,
        status: PropTypes.bool,
      }),
      isValid: PropTypes.bool,
    }),
    reset: PropTypes.func,
    watch: PropTypes.func,
  }),
  handleSubmit: PropTypes.func,
  workableItem: PropTypes.shape({
    status: PropTypes.string,
  }),
};

Form.defaultProps = {
  form: {},
  handleSubmit: () => {},
  workableItem: {},
};
