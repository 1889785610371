import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.abyss-text-input-root': {
    '.abyss-text-input-left-add-on': {
      background: 'none',
      borderBottom: 'none',
      borderLeft: 'none',
      borderTop: 'none',
      fontSize: 'var(--abyss-fontSizes-sm)',
      lineHeight: '100%',
    },
    '.abyss-text-input-right-element': {
      '&>div': {
        height: '100%',
        position: 'relative',
      },
      button: {
        height: '100%',
      },
    },
    marginLeft: 'var(--abyss-space-xs)',
  },
});
