import { config } from '@abyss/web/tools/config';
import { msalInstance } from '@src/client';
import { responseError } from '@src/context/Api/includes/Axios/functions';
import { loginRequest } from '@src/includes/configuration/authentication';
import axios from 'axios';

const AxiosFrontendInstance = axios.create();

/**
 * Modify the request before it's sent.
 */
AxiosFrontendInstance.interceptors.request.use(
  async (axiosConfig) => {
    const configuration = axiosConfig;

    if (config('APP_ENV') !== 'local') {
      const activeAccount = msalInstance.getActiveAccount();

      if (activeAccount) {
        const token = await msalInstance.acquireTokenSilent({
          ...loginRequest,
          account: activeAccount,
        });

        configuration.headers.Authorization = `Bearer ${token?.accessToken}`;
      }
    }

    return configuration;
  },
  (error) => {
    throw responseError(error);
  }
);

/**
 * Modify the response before it's returned.
 */
AxiosFrontendInstance.interceptors.response.use(
  (response) => {
    return { ...response };
  },
  (axiosError) => {
    const errorObject = responseError(axiosError?.response);
    const { error } = errorObject;

    if (error?.statusCode === 401) {
      if (typeof window !== 'undefined') {
        window.location.href = `${config('APP_URL')}/unauthorized`;
        return false;
      }
    }

    throw errorObject;
  }
);

AxiosFrontendInstance.defaults.timeout = 60000; // set the default network timeout to 60 seconds

/**
 * Axios
 *
 * Makes a network request utilizing Axios library.
 *
 * @type {axios.AxiosInstance}
 */
export const Axios = AxiosFrontendInstance;
