import { Box } from '@abyss/web/ui/Box';
import { Card } from '@abyss/web/ui/Card';
import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { AbyssTheme as themeConfiguration } from '@src/client';
import PropTypes from 'prop-types';
import React from 'react';

const variations = {
  blue: {
    dark: 'var(--abyss-colors-info1)',
    light: 'var(--abyss-colors-info2)',
    text: 'var(--abyss-colors-info1)',
  },
  green: {
    dark: 'var(--abyss-colors-success1)',
    light: 'var(--abyss-colors-success2)',
    text: 'var(--abyss-colors-success1)',
  },
  purple: {
    dark: 'var(--abyss-colors-supporting1)',
    light: 'var(--abyss-colors-supporting2)',
    text: 'var(--abyss-colors-white)',
  },
  yellow: {
    dark: 'var(--abyss-colors-warning1)',
    light: 'var(--abyss-colors-warning2)',
    text: 'var(--abyss-colors-warning1)',
  },
};

/**
 * DataItem
 *
 * Displays a data item.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const DataItem = (props) => {
  const { icon, label, value, variant } = props;

  return (
    <Card css={{ borderColor: variations?.[variant]?.dark, boxShadow: 'none' }}>
      <Grid css={{ margin: 0, padding: 0 }}>
        <Grid.Col
          css={{ padding: 0 }}
          span={{
            xs: '25%',
          }}
        >
          <Flex
            alignContent="center"
            alignItems="center"
            css={{ backgroundColor: variations?.[variant]?.dark, height: '100%', width: '100%' }}
            direction="row"
            justify="center"
          >
            {icon}
          </Flex>
        </Grid.Col>

        <Grid.Col
          css={{ padding: 0 }}
          span={{
            xs: '75%',
          }}
        >
          <Box color={variations?.[variant]?.light}>
            <Layout.Stack alignItems="left" grow space={themeConfiguration?.theme?.space?.xs}>
              <Heading color={variations?.[variant]?.text} offset={3}>
                {value}
              </Heading>
              <Text color={variations?.[variant]?.text} size="$lg">
                {label}
              </Text>
            </Layout.Stack>
          </Box>
        </Grid.Col>
      </Grid>
    </Card>
  );
};

DataItem.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.string,
};

DataItem.defaultProps = {
  icon: null,
  label: '',
  value: '',
  variant: 'blue',
};
