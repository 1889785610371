import { useRouter } from '@abyss/web/hooks/useRouter';
import { useToast } from '@abyss/web/hooks/useToast';
import { Flex } from '@abyss/web/ui/Flex';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useApi } from '@src/context/Api';
import { useRoutesContext } from '@src/context/Routes';
import { isEmpty, isUndefined } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

import { AdditionalAddresses } from './components/AdditionalAddresses';
import { ConfCommButton, QmcsoButton } from './components/Buttons';
import { Details } from './components/Details';
import { ConfCommModal, QmcsoModal } from './components/Modals';
import { Skeleton } from './components/Skeleton';

/**
 * MemberProfile
 *
 * Displays contact information about a member.
 *
 * @returns {Element}
 * @constructor
 */
export const MemberProfile = () => {
  const { currentRoute } = useRoutesContext();
  const eid = currentRoute?.params?.eid;
  const policyNumber = currentRoute?.params?.policyNumber;

  const [isLoading, setIsLoading] = useState(true);
  const [isOpenQmcsoModal, setIsOpenQmcsoModal] = useState(false);
  const [isOpenConfCommModal, setIsOpenConfCommModal] = useState(false);
  const [mode, setMode] = useState('create');
  const [currentEntity, setCurrentEntity] = useState({});
  const [additionalAddresses, setAdditionalAddresses] = useState([]);
  const [currentVersion, setCurrentVersion] = useState(0);

  const { useApiQuery } = useApi();
  const [GetMember, { data: memberData, isFetching: isFetchingMember, isLoading: isLoadingMember }] =
    useApiQuery('GetMember');

  const [GetAddresses, { data: addressData, isFetching: isFetchingAddresses, isLoading: isLoadingAddresses }] =
    useApiQuery('GetAddresses');

  const [ListWorkQueue, { data: workQueue, isFetchingWorkQueue, isLoading: isLoadingWorkQueue }] =
    useApiQuery('ListWorkQueue');

  const { toast } = useToast();
  const router = useRouter();

  /**
   * Update the additional addresses and current version when the address data changes.
   */
  useEffect(() => {
    if (!isUndefined(addressData?.addresses) && !isEmpty(addressData?.addresses)) {
      setAdditionalAddresses(addressData?.addresses);
    }

    if (!isUndefined(addressData?.version)) {
      setCurrentVersion(addressData?.version);
    }
  }, [addressData]);

  /**
   * Determine the overall loading state of the component.
   */
  useEffect(() => {
    if (
      !isLoadingMember &&
      !isFetchingMember &&
      !isLoadingWorkQueue &&
      !isFetchingWorkQueue &&
      !isLoadingAddresses &&
      !isFetchingAddresses
    ) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [
    isFetchingAddresses,
    isFetchingMember,
    isLoadingAddresses,
    isLoadingMember,
    isLoadingWorkQueue,
    isFetchingWorkQueue,
  ]);

  /**
   * Retrieve the member data from the API.
   */
  useEffect(() => {
    if (!isLoadingMember && !isFetchingMember && isUndefined(memberData) && !isEmpty(eid) && !isEmpty(policyNumber)) {
      GetMember({ eid, policyNumber });
    }
  }, [eid, isFetchingMember, isLoadingMember, memberData, policyNumber]);

  /**
   * Retrieve the work queue for the  member from the API.
   */
  useEffect(() => {
    if (
      !isLoadingWorkQueue &&
      !isFetchingWorkQueue &&
      isUndefined(workQueue) &&
      !isEmpty(eid) &&
      !isEmpty(policyNumber)
    ) {
      ListWorkQueue({ eid, policyNumber });
    }
  }, [eid, isFetchingWorkQueue, isLoadingWorkQueue, workQueue, policyNumber]);

  /**
   * Retrieve additional addresses for the member from the API.
   */
  useEffect(() => {
    if (!isUndefined(memberData) && !isEmpty(memberData)) {
      if (!isLoadingAddresses && !isFetchingAddresses && isUndefined(addressData)) {
        GetAddresses(memberData);
      }
    }
  }, [addressData, isFetchingAddresses, isLoadingAddresses, memberData]);

  /**
   * confAddresses
   *
   * List of confidential communication addresses.
   *
   * @type {*[]}
   */
  const confAddresses = useMemo(() => {
    let theAddresses = [];

    if (!isUndefined(additionalAddresses) && !isEmpty(additionalAddresses)) {
      theAddresses = additionalAddresses.filter((address) => {
        return address?.confComType === 'CONF_COMM';
      });
    }

    return theAddresses;
  }, [additionalAddresses]);

  /**
   * qmcsoAddresses
   *
   * List of QMCSO addresses.
   *
   * @type {*[]}
   */
  const qmcsoAddresses = useMemo(() => {
    let theAddresses = [];

    if (!isUndefined(additionalAddresses) && !isEmpty(additionalAddresses)) {
      theAddresses = additionalAddresses.filter((address) => {
        return address?.confComType === 'QMCSO';
      });
    }

    return theAddresses;
  }, [additionalAddresses]);

  /**
   * policyExists
   *
   * Checks if this member exists in the database.
   *
   * @type {boolean}
   */
  const policyExists = useMemo(() => {
    if (isUndefined(memberData)) {
      return false;
    }

    if (isUndefined(memberData) || isEmpty(memberData)) {
      toast.show({
        autoClose: true,
        id: `redirect-search-success`,
        message: (
          <Layout.Stack alignItems="left">
            <Text color="white">Please complete your search again.</Text>
          </Layout.Stack>
        ),
        title: `Error - Policy Mismatch`,
        variant: 'error',
      });
      router?.navigate('/search');
      return false;
    }

    return (
      [...[memberData], ...(memberData?.additionalPolicies || [])]?.filter((thePolicy) => {
        return thePolicy?.isConfCommAddress === true;
      }).length > 0
    );
  }, [memberData]);

  if ((isUndefined(memberData) && isUndefined(addressData)) || isLoading) {
    return <Skeleton />;
  }

  return (
    <ErrorHandler location="src/routes/private/MemberProfile/MemberProfile.jsx">
      <Details memberData={memberData} showBorder showDemographics showIdentifiers showName workQueue={workQueue} />
      <Flex>
        <QmcsoButton
          additionalAddresses={additionalAddresses}
          onClick={() => {
            setCurrentEntity({});
            setIsOpenQmcsoModal(true);
            setMode('create');
            toast.clean();
          }}
        />
        <ConfCommButton
          additionalAddresses={additionalAddresses}
          onClick={() => {
            setCurrentEntity({});
            setIsOpenConfCommModal(true);
            setMode('create');
            toast.clean();
          }}
        />
      </Flex>
      {!isEmpty(additionalAddresses) && (
        <AdditionalAddresses
          addresses={additionalAddresses}
          isLoading={isLoadingAddresses || isFetchingAddresses}
          setCurrentEntity={setCurrentEntity}
          setMode={setMode}
          setShowConfAddress={setIsOpenConfCommModal}
          setShowQmcsoAddress={setIsOpenQmcsoModal}
        />
      )}
      {isOpenQmcsoModal && (
        <QmcsoModal
          addresses={qmcsoAddresses}
          currentEntity={currentEntity}
          currentVersion={currentVersion}
          GetAddresses={GetAddresses}
          GetMember={GetMember}
          handleClose={() => {
            setCurrentEntity({});
            setIsOpenQmcsoModal(false);
            setMode('create');
          }}
          isOpen={isOpenQmcsoModal}
          memberData={memberData}
          mode={mode}
          policyExists={policyExists}
        />
      )}
      {isOpenConfCommModal && (
        <ConfCommModal
          addresses={confAddresses}
          currentEntity={currentEntity}
          currentVersion={currentVersion}
          GetAddresses={GetAddresses}
          GetMember={GetMember}
          handleClose={() => {
            setCurrentEntity({});
            setIsOpenConfCommModal(false);
            setMode('create');
          }}
          isOpen={isOpenConfCommModal}
          memberData={memberData}
          mode={mode}
          policyExists={policyExists}
        />
      )}
    </ErrorHandler>
  );
};
