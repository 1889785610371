import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.tableFooter': {
    '.abyss-pagination-results-root': {
      marginTop: 'var(--abyss-space-xs)',
    },
    '.bottom': {
      padding: 0,
      position: 'relative',
    },
    '.left': {
      padding: 0,
      position: 'relative',
    },
    '.right': {
      padding: 0,
      position: 'relative',
    },
    '.top': {
      padding: 0,
      position: 'relative',
    },
    margin: 0,
    marginBottom: 'var(--abyss-space-sm)',
    marginTop: 'var(--abyss-space-sm)',
    position: 'relative',
  },
});
