import { Alert } from '@abyss/web/ui/Alert';
import { Box } from '@abyss/web/ui/Box';
import { Divider } from '@abyss/web/ui/Divider';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Heading } from '@abyss/web/ui/Heading';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Layout } from '@abyss/web/ui/Layout';
import { LoadingOverlay } from '@abyss/web/ui/LoadingOverlay';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React from 'react';

import { Demographics } from './components/Demographics';
import { Identifiers } from './components/Identifiers';
import { Styles } from './includes/styles';

/**
 * Filters
 *
 * Displays a list of input filters to be applied to the api search request.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Filters = (props) => {
  const { form, handleReset, handleSubmit, isFormValid, isLoading } = props;

  return (
    <ErrorHandler location="src/routes/private/Search/components/Filters/Filters.jsx">
      <Styles>
        <FormProvider autoComplete="off" onSubmit={handleSubmit} state={form}>
          <LoadingOverlay
            ariaLoadingLabel="Your search is being processed."
            isLoading={isLoading}
            isOpen={isLoading}
            loadingIcon={<IconSymbol color="$primary1" icon="search" />}
            loadingMessage="Your search is being processed."
            loadingTitle="Searching..."
          >
            <motion.div
              animate="open"
              initial={{ opacity: 0 }}
              variants={{
                closed: { opacity: 0 },
                open: { opacity: 1 },
              }}
            >
              <Box className="row" color="transparent">
                <Heading css={{ marginBottom: 'var(--abyss-space-sm)' }} offset={0}>
                  Search
                </Heading>
                <Alert title="All searches must include at least one of the following:" variant="info">
                  <ul>
                    <li>
                      A combination of any two Identifiers (ex: policy number and member ID; EID and policy number)
                    </li>
                    <li>One identifier and one demographic (ex: EID and last name; Policy number and dob)</li>
                  </ul>
                </Alert>
                <Divider color="transparent" margin="$md" />
                <Identifiers />
                <Divider color="transparent" margin="$md" />
                <Demographics />
                <Divider color="transparent" margin="$md" />

                <Layout.Group>
                  <Button onClick={handleReset} variant="outline">
                    Clear Fields
                  </Button>
                  <Button
                    after={<IconSymbol icon="search" size="18px" />}
                    data-testid="search-button"
                    isDisabled={!isFormValid || form?.formState?.isSubmitting || isLoading}
                    type="submit"
                    variant="solid"
                  >
                    Search
                  </Button>
                </Layout.Group>
              </Box>
            </motion.div>
          </LoadingOverlay>
        </FormProvider>
      </Styles>
    </ErrorHandler>
  );
};

Filters.propTypes = {
  form: PropTypes.shape({
    formState: PropTypes.shape({
      isSubmitting: PropTypes.bool,
    }),
    watch: PropTypes.func,
  }),
  handleReset: PropTypes.func,
  handleSubmit: PropTypes.func,
  isFormValid: PropTypes.bool,
  isLoading: PropTypes.bool,
};

Filters.defaultProps = {
  form: {},
  handleReset: () => {},
  handleSubmit: () => {},
  isFormValid: false,
  isLoading: false,
};
