import { useRouter } from '@abyss/web/hooks/useRouter';
import { config } from '@abyss/web/tools/config';
import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Loader } from '@src/components/Loader';
import { Private } from '@src/components/Router/components/Private';
import { Public } from '@src/components/Router/components/Public';
import { ApplicationProvider as ContextProvider } from '@src/context/Application';
import React, { useEffect } from 'react';

/**
 * Router
 *
 * This is the main component used to handle what is displayed to the user when authenticated or not.
 *
 * @returns {Element}
 * @constructor
 */
export const Router = () => {
  const { inProgress, instance } = useMsal();
  const msalAuth = useIsAuthenticated();
  const isAuthenticated = config('APP_ENV') === 'local' ? true : msalAuth;
  const router = useRouter();
  const location = router.getLocation();
  const pathname = location?.pathname;
  const hash = location?.hash || '';

  /**
   * Redirect the user back to the login screen when the user is not authenticated.
   */
  useEffect(() => {
    if (
      inProgress === InteractionStatus.None &&
      !isAuthenticated &&
      !['/callback', '/login', '/unauthorized'].includes(pathname)
    ) {
      sessionStorage.setItem('referrerPath', `${pathname}${hash}`);
      router?.navigate('/login');
    }
  }, [isAuthenticated, pathname, hash, inProgress]);

  /**
   * Redirect the user to the home (index) page when the user is authenticated.
   */
  useEffect(() => {
    if (
      inProgress === InteractionStatus.None &&
      isAuthenticated &&
      ['/callback', '/login'].includes(location.pathname)
    ) {
      const referrerPath = sessionStorage.getItem('referrerPath');
      if (referrerPath) {
        sessionStorage.removeItem('referrerPath');
        router?.navigate(referrerPath);
      } else {
        router?.navigate('/');
      }
    }
  }, [isAuthenticated, pathname, inProgress, instance]);

  /**
   * unauthenticates a user with the application
   */
  useEffect(() => {
    (async () => {
      if (inProgress === InteractionStatus.None && isAuthenticated && pathname === '/logout') {
        await instance.logoutRedirect({
          account: instance.getAccountByHomeId(instance.getActiveAccount()?.homeAccountId),
          postLogoutRedirectUri: `${config('APP_URL')}/login`,
        });
      }
    })();
  }, [pathname, isAuthenticated, inProgress, instance]);

  if (inProgress !== InteractionStatus.None) {
    return <Loader />;
  }

  return (
    <ErrorHandler location="src/components/Router/Router.jsx">
      {isAuthenticated === true ? (
        <ContextProvider>
          <Private />
        </ContextProvider>
      ) : (
        <Public />
      )}
    </ErrorHandler>
  );
};
