import { Widget } from '@src/components/Widget';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Table } from './components/Table';

/**
 * EimpInformation
 *
 * Displays EIMP information
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const EimpInformation = (props) => {
  const { workableItem } = props;

  const [rows, setRows] = useState([]);

  /**
   * define the rows of data for the table
   */
  useEffect(() => {
    const theRows = [];

    const fields = Object.keys(workableItem?.eimpOriginalRecord || workableItem?.eimpNewRecord || []);

    if (!isEmpty(fields)) {
      fields.forEach((field) => {
        const theField = {
          field,
          newValue: workableItem?.eimpNewRecord?.[field],
          originalValue: workableItem?.eimpOriginalRecord?.[field],
        };

        theRows.push(theField);
      });
    }

    if (theRows !== rows) {
      setRows(theRows);
    }
  }, [workableItem]);

  return (
    <Widget description="" title="Information From EIMP">
      <Table rows={rows} />
    </Widget>
  );
};

EimpInformation.propTypes = {
  workableItem: PropTypes.shape({
    eimpNewRecord: PropTypes.shape({}),
    eimpOriginalRecord: PropTypes.shape({}),
  }),
};

EimpInformation.defaultProps = {
  workableItem: {},
};
